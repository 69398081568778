.bh {
  margin-bottom: 0;
  position: absolute;
  text-align: right;
  right: 14px;
  font-size: 12px;
  top: 8px;
}
.mobile-bh {
  right: 68px;
}
.user-dropdown {
  .dropdown-toggle {
    margin-top: 12px;
    font-size: $baseFontSize;
    color: $grey;
    border: none;

    &:hover,
    &:focus {
      color: $buttonBlue;
      background: none;
    }
  }

  &.nav .open > a {
    background-color: transparent;
  }
}

.dropdown-menu {
  right: 0;
  left: auto;
  border: 2px solid $lightWhite;
  border-radius: 3px;

  > li > a {
    padding: 10px 20px;
    font-family: "Avenir";
    font-size: $baseFontSize;
  }
}
