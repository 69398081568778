h1 {
  font-family: "Avenir Black";
  font-size: 28px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: "Avenir";
}
