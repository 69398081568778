.program-form-page {
  font-size: 14px;
}

/***** NEW/EDIT COURSE FORM STYLES *****/
.program-form-inputs {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 8px));
  grid-gap: 16px;
}
.program-form-inputs.program-schedule-details-form-inputs {
  grid-template-columns: repeat(3, 1fr);
}
.program-form-inputs label {
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  min-width: 180px;
}
.program-form-inputs.program-schedule-details-form-inputs label {
  min-width: 112px;
  margin-right: 8px;
}
.program-form-inputs input[type="text"],
.program-form-inputs input[type="email"],
.program-form-inputs .Select-control {
  height: 32px;
  border: 1px solid #edecec;
  box-shadow: none;
}
.program-form-inputs .Select {
  width: 100%;
}
.program-form-inputs textarea {
  border: 1px solid #edecec;
  box-shadow: none;
  width: 100%;
  padding: 8px;
}
.program-form-inputs .radio-container label {
  height: 30px;
}
.program-form-inputs .react-datepicker-wrapper,
.program-form-inputs .react-datepicker__input-container {
  width: 100%;
}
.date-input-wrapper {
  width: 33%;
  margin-right: 12px;
  label {
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
    min-width: 100px;
    margin-right: 8px;
  }
  .MuiTextField-root {
    width: 100%;
  }
}
.schedule-name-input {
  height: 32px !important;
  border: 1px solid #edecec !important;
}
.manage-participants-btn {
  width: 220px;
  text-align: center;
}

/***** PROGRAM SCHEDULE STYLES *****/
.program-schedules-header-bar {
  background: rgba(83, 183, 232, 0.1);
  height: 48px;
  padding: 0 16px;
}
.program-schedules-header-bar .path3:before {
  margin-left: 0;
}

/***** PROGRAM CONTENT STYLES *****/
//remove notification coming from the tiny mce editor
.mce-notification {
  display: none !important;
}

/***** BREADCRUMBS STYLES *****/
.breadcrumbs-container {
  min-height: 12px;
}

/***** MANAGE PARTICIPANTS MODAL STYLES *****/
.manage-participants-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 100vh;
}
.manage-participants-modal-container .modal-card {
  width: 936px;
}
.manage-participants-modal-container .divider {
  border-bottom: 1px solid #edecec;
  margin-bottom: 32px;
}
.add-selected-btn {
  width: 148px;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.75px;
  line-height: 19px;
  text-align: center;
  align-items: center;
}
.manage-participants-included-shluchim-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 48px;
}
.shliach-chip {
  width: fit-content;
  border-radius: 20px;
  margin: 4px;
  background-color: rgba(237, 236, 236, 0.6);
}
.shliach-chip > span {
  display: flex;
  align-items: center;
  padding: 8px 24px;
  color: rgba(0, 0, 0, 0.6);
  font-family: Avenir;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 19px;
}
.manage-participant-modal-btns {
  margin-top: 72px;
  display: flex;
  justify-content: flex-end;
}
.manage-participant-modal-btns .btn {
  min-width: 80px;
}
.manage-participant-modal-btns .close-btn {
  font-size: 14px;
  padding: 0;
  background-color: transparent;
  border: none;
}
.manage-participant-modal-btns .save-btn {
  font-size: 14px;
  background-color: #53b7e8;
  border: none;
  color: #ffffff;
  padding: 8px 12px;
}
.manage-participant-modal-btns .save-btn:hover {
  background-color: #337ab7;
  border-color: #2e6da4;
}

/***** PROGRAM SCHEDULE REGISTRATIONS STYLES *****/
.program-registrations-page {
  width: 100%;
  @extend .trip-page;
}
.program-registrations-page label {
  font-weight: normal;
  margin-bottom: 0;
}
.program-schedule-details-tabs {
  @extend .trip-details-tabs;
}
.program-schedule-card {
  @extend .trip-card;
}
.program-registrations-table .search-input {
  width: 390px;
}
.program-registrations-table-header {
  @extend .courses-table-header;
}
.program-registrations-table-row {
  @extend .courses-table-row;
}
.program-registrations-table-row p {
  overflow-wrap: break-word;
  hyphens: auto;
}
.program-registrations-table-row:hover {
  cursor: default;
  opacity: 1;
  text-decoration: none;
}
.program-registrations-shluchim-table-header {
  @extend .program-registrations-table-header;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 0.5fr;
}
.program-registrations-shluchim-table-row {
  @extend .program-registrations-table-row;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 0.5fr;
}
.program-registration-form-page {
  @extend .course-form-page;
}
.program-registration-form-page .registration-form-grid {
  @extend .two-column-grid;
  width: 560px !important;
  grid-gap: 16px 48px !important;
}
.program-registration-form-page .unregistered-person,
.program-registration-form-page
  .unregistered-person
  .link-text:not(.toggle-registration-link) {
  color: $accent-text-light;
}
.registration-spouse-checkbox-container
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  height: 8px;
  width: 4px;
  left: 4px;
  top: 4px;
}
.registration-spouse-checkbox-container label {
  font-size: 13px;
  font-weight: 500;
}
.program-registration-form-btns {
  @extend .course-form-btns;
}
.program-application-form-page {
  @extend .course-form-page;
}
.program-application-form {
  @extend .course-form;
}
.program-application-form-section {
  @extend .course-form-section;
}
.program-application-form-btns {
  @extend .course-form-btns;
}
