@mixin program-page {
  padding: 80px 24px;
  font-size: 14px;
}

@mixin data-table {
  table {
    padding: 8px 0;
  }
}

@mixin data-table-header {
  border-top: 1px solid #edecec;
  border-bottom: 1px solid #edecec;
  display: grid;
  align-items: center;
  font-weight: 700;
  grid-gap: 24px;
  padding: 4px 12px;
  height: auto;

  p {
    margin-bottom: 0;
    padding: 8px 0;
  }
}

@mixin data-table-row {
  background: #f5f5f5;
  padding: 12px 16px;
  font-size: 14px;
  color: $accent-text-dark;
  transition: opacity 300ms linear;
  display: grid;
  border-radius: 3px;
  grid-gap: 24px;
  color: inherit;
  padding: 12px;
  font-weight: 400;
  margin-top: 8px;
  align-items: center;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
    text-decoration: none;
  }

  p {
    margin-bottom: 0;
  }
}
