.breadcrumb {
  padding-left: 0;
  margin-top: 15px;
  font-family: "Avenir";
  font-size: $baseFontSize;
  background: none;

  @include small {
    display: inline-block;
  }
}
