.merge-duplicates-card {
  border-radius: 3px;
  background-color: #ffffff !important;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  padding: 5px 24px 24px 24px;
}

.merge-duplicates-btns {
  display: flex;
  justify-content: flex-end;
}

.merge-duplicates-btns .btn {
  min-width: 80px;
}

.merge-duplicates-btns .cancel-btn {
  font-size: 14px;
  padding: 0;
  background-color: transparent;
  border: none;
}

.merge-btn {
  font-size: 14px;
  background-color: #53b7e8;
  border: none;
  color: #ffffff;
  padding: 8px 12px;
}

.merge-btn:hover {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.not-duplicate-btn {
  float: right;
  background-color: #ec5150 !important;
  color: #ffffff;
}

/** Merge Duplicates Form **/
.merge-duplicates-form {
  overflow: scroll;
  height: calc(100vh - 375px);
}

.merge-duplicates-form .field-row {
  display: grid;
  grid-gap: 16px 12px;
}

.exclude-student {
  display: flex;
  border-left: 1px solid #979797;
  padding: 0px 14px 14px;
  justify-content: space-between;
  color: #555555;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}

.merge-duplicates-form input[type="checkbox"] {
  margin-right: 10px;
  width: 16;
  height: 16;
}

.merge-duplicates-form-footer > p {
  margin-top: 24px;
  margin-bottom: 24px;
}

/** Suggestions **/
.merge-suggestions .form-header {
  margin-bottom: 30px;
}

.merge-suggestions .form-header .merge-btn {
  float: right;
  padding: 10px 28px;
  margin-top: -16px;
}

.merge-suggestions .form-header .student-tag {
  background: rgb(238, 238, 238);
  width: fit-content;
  padding: 4px 6px;
  border-radius: 4px;
}

.merge-suggestions .title {
  color: #555555;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
}

.merge-suggestions-table-header {
  display: grid;
  grid-template-columns: 1.15fr 1.5fr 1.5fr 330px;
  border-top: 3px solid #eff7fb;
  border-bottom: 3px solid #eff7fb;
  padding-left: 20px;
  margin-left: 10px;
}

.merge-suggestions-table-header p {
  padding: 14px !important;
  border-bottom: none !important;
  color: #555555;
  font-family: Avenir;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

.merge-suggestion-card {
  display: grid;
  grid-template-columns: auto 262px;
  box-sizing: border-box;
  height: 124px;
  border: 1px solid #edecec;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow:
    0 2px 5px -2px rgba(0, 0, 0, 0.12),
    0 2px 18px 2px rgba(0, 0, 0, 0.08);
  padding: 32px 38px;
  margin: 10px auto;
  font-family: Avenir;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
  align-items: center;
}

.merge-suggestions .btn {
  font-size: 12px;
  letter-spacing: 0.43px;
  line-height: 16px;
  padding: 12px 16px;
}

.merge-suggestions-btns .btn {
  height: 40px;
}

.merge-suggestions-btns .merge-btn {
  margin-right: 10px;
}
.merge-duplicates-tooltip-container {
  height: auto !important;
}
.merge-duplicates-tooltip-container .tooltip {
  left: -100px;
  width: 290px;
  white-space: pre-wrap;
}
