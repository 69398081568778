/***** LOADER STYLES *****/
$boxWidth: 33px;
$animationDuration: 10s;
$animationStepDuration: calc($animationDuration / 7);

@keyframes slide {
  0% {
    transform: translate(0, 0);
  }
  2% {
    transform: translate($boxWidth, 0);
  }
  12.5% {
    transform: translate($boxWidth, 0);
  }
  15.5% {
    transform: translate(2 * $boxWidth, 0);
  }
  25% {
    transform: translate(2 * $boxWidth, 0);
  }
  27% {
    transform: translate(2 * $boxWidth, $boxWidth);
  }
  37.5% {
    transform: translate(2 * $boxWidth, $boxWidth);
  }
  39.5% {
    transform: translate($boxWidth, $boxWidth);
  }
  50% {
    transform: translate($boxWidth, $boxWidth);
  }
  52% {
    transform: translate($boxWidth, 2 * $boxWidth);
  }
  62.5% {
    transform: translate($boxWidth, 2 * $boxWidth);
  }
  64.5% {
    transform: translate(0, 2 * $boxWidth);
  }
  75% {
    transform: translate(0, 2 * $boxWidth);
  }
  77% {
    transform: translate(0, $boxWidth);
  }
  87.5% {
    transform: translate(0, $boxWidth);
  }
  89.5% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

svg#loading {
  display: block;
  margin: auto;
  width: 96px;
  height: 96px;
  .rect {
    animation: slide $animationDuration ease infinite;
  }
  #rect1 {
    animation-delay: -0 * $animationStepDuration;
  }
  #rect2 {
    animation-delay: -1 * $animationStepDuration;
  }
  #rect3 {
    animation-delay: -2 * $animationStepDuration;
  }
  #rect4 {
    animation-delay: -3 * $animationStepDuration;
  }
  #rect5 {
    animation-delay: -4 * $animationStepDuration;
  }
  #rect6 {
    animation-delay: -5 * $animationStepDuration;
  }
  #rect7 {
    animation-delay: -6 * $animationStepDuration;
  }
}

/***** LOADER CONTAINER STYLES *****/
/*** Full Screen Loader ***/
.full-page-loader {
  margin-top: 28vh;
}
.full-page-overlay-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 3;

  svg {
    top: 50%;
    left: 50%;
    position: inherit;
  }
}
.overlay-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;

  svg {
    margin-top: 28vh !important;
  }
}
/*** Mobile Loader ***/
.mobile-loader {
  text-align: center;
  margin: 8px 0 16px 0;
}
.mobile-loader svg#loading {
  height: 24px;
  width: 24px;
}

/***** LOADER SIZES *****/
.medium-loader svg#loading {
  height: 64px;
  width: 64px;
}
.btn-large-loader svg#loading {
  height: 48px;
  width: 48px;
}

/***** CARD STYLES *****/
.card {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 10px -3px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.04);
  padding: 32px;
}
.card.flat-card {
  box-shadow: none;
}

/***** FLEX STYLES *****/
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.flex-align-center {
  align-items: center;
}
.flex-justify-space {
  justify-content: space-between;
}
.flex-justify-end {
  justify-content: flex-end;
}
.flex-justify-center {
  justify-content: center;
}
.flex > p {
  margin-bottom: 0;
}
.flex-column {
  flex-direction: column;
}
@media only screen and (max-width: 560px) {
  .mobile-flex-justify-space {
    justify-content: space-between;
  }
}
/***** WHITESPACE STYLES *****/
.pre-wrap {
  white-space: pre-wrap;
}
.no-wrap {
  white-space: nowrap;
}
.pre {
  white-space: pre;
}
.whitespace-normal {
  white-space: normal;
}
/***** GRID STYLES *****/
.two-column-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}
.three-column-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
}

/***** POSITION STYLES *****/
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

/***** DISPLAY STYLES *****/
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}

/***** MARGIN STYLES *****/
.mt-48 {
  margin-top: 48px;
}
.ml-48 {
  margin-left: 48px;
}
.mt-40 {
  margin-top: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.mt-32 {
  margin-top: 32px;
}
.mr-32 {
  margin-right: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.ml-32 {
  margin-left: 32px;
}
.mr-20 {
  margin-right: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.mr-24 {
  margin-right: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.mt-16 {
  margin-top: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.mt-12 {
  margin-top: 12px;
}
.mr-12 {
  margin-right: 12px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mt-8 {
  margin-top: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.mt-4 {
  margin-top: 4px;
}
.mr-4 {
  margin-right: 4px;
}
.mb-4 {
  margin-bottom: 4px;
}
.ml-4 {
  margin-left: 4px;
}
.mb-0 {
  margin-bottom: 0;
}

/***** WIDTH STYLES *****/
.full-width {
  width: 100%;
}

/***** RESPONSIVE STYLES *****/
.container {
  max-width: 100%;
}
@media only screen and (min-width: 1041px) {
  .desktop-hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 561px) and (max-width: 1040px) {
  .tablet-hidden {
    display: none !important;
  }
  .tablet-visible {
    display: block;
  }
}
@media only screen and (max-width: 560px) {
  .mobile-hidden {
    display: none !important;
  }
  .mobile-showing {
    display: block;
  }
  .mobile-block {
    display: block;
  }
}

/***** PAGE WRAPPER STYLES *****/
.page-wrapper {
  padding-top: 72px;
  background: #fafafa;
  min-height: 100vh;
  height: 100%;
}
@media only screen and (max-width: 1040px) {
  .page-wrapper {
    padding-top: 56px;
  }
}

/***** CONTAINER STYLES *****/
.profile-page.container,
.profile-page .container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
}
.profile-page.page.container {
  padding: 40px;
}
@media only screen and (max-width: 1040px) {
  .profile-page.container,
  .profile-page .container {
    padding: 0 24px;
    max-width: 848px;
  }
  .profile-page.page.container {
    padding: 40px 24px;
  }
}
@media only screen and (max-width: 560px) {
  .profile-page.container,
  .profile-page .container {
    padding: 0 16px;
  }
  .profile-page.page.container {
    padding: 24px 16px;
  }
}

/***** BUTTON STYLES *****/
.profile-page .btn {
  border-radius: 3px;
  letter-spacing: 0.2px;
  font-size: 14px;
  transition: background 300ms linear, color 300ms linear, opacity 300ms linear;
  cursor: pointer;
}
.profile-page a.btn {
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-page .btn:focus {
  outline: none;
}
.profile-page .btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.profile-page .btn-large {
  height: 48px;
  line-height: 48px;
}
.profile-page .btn-medium {
  height: 32px;
  line-height: 32px;
}
.profile-page .btn-fullWidth {
  width: 100%;
}
.profile-page .btn-accent {
  background: $accent-color;
  color: #fff;
  border: 1px solid $accent-color;
}
.profile-page .btn-accent:hover:not(:disabled) {
  background: #fff;
  color: $accent-color;
}

/***** INPUT CONTAINER STYLES *****/
.input-container {
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 8px 16px;
}
.input-container.error {
  border: 1px solid $error-color;
}
.input-container label {
  font-size: 10px;
  color: $accent-text;
  text-transform: uppercase;
  display: block;
  margin-bottom: 4px;
}
.input-container input {
  border: none;
  width: 100%;
  font-size: 13px;
}
.input-container input:focus {
  outline: none;
}
.input-container-outline {
  border: 1px solid $accent-color;
  transition: border 300ms linear;
}

@media only screen and (max-width: 560px) {
  .page-wrapper-mobile .input-container {
    height: 56px;
  }
  .page-wrapper-mobile .input-container input {
    font-size: 16px;
  }
}

/***** CUSTOM INPUT STYLES *****/
.custom-input {
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 0 8px;
  height: 32px;
  transition: border 300ms linear;
  background: #fff;
  font-size: 13px;
}
.custom-input:focus {
  border-color: $accent-color;
  outline: none;
}
.custom-input::placeholder {
  color: rgba(0, 0, 0, 0.24);
}
.custom-input.error,
.error .custom-input {
  border-color: $error-color;
}
.custom-input:disabled,
.disabled .custom-input {
  opacity: 0.5;
  cursor: not-allowed;
}
input.custom-input:read-only {
  border: none;
}
.custom-input.percentage-input {
  background: url("/img/percentage.png");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 96% center;
}
.custom-input.dollar-input {
  background: url("/img/dollar.svg");
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: 4% center;
  padding-left: 24px;
}

.reward-currency-values {
  display: flex;
  color: rgba(0, 0, 0, 0.6);
}

.custom-input.pound-input {
  background: url("/img/pound.svg");
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: 4% center;
  padding-left: 24px;
}
.custom-input.form-required::after {
  content: "";
}
textarea.custom-input {
  padding: 8px;
}
@media only screen and (max-width: 560px) {
  .page-wrapper-mobile .custom-input {
    font-size: 16px;
  }
  .page-wrapper-mobile .custom-input {
    -webkit-appearance: none;
  }
}

/***** CUSTOM SELECT STYLES *****/
.custom-select {
  width: 100%;
}
.custom-select > div,
.custom-select > div:hover {
  border-color: #edecec;
}
.custom-select.is-disabled {
  border: 1px solid #edecec;
  height: 32px;
  border-radius: 3px;
  cursor: not-allowed;
}
.custom-select.is-disabled.read-only {
  border: none;
}
.custom-select.is-disabled > div {
  opacity: 0.5;
  background: transparent;
  cursor: not-allowed;
}
.custom-select.is-disabled.read-only > div {
  opacity: 1;
  cursor: text;
}
.custom-select.is-disabled .Select-placeholder {
  height: 30px;
}
.custom-select.error > div {
  border-color: $error-color !important;
}
.custom-select > div:first-of-type {
  height: 32px;
  min-height: auto;
  border: 1px solid #edecec;
}
.custom-select * {
  font-size: 13px;
}
.custom-select__option--is-focused {
  background: rgba(12, 66, 93, 0.1) !important;
}
.custom-select__option--is-selected {
  background: $accent-color-dark !important;
}
.multiselect {
  @extend .custom-select;
}

/***** PHONE INPUT STYLES ****/
.phone-input {
  border: 1px solid #edecec;
  height: 32px;
  border-radius: 3px;
}
.phone-input.error {
  border: 1px solid $error-color;
}
.phone-input.read-only {
  border: none;
}
.phone-input.read-only .iti-arrow,
.phone-input.read-only .selected-flag:after {
  display: none;
}
.phone-input-error {
  @extend .error-text;
  font-size: 11px;
  margin-top: -4px;
  display: block;
}
@media only screen and (max-width: 560px) {
  .phone-input-error {
    margin-top: -6px;
  }
}

/***** RADIO BUTTON (LABELS) STYLES *****/
.radio-container {
  display: inline-flex;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  height: 32px;
}
.radio-container.error {
  border: 1px solid $error-color;
}
.radio-container.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.radio-container input[type="radio"] {
  display: none;
}
.radio-container input[type="radio"] + label {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $accent-text;
  width: auto;
}
.radio-container input[type="radio"]:first-child + label {
  border-right: 1px solid #e0e0e0;
}
.radio-container input[type="radio"]:nth-child(5) + label {
  border-left: 1px solid #e0e0e0;
}
.radio-container input + label:not(:nth-child(2)) {
  border-left: 1px solid #e0e0e0;
}
.radio-container input[type="radio"]:checked + label,
.radio-container input[type="radio"] + label.checked {
  background: $accent-color;
  color: white;
  transition: background 300ms linear;
}
.radio-container input[type="radio"]:disabled + label {
  cursor: not-allowed;
}

/***** CUSTOM RADIO BUTTON STYLES *****/
.radio-button-container input:checked,
.radio-button-container input:not(:checked) {
  position: absolute;
  left: -9999px;
}
.radio-button-container input:checked + label,
.radio-button-container input:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  font-size: 13px;
}
.radio-button-container input:checked + label.inline,
.radio-button-container input:not(:checked) + label.inline {
  display: inline;
}
.radio-button-container input:checked + label:before,
.radio-button-container input:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fbfbfc;
}
.radio-button-container input:checked + label:before {
  border: 1px solid rgba(0, 0, 0, 0.54);
}
.radio-button-container input:checked + label:after,
.radio-button-container input:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: rgba(0, 0, 0, 0.54);
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.radio-button-container input:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.radio-button-container input:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.radio-button-container .radio-label {
  color: #76838f;
  font-size: 13px;
}
.radio-button-container input:disabled + label {
  cursor: not-allowed;
}

/***** CUSTOM CHECKBOX STYLES *****/
.custom-checkbox[type="checkbox"] {
  display: none;
}
.custom-checkbox[type="checkbox"] + label {
  position: relative;
  display: flex;
  top: -2px;
}
.custom-checkbox[type="checkbox"] + label:before {
  content: "";
  border: 2px solid #e0e0e0;
  border-radius: 3px;
  height: 12px;
  width: 12px;
  min-width: 12px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: bottom;
  transition: all 300ms linear;
}

.custom-checkbox[type="checkbox"]:checked + label:before {
  height: 12px;
  width: 12px;
  min-width: 12px;
  display: inline-block;
  background: $accent-color;
  border: 2px solid $accent-color;
}
.custom-checkbox[type="checkbox"]:checked + label:after {
  content: "";
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  height: 9px;
  width: 5px;
  display: inline-block;
  transform: rotate(45deg);
  position: absolute;
  left: 12px;
  top: 0;
  background: transparent;
}

/***** CLEARABLE SELECT STYLES *****/
.clearable-select {
  width: 100%;
  position: relative;
}
.clearable-select .custom-input.empty {
  color: $accent-text;
}
.clear-select {
  position: absolute;
  color: $accent-text;
  right: 16px;
  font-size: 16px;
  top: 8px;
  cursor: pointer;
}

/***** ERROR MESSAGES STYLES *****/
.error-message-container {
  position: relative;
}
.error-message {
  font-size: 13px;
  color: $error-color;
  position: absolute;
}
.success-message {
  font-size: 13px;
  color: #228b22;
  position: absolute;
}
.warning-message {
  font-size: 13px;
  color: orange;
}
/***** MULTISELECT STYLES *****/
.multiselect {
  width: 100%;
  height: 32px;
}
.multiselect > div {
  border-color: #edecec;
}
.multiselect > div:first-of-type {
  height: 32px;
  min-height: auto;
}
.multiselect .css-1492t68 {
  font-size: 13px;
  opacity: 0.5;
}
.css-2o5izw,
.css-2o5izw:hover {
  border-color: $accent-color !important;
  box-shadow: none !important;
  transition: border 300ms linear !important;
}
.css-1alnv5e:hover {
  background-color: #edecec !important;
  color: $accent-text-dark !important;
  transition: all 300ms linear;
  cursor: pointer;
}

/***** TOOLTIP STYLES *****/
.tooltip-container {
  position: relative;
  height: 16px;
  cursor: default;
}
.tooltip {
  position: absolute;
  left: -24px;
  background-color: #666666;
  box-shadow: 0 0 16px -4px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  font-size: 11px;
  color: #fff;
  white-space: nowrap;
  display: inline-block;
  padding: 4px 8px;
  bottom: 100%;
  margin-bottom: 8px;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms linear;
}
.tooltip.tooltip-medium-width {
  white-space: pre-wrap;
  width: 240px;
}
.tooltip.tooltip-medium-width.tooltip-left {
  left: -200px;
}
.tooltip-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
}
.tooltip-right {
  left: auto !important;
  right: 0;
}
@media only screen and (max-width: 560px) {
  .tooltip {
    left: auto;
    right: 0;
  }
}
@media only screen and (max-width: 400px) {
  .tooltip {
    right: -8px;
    font-size: 10px;
  }
}

/***** FONT WIDTH STYLES *****/
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-700 {
  font-weight: 700;
}
.fw-900 {
  font-weight: 900;
}

/***** FONT SIZE STYLES *****/
.xs-text {
  font-size: 10px;
}
.small-text {
  font-size: 13px;
}
.medium-text {
  font-size: 16px;
}
.large-text {
  font-size: 18px;
}
.xl-text {
  font-size: 20px;
}
.xxl-text {
  font-size: 24px;
}
.xxxl-text {
  font-size: 40px;
}

.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}

/***** FONT STYLES *****/
.italic-text {
  font-style: italic;
}
.link-text {
  color: $accent-color;
  transition: opacity 300ms linear;
  cursor: pointer;
  text-decoration: none;
}
.link-text.material-icons {
  font-family: "Material Icons";
}
.link-text:hover {
  opacity: 0.5;
  text-decoration: none;
  color: $accent-color;
}
.link-text.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  color: inherit;
}
.link-text-heavy {
  font-family: AvenirHeavy;
}
.link-text .back-arrow {
  font-size: 18px;
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 8px;
}
.link-with-icon i {
  color: $accent-text-light;
  transition: color 300ms linear;
}
.link-with-icon:hover i {
  color: $accent-color-dark;
}
.page-wrapper-mobile .link-with-icon i {
  color: $accent-color-dark;
}
.link-text-secondary {
  transition: opacity 300ms linear;
  color: inherit;
}
.link-text-secondary:hover {
  opacity: 0.6;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
.link-text-secondary.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  color: inherit;
  text-decoration: none;
}
.link-text-error {
  @extend .link-text;
  color: $error-color;
}
.link-text-error:hover:not(.disabled) {
  color: $error-color;
}
.page-title {
  font-family: AvenirBlack;
  font-size: 24px;
  margin-bottom: 24px;
}
.strike-through-text {
  text-decoration: line-through;
}

/***** TEXT COLOR STYLES ****/
.accent-text-light {
  color: $accent-text-light;
}
.accent-text {
  color: $accent-text;
}
.accent-text-dark {
  color: $accent-text-dark;
}
.accent-text-secondary {
  color: $accent-color;
}
.flagged-text {
  color: #ff6b6b;
}

/***** SHLUCHIM LIST STYLES *****/
.active-before {
  content: "";
  height: 8px;
  width: 8px;
  background: #72d798;
  border-radius: 100%;
  margin-right: 8px;
  position: absolute;
  left: -16px;
  top: 6px;
}
p.active:before,
span.active:before {
  @extend .active-before;
}
p.inactive:before,
span.inactive:before {
  @extend .active-before;
  background: #f36464;
}
.contacts-delete {
  font-size: 16px;
  padding-top: 4px;
  cursor: pointer;
  transition: opacity 300ms linear;
}
.contacts-delete:hover {
  opacity: 0.5;
}

/***** PROFILE PAGE STYLES *****/
.profile-page {
  font-size: 14px;
}
// .profile-cards-wrapper {margin-top: 40px; width: 100%;}
@media only screen and (max-width: 1040px) {
  .profile-page.page.container {
    padding-top: 80px;
  }
}
@media only screen and (max-width: 560px) {
  .profile-page.page.container {
    padding: 0;
  }
}

/*** Profile Page Subheader Styles ***/
.profile-page-subheader {
  padding: 0;
  height: 56px;
  width: 100%;
}
.profile-page-subheader-nav {
  margin-bottom: 0;
  height: 100%;
  padding-left: 0;
}
.profile-page-subheader-nav > li {
  margin-right: 48px;
  height: 56px;
  display: flex;
  align-items: center;
}
.profile-page-subheader-nav > li:last-child {
  margin-right: 0;
}
.profile-page-subheader-nav > li > a,
.profile-page-subheader-nav > li > p {
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 300ms linear;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  margin-bottom: 0;
  text-decoration: none;
  color: #555;
}
.profile-page-subheader-nav > li a {
  transition: color 300ms linear;
}
.profile-page-subheader-nav > li a:hover,
.profile-page-subheader-nav > li > .active-subheader-nav-link {
  color: $accent-color;
  border-bottom: 2px solid $accent-color;
}
.disabled-link {
  pointer-events: none;
  opacity: 0.2;
}
.disabled-link + .dropdown-box {
  display: none;
}
.dropdown-box {
  background: #fff;
  border-radius: 3px;
  padding: 16px;
  position: absolute;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.12),
    0 2px 18px 2px rgba(0, 0, 0, 0.08);
  transition: opacity 300ms linear;
  opacity: 0;
  visibility: hidden;
}
.dropdown-box.open {
  opacity: 1;
  visibility: visible;
}
.profile-dropdown-box {
  font-size: 12px;
  top: 112px;
  min-width: 280px;
  line-height: 2;
  z-index: 1;
}
.profile-dropdown-box.open {
  opacity: 1;
  visibility: visible;
}
.profile-dropdown-box ul {
  padding-left: 0;
  list-style-type: none;
}
.profile-dropdown-box li a {
  color: #555;
  text-decoration: none;
}
.profile-dropdown-box li a:hover,
.profile-dropdown-box .active-subheader-nav-link {
  border: none;
}
@media only screen and (min-width: 968px) {
  .profile-page-subheader-nav > li:hover .dropdown-box {
    opacity: 1;
    visibility: visible;
  }
}
@media only screen and (max-width: 560px) {
  .profile-page-subheader-nav > li {
    margin-right: 24px;
  }
  .profile-dropdown-box {
    width: 98%;
    left: 1%;
  }
}

/*** Profile Nav Card Styles ***/
.profile-nav-card {
  padding: 24px;
  width: 216px;
  position: fixed;
  top: 112px;
}
.profile-sections-nav,
.profile-sections-nested-nav {
  padding-left: 0;
  list-style-type: none;
}
.profile-sections-nav > li:not(:last-child) {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.profile-link {
  text-transform: uppercase;
  letter-spacing: 0.75px;
  transition: color 300ms linear;
}
.profile-link:hover {
  color: $accent-color;
}
.active-profile-link {
  color: $accent-color;
  margin-left: -25px;
  padding-left: 23px;
  border-left: 2px solid $accent-color;
}
.profile-nav-section-header {
  text-transform: uppercase;
  letter-spacing: 0.75px;
}
.profile-add-icon {
  font-size: 20px;
  margin-top: -2px;
  color: $accent-color;
}
/* Profile Sections Nested Nav Styles */
.profile-sections-nested-nav {
  margin-top: 12px;
  font-size: 12px;
}
.profile-sections-nested-nav > li {
  transition: color 300ms linear;
}
.profile-sections-nested-nav > li:hover {
  color: $accent-color;
}
.profile-sections-nested-nav > li:not(:last-child) {
  margin-bottom: 12px;
}

/*** Profile Form Card Styles ***/
.profile-form-card {
  padding: 24px 32px;
  width: calc(100% - 128px);
  margin-bottom: 24px;
}
form.read-only .profile-form-card {
  margin: 0 auto 32px;
}
@media only screen and (max-width: 1040px) {
  .profile-form-card {
    width: 100%;
    margin: 0;
  }
}
@media only screen and (max-width: 560px) {
  .profile-form-card {
    box-shadow: none;
    border: none;
    border-radius: 0;
    padding: 0;
    height: calc(100vh - 208px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}
/* Card Tabs Header Styles */
.card-tabs-header {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #edecec;
  margin-bottom: 24px;
  list-style-type: none;
}
.card-tabs-header li p {
  text-transform: uppercase;
  padding-bottom: 16px;
  border-bottom: 2px solid transparent;
  transition: all 300ms linear;
  text-align: center;
  width: 216px;
  max-width: 100%;
  margin-bottom: 0;
}
.card-tabs-header li p:hover {
  color: $accent-color;
  cursor: pointer;
}
.card-tabs-header li .has-errors {
  color: $error-color;
}
.card-tabs-header li .active {
  border-bottom: 2px solid $accent-color;
  color: $accent-color;
  cursor: pointer;
}
.card-tabs-header li .active.has-errors {
  border-bottom: 2px solid $error-color;
  color: $error-color;
}
/* Profile Form Card Input Styles */
.profile-form-inputs {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row-gap: 16px;
}
.profile-form-inputs.profile-outreach-form-inputs,
.profile-form-inputs.profile-activities-form-inputs {
  display: block;
}
.profile-form-inputs.profile-activities-form-inputs .custom-input,
.profile-form-inputs.profile-activities-form-inputs .radio-container {
  max-width: 200px;
}
.profile-form-inputs .custom-checkbox[type="checkbox"]:checked + label:after {
  top: 4px;
  left: 4px;
}
.profile-form-section .custom-checkbox[type="checkbox"]:checked + label:after {
  top: 4px;
  left: 4px;
}
.profile-form-card label.accent-text.small-text {
  min-width: 104px;
  width: 104px;
  display: inline-block;
  padding-right: 8px;
}
.profile-form-card label:not(.fw-700) {
  font-weight: 400;
}
label.error {
  color: $error-color;
}
.profile-form-inputs .radio-container input[type="radio"] + label {
  width: 50%;
  min-width: auto;
  margin-bottom: 0;
}
.profile-form-card .radio-button-container label {
  min-width: auto;
  width: auto;
}
.profile-form-inputs .radio-container,
.profile-form-inputs .custom-input {
  width: 100%;
}
.profile-form-inputs .error-message {
  bottom: -13px;
  left: 104px;
  font-size: 11px;
}
@media only screen and (max-width: 560px) {
  .profile-form-inputs {
    display: block;
  }
  .profile-form-inputs > .flex {
    display: block;
    margin-right: 0;
  }
  .profile-form-inputs > .flex:not(:last-child) {
    margin-bottom: 16px;
  }
  .profile-form-inputs .error-message {
    left: 0;
  }
  .profile-form-card label {
    margin-bottom: 4px;
    width: auto;
  }
  .profile-form-card .radio-container label {
    margin-bottom: 4px;
  }
  .profile-form-card
    .radio-button-container
    [type="radio"]:checked
    + label:before,
  .profile-form-card
    .radio-button-container
    [type="radio"]:not(:checked)
    + label:before {
    top: -10px;
  }
  .profile-form-card
    .radio-button-container
    [type="radio"]:checked
    + label:after,
  .profile-form-card
    .radio-button-container
    [type="radio"]:not(:checked)
    + label:after {
    top: -6px;
  }
  .profile-add-icon.hidden {
    display: none;
  }
}
/* Account Info Styles */
.account-info .custom-input:disabled {
  opacity: 1;
}
.account-info.profile-form-inputs > .flex {
  display: flex;
}
/* Personal Info Styles */
.profile-pic-container img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 44px;
}
.address-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
}
@media only screen and (max-width: 560px) {
  .flex.profile-pic-container {
    display: flex;
  }
  .profile-pic-container img {
    margin-right: 32px;
  }
  .address-grid {
    grid-template-columns: 1fr 1fr;
  }
}
/* Spouse Info Styles */
.spouse-card {
  border: 1px solid #edecec;
  padding: 24px 16px;
  grid-column-start: span 2;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row-gap: 16px;
  border-radius: 3px;
}
.spouse-disabled label {
  opacity: 0.5;
}
@media only screen and (max-width: 560px) {
  .spouse-card {
    display: block;
    margin-bottom: 16px;
  }
  .spouse-card > *:not(:last-child) {
    margin-bottom: 16px;
    margin-right: 0;
  }
}
/* Contact Info Styles */
.profile-form-contact-info {
  display: grid;
  grid-template-columns: 1fr 216px 216px 1fr 1fr;
  grid-gap: 16px;
}
_:-ms-lang(x),
.profile-form-contact-info {
  display: block;
}
.profile-form-contact-info > p {
  grid-column-start: span 5;
}
.profile-form-contact-info > p span {
  line-height: 1.4;
}
.profile-form-contact-info > div {
  display: contents;
}
_:-ms-lang(x),
.profile-form-contact-info > div {
  display: flex;
}
.profile-form-contact-info .custom-select {
  min-width: 112px;
  margin-right: 16px;
}
.profile-form-contact-info .custom-input {
  width: 100%;
}
.profile-form-contact-info .phone-input {
  padding-left: 8px;
  padding-top: 6px;
}
.profile-form-contact-info > div > .custom-input {
  min-width: 200px;
}
.profile-form-contact-info.ch-form-contact-info .relative.flex label {
  width: 32px;
  min-width: 32px;
}
.contact-error-message {
  color: $error-color;
}
@media only screen and (max-width: 1200px) {
  .profile-form-contact-info {
    display: block;
  }
  .profile-form-contact-info > div {
    display: flex;
  }
  .profile-form-contact-info > div > *:not(:last-child) {
    margin-right: 16px;
  }
}
@media only screen and (max-width: 560px) {
  .radio-button-container [type="checkbox"]:checked + label,
  .radio-button-container [type="checkbox"]:not(:checked) + label {
    top: -10px;
  }
  .profile-form-contact-info {
    display: block;
  }
  .profile-form-contact-info .mobile-card {
    display: grid;
    grid-template-columns: 75% 25%;
    border: 1px solid #edecec;
    border-radius: 3px;
    width: 100%;
    padding: 16px 16px 8px;
  }
  .profile-form-contact-info .mobile-card > *:nth-child(1) {
    order: 1;
    grid-column-start: span 2;
    margin-bottom: 8px;
    margin-right: 0;
  }
  .profile-form-contact-info .mobile-card > *:nth-child(2) {
    order: 2;
    grid-column-start: span 2;
    margin-bottom: 16px;
    margin-right: 0;
  }
  .profile-form-contact-info .mobile-card > *:nth-child(3) {
    order: 3;
    margin-bottom: 0;
    margin-left: -16px;
    padding-left: 16px;
    border-top: 1px solid #e0e0e0;
    padding-top: 8px;
    margin-right: 0;
  }
  .profile-form-contact-info .mobile-card > *:nth-child(4) {
    order: 4;
    margin-bottom: 0;
    border-top: 1px solid #e0e0e0;
    margin-right: -16px;
    padding-right: 16px;
    height: 100%;
    padding-top: 8px;
    margin-right: 0;
  }
  .profile-form-contact-info .mobile-card > *:nth-child(5) {
    grid-column-start: span 2;
  }
  .profile-form-contact-info .hover-delete {
    width: 100% !important;
    text-align: right;
  }
  .profile-form-contact-info
    .mobile-card
    .radio-button-container
    [type="checkbox"]:checked
    + label,
  .profile-form-contact-info
    .mobile-card
    .radio-button-container
    [type="checkbox"]:not(:checked)
    + label {
    margin-right: -2px;
  }
  .profile-form-contact-info .mobile-card .custom-select {
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    margin-top: 8px;
  }
}
/* Family Info Styles */
.child-row {
  display: grid;
  grid-template-columns: repeat(3, calc(33% - 9px));
  border-radius: 3px;
  border: 1px solid #edecec;
  padding: 8px 8px 0 8px;
  grid-column-gap: 16px;
  width: calc(100% - 32px);
  margin-bottom: 16px;
}
.child-row > div {
  position: relative;
}
.child-row label {
  text-transform: uppercase;
  font-size: 10px;
}
.child-row .custom-input {
  height: 24px;
  margin-bottom: 8px;
  border-radius: 0;
  max-width: 100%;
  border: none;
  border-bottom: 1px solid #edecec;
  width: 100%;
}
.child-row .custom-input.error {
  border-color: $error-color;
}
.child-row .custom-input:read-only {
  border-bottom: none;
}
.child-row .custom-select > div:first-of-type {
  border: none;
  margin-left: -8px;
  height: 30px;
}
.child-row input {
  font-size: 13px;
  padding: 0;
}
.child-row input[type="date"] {
  font-size: 11.5px;
}
.child-row select {
  padding: 0;
  background: transparent;
  font-size: 13px;
  width: 100%;
}
.child-row input:focus,
.child-row select:focus {
  outline: none;
}
.child-row .error-message {
  bottom: -6px;
}
@media only screen and (max-width: 560px) {
  .child-row-container {
    border: 1px solid #edecec;
    border-radius: 3px;
    align-items: flex-start;
    position: relative;
    padding: 16px;
  }
  .child-row {
    grid-template-columns: 1fr;
    border: none;
    padding: 0;
    width: 100%;
  }
  .child-row label {
    display: block;
  }
  .child-row > div:not(:last-child) {
    margin-bottom: 16px;
    width: 100%;
  }
  .child-row select:last-child {
    margin-bottom: 0;
  }
  .page-wrapper-mobile .child-row input {
    font-size: 16px;
    width: calc(100% - 8px);
  }
  .page-wrapper-mobile .child-row select {
    margin-left: 0;
    font-size: 16px;
  }
  .child-row + .material-icons {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
/* Demographics Served Info Styles */
.demographics-grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 8px;
  align-items: center;
}
.demographics-grid > div {
  display: contents;
}
/* Primary Chabad House Info Styles */
.profile-form-primary-ch-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}
_:-ms-lang(x),
.profile-form-primary-ch-info {
  display: block;
}
.profile-form-primary-ch-info > p {
  grid-column-start: span 2;
}
.profile-form-primary-ch-info > p span {
  line-height: 1.4;
}
.profile-form-primary-ch-info > div {
  display: contents;
}
_:-ms-lang(x),
.profile-form-primary-ch-info > div {
  display: flex;
}
.primary-ch-error-message {
  color: $error-color;
}
/* General Info Styles */
.square-image-upload {
  margin-top: 16px;
  margin-bottom: 16px;
}
.square-image-upload .profile-pic-container img {
  border-radius: 3px;
}
/* Associated School Info Styles */
.profile-form-associated-schools-info {
  display: grid;
  grid-template-columns: auto 56px 1fr 1fr auto;
  grid-gap: 16px;
  align-items: center;
}
.profile-form-associated-schools-info > div {
  display: contents;
}
.profile-form-associated-schools-info > div label.accent-text.small-text {
  display: none;
}
.profile-form-associated-schools-info
  .radio-button-container
  [type="checkbox"]:checked
  + label,
.profile-form-associated-schools-info
  .radio-button-container
  [type="checkbox"]:not(:checked)
  + label {
  top: -12px;
}
@media only screen and (max-width: 560px) {
  .profile-form-associated-schools-info {
    display: block;
  }
  .profile-form-associated-schools-info > div:nth-child(1) {
    display: none;
  }
  .profile-form-associated-schools-info > div label {
    display: block;
    text-transform: uppercase;
    font-size: 10px;
  }
  .profile-form-associated-schools-info > div > * {
    margin-bottom: 16px;
  }
  .profile-form-associated-schools-info .mobile-card {
    display: grid;
    grid-template-columns: auto auto;
    border: 1px solid #edecec;
    border-radius: 3px;
    width: 100%;
    padding: 16px 16px 8px;
  }
  .profile-form-associated-schools-info .mobile-card > *:nth-child(1) {
    order: 1;
    grid-column-start: span 2;
  }
  .profile-form-associated-schools-info .mobile-card > *:nth-child(2) {
    order: 3;
    margin-bottom: 0;
    margin-left: -16px;
    padding-left: 16px;
    border-top: 1px solid #e0e0e0;
    padding-top: 12px;
    padding-bottom: 4px;
  }
  .profile-form-associated-schools-info .mobile-card > *:nth-child(3) {
    order: 2;
    grid-column-start: span 2;
  }
  .profile-form-associated-schools-info .mobile-card > *:nth-child(4) {
    order: 4;
    margin-bottom: 0;
    border-top: 1px solid #e0e0e0;
    padding-top: 12px;
    padding-bottom: 4px;
    margin-right: -16px;
    padding-right: 16px;
  }
  .mobile-card .radio-button-container [type="checkbox"]:checked + label,
  .mobile-card .radio-button-container [type="checkbox"]:not(:checked) + label {
    top: 0;
    padding-left: 18px;
    line-height: 14px;
  }
  .mobile-card .radio-button-container label span {
    text-transform: none;
    font-size: 10px;
    margin-left: 8px;
  }
  .mobile-card .radio-button-container [type="checkbox"]:checked + label:before,
  .mobile-card
    .radio-button-container
    [type="checkbox"]:not(:checked)
    + label:before {
    border-radius: 3px;
    border-color: $accent-color;
    background: transparent;
    height: 14px;
    width: 14px;
    transition: background 300ms linear;
  }
  .mobile-card
    .radio-button-container
    [type="checkbox"]:checked
    + label:before {
    background: $accent-color;
  }
  .mobile-card .radio-button-container [type="checkbox"]:checked + label:after {
    background: none;
    border-radius: 0;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    transform: rotate(-48deg);
    height: 4px;
    margin-left: -1px;
    width: 10px;
  }
  .profile-form-associated-schools-info .text-center {
    text-align: left;
  }
  .profile-form-associated-schools-info .hover-delete {
    text-align: right;
  }
}
/* Contact Info - CH Form Styles */
.profile-form-contact-info.ch-form-contact-info {
  grid-template-columns: 176px 216px 140px 1fr;
}
.profile-form-contact-info.ch-form-contact-info > p {
  grid-column-start: span 3;
}
.ch-ext label {
  min-width: auto;
  width: auto;
}
@media only screen and (max-width: 560px) {
  .profile-form-contact-info.ch-form-contact-info .mobile-card {
    display: block;
  }
  .profile-form-contact-info.ch-form-contact-info
    .mobile-card
    > *:nth-child(3) {
    margin-right: -16px;
    padding-right: 16px;
    text-align: right;
    padding-top: 8px;
    padding-bottom: 0;
  }
}
/* Social Media Styles */
.profile-form-social-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
}
.profile-form-social-info .custom-input {
  width: calc(100% - 104px);
}
@media only screen and (max-width: 560px) {
  .profile-form-social-info {
    grid-template-columns: 1fr;
  }
}
/* Shluchim Styles */
.profile-form-shluchim-info {
  grid-template-columns: 1fr;
  grid-row-gap: 4px;
}
.profile-form-shluchim {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.profile-form-shluchim img {
  height: 24px;
  width: 24px;
  border-radius: 3px;
  margin-right: 8px;
}
.profile-form-shluchim > p {
  margin-bottom: 0;
  margin-right: 16px;
  width: 200px;
}
.profile-form-shluchim > .custom-select {
  width: 200px;
  margin-right: 16px;
}
.profile-form-shluchim > div .custom-select {
  width: 160px;
}

/* Personnel Styles */
.profile-form-personnel-info.profile-form-inputs {
  grid-template-columns: 1fr;
}
.personnel-new > div > div {
  width: 50%;
}
.personnel-new > div > div input {
  width: calc(100% - 104px);
}
.personnel-row-container {
  @extend .child-row-container;
}
.personnel-row {
  @extend .child-row;
  grid-template-columns: repeat(4, calc(25% - 12px));
  margin-bottom: 8px;
  width: 100%;
}
@media only screen and (max-width: 560px) {
  .personnel-row {
    grid-template-columns: 1fr;
  }
}
/* Other Info Styles */
.profile-form-other-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
}
.profile-form-other-info.profile-form-inputs div input,
.profile-form-other-info.profile-form-inputs div .radio-container {
  width: calc(100% - 104px);
}
.profile-form-other-info div:nth-child(2) label {
  width: 104px;
}
.profile-form-other-info div:nth-child(3) label {
  width: 136px;
}
.profile-form-other-info div:nth-child(3) .radio-container {
  width: calc(100% - 136px);
}
.profile-form-other-info div:nth-child(5) label {
  width: 224px;
}
.profile-form-other-info div:nth-child(5) input {
  width: calc(100% - 224px);
}
@media only screen and (max-width: 560px) {
  .profile-form-other-info {
    grid-template-columns: 1fr;
  }
  .profile-form-other-info label {
    display: block;
    width: 100% !important;
  }
  .profile-form-other-info input,
  .profile-form-other-info .radio-container {
    width: 100% !important;
  }
}
/* Studies Info Styles */
.study-area {
  min-width: 320px;
  margin-right: 24px;
}
.profile-form-studies-info .clearable-select .custom-input {
  min-width: 320px;
  margin-right: 24px;
}
.profile-form-studies-info-grid {
  display: grid;
  grid-template-columns: 50% 20% 20% 10%;
  grid-gap: 16px;
}
.profile-form-studies-info .custom-input {
  max-width: 100%;
}
@media only screen and (min-width: 561px) {
  .profile-form-studies-info label {
    display: none !important;
  }
}
@media only screen and (max-width: 560px) {
  .profile-form-studies-info .mobile-card {
    border: 1px solid #edecec;
    border-radius: 3px;
    width: 100%;
    padding: 16px 16px 8px;
    position: relative;
  }
  .profile-form-studies-info .mobile-card > .flex {
    display: block;
  }
  .profile-form-studies-info .mobile-card > .flex > div {
    margin-bottom: 16px;
  }
  .profile-form-studies-info .mobile-card > .flex .hover-delete {
    position: absolute;
    top: 12px;
    right: 16px;
  }
  .profile-form-studies-info label {
    text-transform: uppercase;
    display: block;
    font-size: 10px;
    margin-bottom: 8px;
  }
  .mobile-card .custom-select > div {
    border: none;
  }
  .mobile-card .custom-select__value-container {
    padding-left: 0;
  }
  .mobile-card .custom-select__indicator {
    padding-right: 0;
  }
  .mobile-card .custom-input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 0;
  }
}
/* Population Info Styles */
@media only screen and (max-width: 560px) {
  .profile-form-inputs .flex + p.fw-900.accent-text {
    margin-bottom: 16px;
    margin-top: 24px;
  }
}
/* Profile Form Hover Delete Styles */
@media only screen and (min-width: 1041px) {
  .hover-delete {
    height: 32px;
    display: flex;
    align-items: center;
  }
}
/* Profile Form Mobile Styles */
@media only screen and (max-width: 560px) {
  /* Profile Form Card Mobile Divider Styles */
  .profile-form-card hr.mobile-divider {
    height: 1px;
    width: calc(100% + 32px);
    margin: 24px -16px;
    border: 0.5px solid #edecec;
  }
  .profile-form-card hr.mobile-divider + .mt-48 {
    margin-top: 24px;
  }
  .profile-form-card hr.mobile-divider + .mt-32 {
    margin-top: 24px;
  }
  .profile-form-card hr.mobile-divider + .mb-16 {
    margin-bottom: 0;
  }
  .profile-form-card hr.mobile-divider + .mb-8 {
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
  }
  /* Profile Form Card Mobile Collapsing Sections */
  .mobile-tab-collapsed .profile-form-inputs,
  .mobile-tab-collapsed .profile-form-contact-info,
  .mobile-tab-collapsed .profile-form-family-info {
    height: 0;
    overflow: hidden;
    margin-top: 0;
  }
  /* Profile Form Card Mobile Sections */
  .profile-form-sections {
    display: grid;
    height: calc(100vh - 208px);
    min-height: 0;
    min-width: 0;
  }
  .profile-form-section {
    border-bottom: 1px solid #edecec;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
  }
  .profile-form-section .mt-48 {
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 0;
  }
  .profile-form-section > p {
    margin: 0;
    padding: 16px 0;
    cursor: pointer;
  }
  .profile-form-section > div > p {
    cursor: pointer;
  }
}
/*** Profile Save Button ***/
.profile-save-btn-container {
  position: fixed;
}
.profile-save-btn {
  width: 104px;
  height: 40px;
  text-transform: uppercase;
}
.profile-save-btn-font-size {
  font-size: 11px;
}
.profile-save-btn-container p {
  display: flex;
  align-items: center;
  font-size: 11px;
  margin-top: 12px;
}
.profile-save-btn-container p i {
  font-size: 13px;
  margin-right: 4px;
}
.profile-save-btn-container .error-message {
  position: relative;
  width: 140px;
  display: flex;
  flex-direction: column;
}
.profile-save-btn-container .error-message span {
  display: block;
  margin-bottom: 8px;
}
@media only screen and (max-width: 1040px) {
  .profile-save-btn {
    height: 32px;
    line-height: 32px;
    position: unset;
  }
}
@media only screen and (min-width: 561px) and (max-width: 1040px) {
  .profile-save-btn-container {
    position: absolute;
    top: -56px;
    left: -105px;
  }
  .profile-save-btn-container p {
    position: absolute;
    top: -2px;
    left: -112px;
  }
  .profile-save-btn-container .btn {
    padding: 0 8px;
  }
}
@media only screen and (max-width: 560px) {
  .profile-save-btn-container {
    position: fixed;
    bottom: 0;
    top: auto;
    left: 0;
    z-index: 10;
    height: 96px;
    background: #fff;
    width: calc(100% + 16px);
    margin-left: -16px;
    border-top: 1px solid #edecec;
  }
  .profile-save-btn {
    width: calc(100% - 48px);
    margin-left: 32px;
    height: 48px;
    margin-top: 24px;
  }
  .profile-save-btn-font-size {
    font-size: 14px;
  }
  .profile-save-btn-container p {
    margin-top: 4px;
    justify-content: center;
  }
}
/*** Profile Incomplete Tooltip ***/
.profile-tooltip .material-icons {
  color: $error-color;
}
.profile-tooltip .tooltip {
  background-color: $error-color;
}

/*** Profile Card - Chabad House Options Styles ***/
.chabad-house-options-card {
  padding: 96px 40px 180px 40px;
  justify-content: space-around;
  width: 100%;
}
.chabad-house-options-card > div:first-child,
.chabad-house-options-card > div:last-child {
  max-width: 280px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chabad-house-options-card > div > p.medium-text {
  margin-bottom: 56px;
}
.chabad-house-options-card > div:nth-child(2) {
  width: 56px;
  margin: 0 12px;
  display: flex;
  justify-content: center;
}
.chabad-house-options-card div:nth-child(2) p {
  height: 56px;
  width: 56px;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: #fff;
}
.chabad-house-options-card div:nth-child(2) .line {
  background: rgba(0, 0, 0, 0.1);
  height: calc(100% + 276px);
  width: 1px;
  margin: -96px 0 -180px 0;
}
.chabad-house-options-card .custom-select {
  max-width: 280px;
}
.chabad-house-options-card .link-text {
  display: block;
  text-align: right;
  text-transform: uppercase;
  margin-top: 24px;
  align-self: flex-end;
  border: none;
  font-family: inherit;
  background: none;
  transition: opacity 300ms linear;
}
.chabad-house-options-card .link-text:focus {
  border: none;
  outline: none;
}
.chabad-house-options-card .link-text:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.chabad-house-options-card .link-text:hover {
  opacity: 0.5;
}
@media only screen and (max-width: 968px) {
  .chabad-house-options-card {
    display: block;
    padding: 48px 16px;
  }
  .chabad-house-options-card > div:first-child,
  .chabad-house-options-card > div:last-child {
    max-width: none;
    width: 100%;
  }
  .chabad-house-options-card > div:nth-child(2) {
    width: 100%;
    height: 56px;
    margin: 32px 0;
  }
  .chabad-house-options-card > div:nth-child(2) .line {
    height: 1px;
    width: 100%;
    margin: 28px 0 0 0;
  }
  .chabad-house-options-card > div > p.medium-text {
    margin-bottom: 24px;
  }
  .chabad-house-options-card .link-text {
    align-self: auto;
  }
}
@media only screen and (max-width: 560px) {
  .chabad-house-options-card {
    border-radius: 3px;
    box-shadow: 0 2px 10px -3px rgba(0, 0, 0, 0.12);
    border: 1px solid rgba(0, 0, 0, 0.04);
    margin: 16px;
    width: calc(100% - 32px);
    height: auto;
  }
}

/***** COLUMNS TO EXPORT MODAL STYLES *****/
.columns-to-export-modal {
  max-height: 80vh;
  position: absolute;
  top: 10%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: visible;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  transform: translate(-50%, 0px);
  min-width: 250px;
  white-space: pre-line;
}
.column-selection {
  max-height: calc(80vh - 180px);
}

/***** SHLIACH DETAILS PAGE STYLES *****/
.shliach-details-page {
  @extend .trip-page;
  padding-top: 152px;
}
.shliach-details-page .program-schedule-details-tabs p {
  padding: 8px 0;
}
.shliach-details-page .program-schedule-details-tabs {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.shliach-details-page .program-activity-table-row {
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr;
  grid-gap: 16px;
  padding: 6px 0;
}
.shliach-details-page .program-activity-table-row p:nth-child(3) {
  text-align: right;
}
.shliach-details-page .resources-activity-table-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 3fr;
  grid-gap: 16px;
  padding: 6px 0;
}
.shliach-details-card {
  padding: 0;
}
.shliach-details-card .profile-image {
  height: 72px;
  width: 72px;
  border-radius: 100%;
}
.shliach-details-card .profile-image-sm {
  height: 32px;
  width: 32px;
  border-radius: 10%;
}
.shliach-details-card .profile-image-sm-placeholder {
  height: 32px;
  width: 32px;
}
.shliach-details-card .social-links img {
  height: 16px;
}
.shliach-details-card > div {
  padding: 24px;
}
.shliach-details-card > div:nth-child(2) {
  border-top: 1px solid #eee;
}
.shliach-details-card .chabad-house-details {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
}
.shliach-details-card .chabad-house-details > div {
  padding-right: 8px;
}
.shliach-details-card .chabad-house-details > div:not(:last-child) {
  border-right: 1px solid #eee;
}
.shliach-details-card .chabad-house-details .two-column-grid {
  grid-template-columns: 0.25fr 1fr;
  grid-row-gap: 0;
}
