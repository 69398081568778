body {
  padding: 0;
  margin: 0;
  font-family: "Avenir", sans-serif;
  font-size: $baseFontSize;
  color: $grey;
  background: $lightGrey;
}

a {
  cursor: pointer;
}

.clear {
  float: right;
}

.rfloat {
  float: right;
}

.lfloat {
  float: left;
}

.lfloatmb1 {
  float: left;
  margin-bottom: 1em;
}

.align-center {
  text-align: center;
}

.icon-filter.icon-white .path2:before,
.icon-shluchim.icon-white:before {
  color: white;
}

.label-primary {
  padding: 4px 6px;
  margin-left: 10px;
  background: $blue;
  border-radius: 3px;
}

.rrui__select__button {
  border: none;
}
