.deselect-checkbox {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: $blue;
  border: 1px solid $blue;
  border-radius: 3px;

  &:before {
    position: absolute;
    top: -7px;
    right: 5px;
    font-size: 22px;
    color: white;
    content: "-";
  }
}

.styled-checkbox {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
}

.styled-checkbox + label:before {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: text-top;
  content: "";
  background: white;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.styled-checkbox:checked + label:after {
  position: absolute;
  top: 9px;
  left: 5px;
  width: 2px;
  height: 2px;
  content: "";
  background: white;
  box-shadow:
    2px 0 0 white,
    4px 0 0 white,
    4px -2px 0 white,
    4px -4px 0 white,
    4px -6px 0 white,
    4px -8px 0 white;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.styled-checkbox:checked + label:before {
  background: $blue;
  border-color: $blue;
}
