$accent-color: #53b7e8;
$accent-color-dark: #0c425d;
$error-color: #e94746;
$accent-text-light: rgba(0, 0, 0, 0.16);
$accent-text: rgba(0, 0, 0, 0.38);
$accent-text-dark: rgba(0, 0, 0, 0.6);

//common styles
@import "../../lib/coc-common-styles/_common-styles.scss";

//components
@import "components/mixins";
@import "components/Config";
@import "components/Courses";
@import "components/Engagement";
@import "components/Lamplighters";
@import "components/LifeInsurance";
@import "components/MergeDuplicates";
@import "components/PortalRedirects";
@import "components/Programs";
@import "components/ProgramSettings";
@import "components/_QuickLinks.scss";
@import "components/Raffles";
@import "components/Rsvp";
@import "components/Shluchim";
@import "components/Students";
@import "components/StyledComponents";
@import "components/Trips";

.bg-status-active {
  background-color: #72d798;
  &:active {
    background-color: #72d798;
  }
  &:active:focus {
    background-color: #72d798;
  }
  &:hover {
    background-color: #72d798;
  }
  &:focus {
    background-color: #72d798;
  }
}

.bg-status-inactive {
  background-color: #f36464;
  color: white;
  &:active {
    background-color: #f36464;
    color: white;
  }
  &:active:focus {
    background-color: #f36464;
    color: white;
  }
  &:hover {
    background-color: #f36464;
    color: white;
  }
  &:focus {
    background-color: #f36464;
    color: white;
  }
}

.date-input {
  border-radius: 3px;
  height: 48px;
  width: 100%;
  padding: 6px 12px;
  border: solid 2px #e2e2e2;
}

.dropzone {
  display: flex;
  align-items: center;
  color: $accent-color;
  text-transform: uppercase;
  font-weight: 700;
  .upload-thumbnail {
    height: 60px;
    width: 60px;
    background: #edecec;
    border-radius: 100%;
    margin-right: 16px;
  }
}

.icon-program-details:before {
  content: "\e944";
}

.icon-status-active:before {
  color: #72d798;
  content: "\2B24";
}

.icon-status-inactive:before {
  color: #f36464;
  content: "\2B24";
}

// .image-preview-modal {
//   position: absolute;
//   top: 75px;
//   left: 210px;
//   right: 20px;
//   bottom: 50px;
//   padding: 24px;

//   &:focus {
//     outline: none;
//   }

//   .image-preview-close {
//     position: absolute;
//     top: 0px;
//     right: 0px;
//     width: 24px;
//     height: 24px;
//     background-color: rgb(215, 21, 38);
//     opacity: 0.90;
//     color: white;
//     text-align: center;
//     cursor: pointer;
//     font-weight: bold;
//   }
//   // TODO: Figure out how to keep this images aspect ratio and center it...
//   img {
//     width: 100%;
//     height: 100%;
//   }

// }

.programs-help-modal {
  position: absolute;
  top: 75px;
  left: 220px;
  right: 25px;
  bottom: 60px;
  overflow: auto;

  padding: 24px;

  background-color: white;

  &:focus {
    outline: none;
  }

  .programs-help-close {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 24px;
    height: 24px;
    background-color: rgb(215, 21, 38);
    opacity: 0.9;
    color: white;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
  }
  // TODO: Figure out how to keep this images aspect ratio and center it...
  img {
    width: 100%;
    height: 100%;
  }
}

.widen-contents {
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
}

/***** ERROR PAGE STYLES *****/
.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-height: 880px;
}
.error-page .btn {
  transition: all 300ms linear;
}
.error-page .btn-accent {
  background: $accent-color;
  color: #fff;
  border: 1px solid $accent-color;
}
.error-page .btn-accent:hover:not(:disabled) {
  background: #fff;
  color: $accent-color;
}
.error-page .btn-cancel {
  background: #ebebeb;
  color: $accent-text-dark;
  border: 1px solid #ebebeb;
}
.error-page .btn-cancel:hover:not(:disabled) {
  background: $accent-text-dark;
  color: #ebebeb;
}
.error-page .btn-medium {
  height: 32px;
  line-height: 32px;
  padding: 0 16px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/***** REACT DATEPICKER STYLES *****/
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  font-size: 12px;
  height: auto !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding: 0 !important;
}

/***** CUSTOM BUTTON STYLES *****/
.custom-btn {
  border-radius: 3px;
  letter-spacing: 0.2px;
  font-size: 14px;
  padding: 0 16px;
  height: 40px;
  line-height: 40px;
  transition: background 300ms linear, color 300ms linear, opacity 300ms linear;
  cursor: pointer;
}
a.custom-btn {
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-btn:focus {
  outline: none;
}
.custom-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.custom-btn.btn-large {
  height: 48px;
  line-height: 48px;
}
.custom-btn.btn-medium {
  height: 32px;
  line-height: 32px;
}
.custom-btn.btn-small {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
}
.custom-btn.btn-fullWidth {
  width: 100%;
}
.custom-btn.btn-accent {
  background: $accent-color;
  color: #fff;
  border: 1px solid $accent-color;
}
.custom-btn.btn-accent:hover:not(:disabled) {
  opacity: 0.5;
}
.custom-btn.btn-accent-secondary {
  background: #f3893d;
  color: #fff;
  border: 1px solid #f3893d;
}
.custom-btn.btn-accent-secondary:hover:not(:disabled) {
  background: #fff;
  color: #f3893d;
}
.custom-btn.btn-light {
  background: #edecec;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #edecec;
}
.custom-btn.btn-light:hover:not(:disabled) {
  background: rgba(0, 0, 0, 0.87);
  color: #edecec;
}
.custom-btn.btn-delete {
  background: #ec5150;
  color: #fff;
  border: 1px solid #ec5150;
}
.custom-btn.btn-delete:hover:not(:disabled) {
  opacity: 0.5;
}

/***** MODAL STYLES *****/
.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 100vh;
}
