.react-phone-number-input__row {
  position: relative;
  display: flex;
  align-items: center;
}

.react-phone-number-input__country {
  width: auto;
}

.react-phone-number-input__phone,
.react-phone-number-input__country.rrui__select--expanded {
  flex: 1;
}

.react-phone-number-input__phone {
  font-size: inherit;
}

.react-phone-number-input__country.rrui__select--collapsed {
  margin-right: 0.5em;
}

.react-phone-number-input__country .rrui__select__autocomplete {
  width: 100%;
}

.react-phone-number-input__country,
.react-phone-number-input__country:not(
    .react-phone-number-input__country--native-expanded
  )
  .rrui__input {
  position: static;
}

.react-phone-number-input .rrui__select__arrow {
  margin-top: 0.3em;
  margin-left: 0.3em;
}

.react-phone-number-input .rrui__select__option {
  display: flex;
  align-items: center;
}

.react-phone-number-input .rrui__select__option-icon {
  margin-bottom: 0.1em;
}

.react-phone-number-input__icon {
  width: 1.24em;
  height: 0.93em;
  box-sizing: content-box;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.react-phone-number-input__icon--international {
  width: calc(0.93em + 2px);
  height: calc(0.93em + 2px);
  padding-right: 0.155em;
  padding-left: 0.155em;
  border: none;
}

.react-phone-number-input__error {
  margin-left: calc(0.93em + 2px);
}

.phone-number-separator {
  margin: 0 7px;
}
