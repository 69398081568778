.nav-tabs {
  border: none;

  li {
    a {
      font-family: "Avenir";
      font-size: $baseFontSize;
      color: $grey;
      border: none;

      &:hover {
        color: $tabBlue;
        background: none;
        border: none;
      }
    }

    &.active {
      border-bottom: 3px solid $tabBlue;

      a,
      a:focus {
        background: none;
        border: none;

        &:hover {
          background: none;
          border: none;
        }
      }
    }
  }
}
