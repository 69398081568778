$blue: #00a4ea;
$menuBlue: #337ab7;
$buttonBlue: #30a4dc;
$tabBlue: #3ba4da;
$lightBlue: #eff7fb;
$darkBlue: #0071a5;
$grey: #555;
$darkGrey: #333;
$lightGrey: #fafafa;
$lighterGrey: #eee;
$lightWhite: #e2e2e2;
$lighterWhite: #f5f5f5;
$labelColor: #a0a3a9;
$itemColor: #7f8c8d;
$warningColor: #d03b38;
$baseFontSize: 16px;
