@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 900;
  src: url("../../../assets/fonts/Avenir-Black.eot");
  src:
    url("../../../assets/fonts/Avenir-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/Avenir-Black.woff") format("woff"),
    url("../../../assets/fonts/Avenir-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 700;
  src: url("../../../assets/fonts/Avenir-Heavy.eot");
  src:
    url("../../../assets/fonts/Avenir-Heavy.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/Avenir-Heavy.woff") format("woff"),
    url("../../../assets/fonts/Avenir-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/Avenir-Roman.eot");
  src:
    url("../../../assets/fonts/Avenir-Roman.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/Avenir-Roman.woff") format("woff"),
    url("../../../assets/fonts/Avenir-Roman.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 300;
  src: url("../../../assets/fonts/Avenir-Light.eot");
  src:
    url("../../../assets/fonts/Avenir-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/Avenir-Light.woff") format("woff"),
    url("../../../assets/fonts/Avenir-Light.ttf") format("truetype");
}
