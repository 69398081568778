.login {
  position: absolute;
  right: 0;
  left: 0;
  padding: 40px;
  background: $lightGrey;

  @media (max-width: 550px) {
    padding: 20px;
  }
}

.login-panel {
  max-width: 1140px;
  margin: 0 auto;

  .panel-heading {
    border: none;
  }

  .panel-title {
    font-family: "Avenir";
    font-weight: 100;
  }

  .panel {
    margin-top: 20px;
  }
}

.login-jumbo {
  height: 450px;
  padding: 100px 80px;
  margin-bottom: 20px;
  text-align: left;
  background-image: url("../../../assets/images/loginbg.png");
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 550px) {
    height: 350px;
    padding: 50px;
  }
}

.login-logo {
  width: 123px;
  margin-bottom: 10px;
}

.login-header {
  font-family: "Avenir Black";
  font-size: 58px;
  color: $blue;
}

.login-app-name {
  font-family: "Avenir";
  font-size: 58px;
  color: $grey;
}

.login-header,
.login-app-name {
  @media (max-width: 550px) {
    font-size: 40px;
  }
}
