.content-window {
  min-height: 50vh;
}

.typeahead-suggestion {
  padding: 0.5em;
  cursor: pointer;
}

.typeahead-suggestion.highlighted {
  color: #fff;
  background: #5bc0de;
}

.recharts-pie-label-text tspan {
  font-weight: bold;
}

.no-participants-message {
  width: 100%;
  height: 100%;
}

.no-participants-message tspan {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  font-size: 2em;
  color: #636c72;
  transform: translate(-50%, -50%);
}

.custom-select.user-title-select {
  width: auto;
  max-width: 180px;
}
.office-staff-user-radio {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.office-staff-user-radio > .radio-button-container {
  margin-right: 16px;
}
