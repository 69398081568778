.list-container {
  padding: 25px;
  margin-bottom: 30px;
  background: white;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='39' viewBox='0 0 29 39'%3E%3Cg fill='%2330A4DC' fill-rule='evenodd'%3E%3Cpath d='M15.195 12.378a.429.429 0 0 1 .61 0l5.07 5.176a.451.451 0 0 1 0 .623l-5.07 5.175a.429.429 0 0 1-.61 0l-5.07-5.175a.451.451 0 0 1 0-.623M5.195 6.253a.429.429 0 0 1 .61 0l5.07 5.175a.451.451 0 0 1 0 .624l-5.07 5.175a.429.429 0 0 1-.61 0l-5.07-5.175a.451.451 0 0 1 0-.624' opacity='.4'/%3E%3Cpath d='M14.195 27.691a.429.429 0 0 1 .61 0l5.07 5.176a.451.451 0 0 1 0 .623l-5.07 5.176a.429.429 0 0 1-.61 0l-5.07-5.176a.451.451 0 0 1 0-.623M23.195.128a.429.429 0 0 1 .61 0l5.07 5.175a.451.451 0 0 1 0 .624l-5.07 5.175a.429.429 0 0 1-.61 0l-5.07-5.175a.451.451 0 0 1 0-.624'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position-x: -5px;
  background-position-y: -5px;
  border-radius: 3px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);

  @include small {
    padding: 15px;
  }

  &-header {
    padding-bottom: 5px;
    margin-bottom: 20px;
    font-size: 24px;
    border-bottom: 1px solid $lightWhite;
  }

  &::after {
    display: block;
    clear: both;
    content: "";
  }

  h1 {
    margin-bottom: 20px;
    font-family: "Avenir";
    font-size: 24px;
  }
}

.download-section {
  display: flex;
  padding: 15px;
  justify-content: flex-end;
  border-top: 3px solid #eff7fb;
  border-bottom: 3px solid #eff7fb;

  .btn-primary {
    margin-left: 15px;
  }
}

.filter-info {
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filter-component {
  display: flex;
  justify-content: flex-end;
}

.select-results {
  color: $blue;
}
