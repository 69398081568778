.contact-overview {
  &-details {
    padding: 0;
    margin-top: 20px;
    list-style: none;
  }

  &-item {
    margin-bottom: 5px;
    font-family: "Avenir";
    font-size: 16px;

    i {
      display: inline-block;
      margin-right: 10px;
    }

    a {
      color: $blue;
    }
  }

  &-gender {
    display: inline-block;
    padding-bottom: 10px;
    font-size: 16px;
    color: $itemColor;
    border-bottom: 4px solid $lightBlue;
  }

  &-image {
    width: 200px;
    border-radius: 50%;
  }

  &-item-name {
    color: $itemColor;
  }
}
