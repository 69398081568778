/***** LAMPLIGHTERS PAGE STYLES *****/
.lamplighters-page.container,
.lamplighters-page .container {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
}
.lamplighters-page.page.container {
  padding-top: 80px;
}
.lamplighters-page {
  font-size: 14px;
  font-family: "Avenir", sans-serif;
}
.pointer {
  cursor: pointer;
}
@media only screen and (max-width: 1040px) {
  .lamplighters-page.container,
  .lamplighters-page .container {
    padding: 0 24px;
    max-width: 848px;
  }
  .lamplighters-page.page.container {
    padding: 40px 24px;
  }
}
@media only screen and (max-width: 560px) {
  .lamplighters-page.container,
  .lamplighters-page .container {
    padding: 0 16px;
  }
  .lamplighters-page.page.container {
    padding: 24px 16px;
  }
}

/***** LAMPLIGHTER SETTINGS STYLES *****/
/*** Subheader Styles ***/
.lamplighters-page-subheader {
  background: #fff;
  height: 64px;
  box-shadow: 0 2px 18px 2px rgba(0, 0, 0, 0.08);
  top: 72px;
  width: calc(100% - 230px);
  display: flex;
  margin-bottom: 40px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.lamplighters-page-subheader .btn {
  font-size: 12px;
}
.lamplighters-page-subheader .container {
  height: 100%;
}
.lamplighters-page-subheader .breadcrumbs-container {
  padding-top: 4px;
}
.lamplighters-page-subheader ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  align-items: center;
  flex: 1;
  height: 100%;
}
.lamplighters-page-subheader li {
  margin-right: 48px;
  border-bottom: 2px solid transparent;
  transition: all 300ms linear;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.lamplighters-page-subheader li.active,
.lamplighters-page-subheader li:hover {
  border-bottom: 2px solid $accent-color;
  color: $accent-color;
}
.lamplighters-page-subheader li.disabled,
.lamplighters-page-subheader li.disabled:hover {
  border-bottom-color: transparent;
  color: $accent-text;
  cursor: not-allowed;
}
.lamplighters-page-subheader li i {
  vertical-align: middle;
}
.lamplighters-page-subheader .tooltip-container {
  height: 100%;
}
/* Wizard Steps Styles */
.lamplighters-page-wizard-steps {
  flex: 1;
}
.lamplighters-page-wizard-steps p {
  margin-bottom: 0;
}
.wizard-step {
  margin-right: 32px;
  display: flex;
  align-items: center;
  color: #333;
  cursor: pointer;
}
.wizard-step .wizard-step-number {
  border: 1px solid #333;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  margin-right: 12px;
  position: relative;
}
.wizard-step.active .wizard-step-number {
  background: $accent-color;
  color: #fff;
  border-color: $accent-color;
}
.wizard-step.active .wizard-step-number:after {
  content: "";
  height: 32px;
  width: 32px;
  border: 1px solid $accent-color;
  border-radius: 100%;
  position: absolute;
  top: -5px;
  left: -5px;
}
/* Wizard Steps Styles */
.wizard-step {
  transition: opacity 300ms linear;
}
.wizard-step.active .wizard-step-title-tab {
  color: $accent-color;
  font-weight: 500;
  cursor: default;
}
.wizard-step:not(.active):hover {
  opacity: 0.5;
}
.wizard-step.active {
  cursor: default;
}
/*** Wizard Step Styles ***/
.lamplighters-settings-step {
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: #fff;
  border-radius: 3px;
  padding: 40px;
  margin-bottom: 24px;
}
.lamplighters-settings-step .section-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lamplighters-settings-step .section-save-btns {
  position: absolute;
  right: 0;
}
.lamplighters-settings-step-section:not(:last-child) {
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid #edecec;
}
.lamplighters-settings-step-section > p.xl-text {
  font-size: 22px;
  margin-bottom: 0;
}
/* Timeline Step */
.lamplighters-label {
  width: 112px;
  min-width: 112px;
  color: $accent-text;
  font-size: 12px;
  margin-bottom: 0px;
  margin-right: 16px;
}
.lamplighters-schedule-timeline-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  width: fit-content;
}
.lamplighters-schedule-timeline .custom-checkbox[type="checkbox"] + label {
  width: 190px;
  top: 0;
  font-weight: 500;
  margin-bottom: 0;
}
.lamplighters-schedule-timeline
  .custom-checkbox[type="checkbox"]
  + label:before,
.lamplighters-schedule-timeline
  .custom-checkbox[type="checkbox"]:checked
  + label:before {
  height: 14px;
  width: 14px;
}
.lamplighters-schedule-timeline
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 5px;
  top: 3px;
}
.lamplighters-schedule-deadline-card {
  border: 1px solid $accent-text-light;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}
/* Configuration Step */
.lamplighters-grant-totals {
  white-space: pre-wrap;
  margin-top: 16px;
}
.lamplighters-grant-configuration-card {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.lamplighters-grant-configuration-card > div:first-child {
  border: 1px solid $accent-text-light;
  padding: 16px;
  width: 100%;
}
.lamplighters-grant-configuration-card .configuration-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
}
.lamplighters-grant-configuration-card .configuration-grid .lamplighters-label {
  min-width: fit-content;
}
.lamplighters-grant-configuration-card .configuration-grid .custom-input {
  width: 100%;
}
.lamplighters-grant-configuration-card
  .custom-checkbox[type="checkbox"]
  + label {
  min-width: calc(100% - 200px);
  top: 0;
  font-weight: 500;
  margin-bottom: 0;
}
.lamplighters-grant-configuration-card
  .custom-checkbox[type="checkbox"]
  + label:before,
.lamplighters-grant-configuration-card
  .custom-checkbox[type="checkbox"]:checked
  + label:before {
  height: 14px;
  width: 14px;
}
.lamplighters-grant-configuration-card
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 5px;
  top: 3px;
}
.lamplighters-grant-configuration-card .classification-requirement {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.lamplighters-grant-configuration-card
  .classification-requirement
  > div:first-child {
  border: 1px solid $accent-text-light;
  padding: 8px;
  width: 100%;
}
/* Locations Step */
.lamplighters-totals-card {
  display: flex;
  align-items: center;
  border: 1px solid $accent-text-light;
  padding: 16px;
  height: 84px;
}
.lamplighters-totals-card p {
  white-space: nowrap;
}
.lamplighters-allocation-cards-grid {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}
.lamplighters-allocation-card {
  border: 1px solid $accent-text-light;
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.lamplighters-allocation-card:not(:last-child) {
  margin-right: 16px;
}
.lamplighters-locations-table .table {
  padding: 8px 0;
}
.lamplighters-locations-table-header {
  @extend .courses-table-header;
  grid-template-columns: 1fr 1fr 1fr auto 60px;
  white-space: pre-wrap;
}
.lamplighters-locations-table-row {
  @extend .courses-table-row;
  grid-template-columns: 1fr 1fr 1fr auto 60px;
}
.lamplighters-locations-table-row:hover {
  opacity: 1;
}
.lamplighters-locations-table-dynamic-cell {
  padding: 0 4px;
  width: 92px;
  text-align: center;
}
.lamplighters-locations-table-row .tooltip-container {
  height: auto;
}
.lamplighters-locations-table-row svg#loading {
  height: 100%;
  width: 100%;
}
/*** Wizard Button Styles ***/
.lamplighters-page-wizard-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px;
}
.lamplighters-page .lamplighters-page-wizard-btns .btn {
  font-size: 12px;
  width: 96px;
}

/***** LAMPLIGHTERS DETAILS STYLES *****/
/*** Lamplighters Card Styles ***/
.lamplighters-card {
  margin-bottom: 32px;
  padding: 24px;
}

/*** Lamplighters Tab Cards Styles ***/
.lamplighters-details-tabs {
  @extend .raffle-details-tabs;
}

/*** Lamplighters Dashboard Tab Styles ***/
.lamplighters-dashboard .lamplighters-allocation-card > div:not(:last-child) {
  margin-bottom: 16px;
}

/*** Lamplighters Shluchim Tab Styles ***/
.lamplighters-shluchim-view-toggle.radio-container input[type="radio"] + label {
  width: 160px;
  margin-bottom: 0;
}
.lamplighters-shluchim-allocations-table-header {
  @extend .courses-table-header;
  grid-template-columns: 1fr 1fr 1fr 0.5fr 0.5fr 6fr 0.5fr;
  grid-gap: 12px;
  white-space: pre-wrap;
}
.lamplighters-shluchim-payments-table-header {
  @extend .courses-table-header;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 0.5fr auto;
  white-space: pre-wrap;
}
.lamplighters-shluchim-allocations-table-row {
  @extend .courses-table-row;
  grid-template-columns: 1fr 1.25fr 1fr 0.5fr 0.5fr 6fr 0.5fr;
  grid-gap: 12px;
}
.lamplighters-shluchim-payments-table-row {
  @extend .courses-table-row;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 0.5fr auto;
}
.lamplighters-shluchim-table-row:hover {
  opacity: 1;
}
.lamplighters-shluchim-table-dynamic-cell {
  @extend .lamplighters-locations-table-dynamic-cell;
  text-align: left;
  min-width: 120px;
}
.lamplighters-shluchim-table-row .export-interactions-button svg#loading {
  height: 16px;
  width: fit-content;
}
.lamplighters-shluchim-table-row .export-interactions-button-error-message {
  right: 0;
  white-space: nowrap;
}
/*** Lamplighters Manage Payments Modal Styles ***/
.lamplighters-payments-modal .modal-card {
  width: 900px;
}
.lamplighters-payments-modal .payment-percentage-input {
  @extend .percentage-input;
  font-weight: normal;
  width: 80px;
}
.lamplighters-payments-modal
  .radio-button-container
  input.payment-percentage-input:not(:checked) {
  position: initial;
}
.lamplighters-payments-modal .payment-title {
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
}
.lamplighters-payments-modal label {
  font-size: 14px;
  font-weight: normal;
  margin-right: 32px;
  margin-bottom: 0px;
}
.lamplighters-payments-modal .manage-payments-totals {
  border: 1px solid #e8e8e8;
  padding: 16px;
  min-width: 280px;
  height: fit-content;
}
.lamplighters-payments-modal .manage-payments-totals .divider {
  border-top: 1px solid #e8e8e8;
  margin: 24px 8px;
}

/* Lamplighters Students Tab Styles */
.lamplighters-students-checkbox
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 4px;
  top: 4px;
}
.lamplights-students-table .table {
  padding: 0;
}
.lamplighters-students-table-header {
  @extend .courses-table-header;
}
.lamplighters-students-table-header .tooltip {
  left: -280px;
  width: 380px;
  white-space: pre-wrap;
}
.lamplighters-students-table-row {
  @extend .courses-table-row;
}
.lamplighters-students-table-row:hover {
  opacity: 1;
}
.lamplighters-students-table-row .student-grant-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.lamplighters-students-table-row .student-grant-container .activate-link {
  font-size: 13px;
  font-weight: 700;
}

.lamplighters-students-filters .search-input-container {
  max-width: calc(100% - 400px);
}
.lamplighters-students-filters .search-input {
  width: 100%;
}
.lamplighters-filter-select {
  @extend .trip-filter-select;
}
.lamplighters-students-filters .lamplighters-filter-select {
  width: 180px;
  min-width: 180px;
}
@media only screen and (max-width: 560px) {
  .lamplighters-students-filters .search-input-container {
    max-width: 100%;
  }
}
/* Student grant cards Styles */
.grant-card {
  padding: 16px;
}
.lamplighters-students-table-row .grant-card {
  padding: 8px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.lamplighters-students-table-row .grant-card .submitted-text {
  color: #63c674;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.grant-card .ineligible-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.active-grant-card,
.flagged-grant-card {
  justify-content: space-between;
}
.active-grant-card {
  border-left: 4px solid #2774ae;
}
.active-grant-card .grant-status-tag {
  color: #2774ae;
}
.flagged-grant-card.eligibility-flag {
  border-left: 4px solid #ec5150;
}
.flagged-grant-card.missing-data-flag {
  border-left: 4px solid #f3893d;
}
.flagged-grant-card.duplicate-flag,
.flagged-grant-card.time-range-flag {
  border-left: 4px solid #fec609;
}
.flagged-grant-card .flag-icon.eligibility-flag {
  color: #ec5150;
}
.flagged-grant-card .flag-icon.missing-data-flag {
  color: #f3893d;
}
.flagged-grant-card .flag-icon.duplicate-flag {
  color: #fec609;
}
.submitted-grant-card {
  border-left: 4px solid #63c674;
}
.potential-grant-card {
  border-left: 4px solid #f3893d;
}
.potential-grant-card .grant-status-tag {
  color: #f3893d;
}
.grant-card .btn {
  height: 28px;
  line-height: 28px;
  font-size: 12px;
}
.grant-card .btn-activate {
  background: #f3893d;
  color: #fff;
  border: 1px solid #f3893d;
}
.grant-card .btn-activate:hover {
  background: #fff;
  color: #f3893d;
}
