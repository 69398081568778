.dt-glyphicon {
  color: gray;
}

.dt-sortable {
}

.dt-sort-asc {
}

.dt-sort-desc {
}
