.btn {
  font-family: "Avenir";
  font-size: $baseFontSize;
  transition: all 300ms linear;
}
.btn-primary {
  background: $blue;
  border-color: $blue;
  &:hover {
    background: $darkBlue;
    border-color: $darkBlue;
  }
}
.btn-accent {
  background: $accent-color;
  color: #fff;
  border: 1px solid $accent-color;
  &:hover {
    background: #fff;
    color: $accent-color;
  }
}
.btn-secondary {
  color: $blue;
  background: white;
  border: 2px solid $blue;

  &:hover {
    color: $darkBlue;
    border-color: $darkBlue;
  }
}
.btn-light {
  color: #333;
  background: #edecec;
  border: 2px solid #edecec;
  &:hover {
    color: #edecec;
    background: #333;
  }
}
.btn-group {
  .btn-primary {
    color: $grey;
    background: white;
    border: none;
    box-shadow: none;
    &:focus,
    &:active,
    &:hover {
      color: $blue;
      background: rgba(83, 183, 232, 0.1);
      box-shadow: none;
    }
  }
  &.open > .dropdown-toggle.btn-primary,
  &.open > .btn-primary {
    color: $blue;
    background: rgba(83, 183, 232, 0.1);
    box-shadow: none;
  }
}

.dropdown.btn-group {
  .btn-default {
    color: white;
    background: $blue;
    border: none;
    box-shadow: none;
    &:active {
      color: $blue;
      background: rgba(83, 183, 232, 0.1);
      box-shadow: none;
    }
  }
}
.dropdown.btn-group.open {
  .btn-default {
    color: $blue;
    background: rgba(83, 183, 232, 0.1);
    box-shadow: none;
  }
}
