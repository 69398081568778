.omnisearch {
  z-index: 3;
  padding: 1em 0;
  margin-left: 20px;

  @include small {
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 0;
  }

  form {
    padding: 0;
  }

  .dropdown {
    @include small {
      width: 100%;
    }

    button {
      height: 40px;
      padding-right: 20px;
      padding-left: 20px;
      font-family: "Avenir";
      font-size: $baseFontSize;
      color: white;
      background-color: $buttonBlue;
      border-right: 0;
      border-radius: 0 3px 3px 0;

      @include small {
        display: flex;
        width: 100%;
        margin-top: 10px;
        justify-content: space-between;
        align-items: center;
        border-radius: 3px;
      }
    }
  }

  .btn-group .dropdown-toggle {
    border: 2px solid $buttonBlue;
  }

  .btn-group.open .dropdown-toggle {
    font-family: "Avenir";
    color: $grey;
    background: white;
    border: 2px solid $lightWhite;
    box-shadow: none;
  }

  .btn-primary {
    position: relative;
    top: 0;
    width: 40px;
    height: 40px;
    font-family: "Glyphicons Halflings";
    color: $buttonBlue;
    vertical-align: top;
    background: white;
    border: solid 2px $lightWhite;
    border-right: none;
    border-radius: 3px 0 0 3px;
  }

  .form-control {
    height: 40px;
    font-family: "Avenir";
    font-size: $baseFontSize !important;
    color: $grey;
    background: white;
    border: 2px solid $lightWhite;
    border-right: 0;
    border-left: 0;
    outline: none;
    box-shadow: none;

    @include small {
      border-right: 2px solid $lightWhite;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .caret {
    margin-left: 10px;
  }

  .btn-primary.glyphicon-search {
    cursor: default;
    border-color: $lightWhite;

    &:hover {
      background: white;
    }

    &:before {
      font-size: 18px;
    }
  }

  .advanced-search {
    margin-top: 8px;
    margin-left: 10px;
    font-family: "Avenir";
    font-size: $baseFontSize;
    color: $blue;
    cursor: pointer;

    @include small {
      display: block;
    }
  }
}

.typeahead-suggestion {
  background: white;
  border-right: 2px solid $lightWhite;
  border-left: 2px solid $lightWhite;

  &:first-child {
    border-top: 2px solid $lightWhite;
  }

  &:last-child {
    border-bottom: 2px solid $lightWhite;
  }
}

.search-typeahead {
  width: 100%;

  @include small {
    display: inline-block;
    width: calc(100% - 40px);
  }

  div > div {
    top: 54px !important;
    left: 0 !important;

    @include small {
      top: 100px !important;
      left: 10px !important;
      width: calc(100% - 10px) !important;
    }
  }
}

.search-mobile-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $lighterGrey;

  .glyphicon-search {
    top: 0;
    padding: 18px;
    font-size: 1.4em;
    color: white;
    cursor: pointer;
    background: $blue;
  }

  .search-logo {
    margin-top: 8px;
  }

  .glyphicon-menu-hamburger {
    top: 0;
    z-index: 4;
    padding: 18px;
    font-size: 1.4em;
    color: $blue;
    cursor: pointer;
  }

  .search-logo {
    width: 30px;
    height: 35px;
  }
}

.search-header-container {
  display: flex;

  @include small {
    display: block;
  }
}

.search-area {
  display: flex;

  @include small {
    display: block;
    padding: 10px;
  }
}
