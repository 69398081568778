/* see issue with react-datepicker here: https://github.com/Hacker0x01/react-datepicker/issues/624#issuecomment-261696513 */
.react-datepicker {
  font-size: 1em;
}

.react-datepicker__header {
  padding-top: 0.8em;
}

.react-datepicker__month {
  margin: 0.4em 1em;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  margin: 0.166em;
  line-height: 1.9em;
}

.react-datepicker__current-month {
  font-size: 1em;
}

.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
}

.react-datepicker__navigation--previous {
  left: 1em;
}

.react-datepicker__navigation--next {
  right: 1em;
}
