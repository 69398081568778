@font-face {
  font-family: "icomoon";
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/icomoon.eot?t6lyye");
  src:
    url("../../../assets/fonts/icomoon.eot?t6lyye#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/icomoon.ttf?t6lyye") format("truetype"),
    url("../../../assets/fonts/icomoon.woff?t6lyye") format("woff"),
    url("../../../assets/fonts/icomoon.svg?t6lyye#icomoon") format("svg");
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  /* Better Font Rendering =========== */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.icon-shluchim:before {
  color: #555;
  content: "\e927";
}
.icon-shlucha .path1:before {
  color: rgb(85, 85, 85);
  content: "\e900";
}
.icon-shlucha .path2:before {
  margin-left: -0.9228515625em;
  color: rgb(249, 203, 202);
  content: "\e901";
}
.icon-shliach .path1:before {
  color: rgb(85, 85, 85);
  content: "\e902";
}
.icon-shliach .path2:before {
  margin-left: -0.8798828125em;
  color: rgb(145, 203, 231);
  content: "\e903";
}
.icon-bookmark .path1:before {
  color: rgb(218, 244, 228);
  content: "\e92d";
}
.icon-bookmark .path2:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e92e";
}
.icon-notification .path1:before {
  color: rgb(254, 241, 201);
  content: "\e92f";
}
.icon-notification .path2:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e930";
}
.icon-copy .path1:before {
  color: rgb(225, 235, 240);
  content: "\e931";
}
.icon-copy .path2:before {
  margin-left: -1em;
  color: rgb(50, 54, 62);
  content: "\e932";
}
.icon-comments .path1:before {
  color: rgb(225, 235, 240);
  content: "\e941";
}
.icon-comments .path2:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e942";
}
.icon-globe .path1:before {
  color: rgb(225, 235, 240);
  content: "\e943";
}
.icon-globe .path2:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e944";
}
.icon-calendar .path1:before {
  color: rgb(244, 190, 189);
  content: "\e945";
}
.icon-calendar .path2:before {
  margin-left: -1em;
  color: rgb(255, 255, 255);
  content: "\e946";
}
.icon-calendar .path3:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e947";
}
.icon-clock .path1:before {
  color: rgb(225, 235, 240);
  content: "\e948";
}
.icon-clock .path2:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e949";
}
.icon-phone .path1:before {
  color: rgb(225, 235, 240);
  content: "\e94a";
}
.icon-phone .path2:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e94b";
}
.icon-email .path1:before {
  color: rgb(225, 235, 240);
  content: "\e94c";
}
.icon-email .path2:before {
  margin-left: -1em;
  color: rgb(255, 255, 255);
  content: "\e94d";
}
.icon-email .path3:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e94e";
}
.icon-gear .path1:before {
  color: rgb(226, 226, 226);
  content: "\e94f";
}
.icon-gear .path2:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e950";
}
.icon-trash .path1:before {
  color: rgb(226, 226, 226);
  content: "\e951";
}
.icon-trash .path2:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e952";
}
.icon-pencil .path1:before {
  color: rgb(248, 220, 219);
  content: "\e953";
}
.icon-pencil .path2:before {
  margin-left: -1em;
  color: rgb(250, 250, 250);
  content: "\e954";
}
.icon-pencil .path3:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e955";
}
.icon-finances .path1:before {
  color: rgb(114, 215, 152);
  content: "\e957";
  opacity: 0.2;
}
.icon-finances .path2:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e958";
}
.icon-tefillin .path1:before {
  color: rgb(85, 85, 85);
  content: "\e959";
}
.icon-tefillin .path2:before {
  margin-left: -1em;
  color: rgb(201, 208, 212);
  content: "\e95a";
}
.icon-tefillin .path3:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e95b";
}
.icon-tefillin .path4:before {
  margin-left: -1em;
  color: rgb(136, 136, 136);
  content: "\e95c";
}
.icon-tefillin .path5:before {
  margin-left: -1em;
  color: rgb(127, 135, 143);
  content: "\e95d";
}
.icon-tefillin .path6:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e95e";
}
.icon-tefillin .path7:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e95f";
}
.icon-chayenu .path1:before {
  color: rgb(85, 85, 85);
  content: "\e960";
}
.icon-chayenu .path2:before {
  margin-left: -1em;
  color: rgb(216, 216, 216);
  content: "\e961";
}
.icon-chayenu .path3:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e962";
}
.icon-chayenu .path4:before {
  margin-left: -1em;
  color: rgb(216, 216, 216);
  content: "\e963";
}
.icon-chayenu .path5:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e964";
}
.icon-chayenu .path6:before {
  margin-left: -1em;
  color: rgb(216, 216, 216);
  content: "\e965";
}
.icon-chayenu .path7:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e966";
}
.icon-chayenu .path8:before {
  margin-left: -1em;
  color: rgb(216, 216, 216);
  content: "\e967";
}
.icon-chayenu .path9:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e968";
}
.icon-chayenu .path10:before {
  margin-left: -1em;
  color: rgb(255, 255, 255);
  content: "\e969";
}
.icon-chayenu .path11:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e96a";
}
.icon-chayenu .path12:before {
  margin-left: -1em;
  color: rgb(174, 224, 248);
  content: "\e96b";
}
.icon-chayenu .path13:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e96c";
}
.icon-chayenu .path14:before {
  margin-left: -1em;
  color: rgb(174, 224, 248);
  content: "\e96d";
}
.icon-lamp .path1:before {
  color: rgb(85, 85, 85);
  content: "\e96e";
}
.icon-lamp .path2:before {
  margin-left: -1em;
  color: rgb(255, 188, 141);
  content: "\e96f";
}
.icon-lamp .path3:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e970";
}
.icon-lamp .path4:before {
  margin-left: -1em;
  color: rgb(254, 229, 152);
  content: "\e971";
}
.icon-lamp .path5:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e972";
}
.icon-lamp .path6:before {
  margin-left: -1em;
  color: rgb(215, 215, 215);
  content: "\e973";
}
.icon-raffle .path1:before {
  color: rgb(85, 85, 85);
  content: "\e974";
}
.icon-raffle .path2:before {
  margin-left: -1em;
  color: rgb(232, 185, 183);
  content: "\e975";
}
.icon-raffle .path3:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e976";
}
.icon-raffle .path4:before {
  margin-left: -1em;
  color: rgb(235, 202, 201);
  content: "\e977";
}
.icon-raffle .path5:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e978";
}
.icon-life-insurance .path1:before {
  color: rgb(85, 85, 85);
  content: "\e97a";
}
.icon-life-insurance .path2:before {
  margin-left: -1em;
  color: rgb(255, 255, 255);
  content: "\e97b";
}
.icon-life-insurance .path3:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e97c";
}
.icon-life-insurance .path4:before {
  margin-left: -1em;
  color: rgb(233, 194, 196);
  content: "\e97d";
}
.icon-media .path1:before {
  color: rgb(85, 85, 85);
  content: "\e97e";
}
.icon-media .path2:before {
  margin-left: -1em;
  color: rgb(236, 236, 236);
  content: "\e97f";
}
.icon-media .path3:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e980";
}
.icon-media .path4:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e981";
}
.icon-media .path5:before {
  margin-left: -1em;
  color: rgb(245, 245, 245);
  content: "\e982";
}
.icon-media .path6:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e983";
}
.icon-media .path7:before {
  margin-left: -1em;
  color: rgb(174, 224, 248);
  content: "\e984";
}
.icon-media .path8:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e985";
}
.icon-media .path9:before {
  margin-left: -1em;
  color: rgb(174, 224, 248);
  content: "\e986";
}
.icon-kinus .path1:before {
  color: rgb(255, 255, 255);
  content: "\e987";
}
.icon-kinus .path2:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e988";
}
.icon-kinus .path3:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e989";
}
.icon-kinus .path4:before {
  margin-left: -1em;
  color: rgb(226, 226, 226);
  content: "\e98a";
}
.icon-kinus .path5:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e98b";
}
.icon-kinus .path6:before {
  margin-left: -1em;
  color: rgb(226, 226, 226);
  content: "\e98c";
}
.icon-shabbaton .path1:before {
  color: rgb(85, 85, 85);
  content: "\e98d";
}
.icon-shabbaton .path2:before {
  margin-left: -1em;
  color: rgb(217, 201, 182);
  content: "\e98e";
}
.icon-shabbaton .path3:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e98f";
}
.icon-shabbaton .path4:before {
  margin-left: -1em;
  color: rgb(242, 227, 208);
  content: "\e990";
}
.icon-shabbaton .path5:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e991";
}
.icon-shabbaton .path6:before {
  margin-left: -1em;
  color: rgb(245, 245, 245);
  content: "\e992";
}
.icon-olive .path1:before {
  color: rgb(85, 85, 85);
  content: "\e993";
}
.icon-olive .path2:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e994";
}
.icon-olive .path3:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e995";
}
.icon-olive .path4:before {
  margin-left: -1em;
  color: rgb(207, 227, 215);
  content: "\e996";
}
.icon-olive .path5:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e997";
}
.icon-olive .path6:before {
  margin-left: -1em;
  color: rgb(207, 227, 215);
  content: "\e998";
}
.icon-olive .path7:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e999";
}
.icon-olive .path8:before {
  margin-left: -1em;
  color: rgb(191, 170, 156);
  content: "\e99a";
}
.icon-olive .path9:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e99b";
}
.icon-olive .path10:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e99c";
}
.icon-olive .path11:before {
  margin-left: -1em;
  color: rgb(191, 170, 156);
  content: "\e99d";
}
.icon-olive .path12:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e99e";
}
.icon-csv-blue .path1:before {
  color: rgb(255, 255, 255);
  content: "\e99f";
}
.icon-csv-blue .path2:before {
  margin-left: -1em;
  color: rgb(83, 183, 232);
  content: "\e9a0";
}
.icon-csv-white:before {
  color: #fff;
  content: "\e92c";
}
.icon-csv .path1:before {
  color: rgb(255, 255, 255);
  content: "\e928";
}
.icon-csv .path2:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e929";
}
.icon-filter .path1:before {
  color: rgb(225, 235, 240);
  content: "\e92a";
}
.icon-filter .path2:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e92b";
}
.icon-staff .path1:before {
  color: rgb(254, 254, 254);
  content: "\e90b";
}
.icon-staff .path2:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e90c";
}
.icon-staff .path3:before {
  margin-left: -1em;
  color: rgb(74, 144, 226);
  content: "\e90d";
}
.icon-staff .path4:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e90e";
}
.icon-staff .path5:before {
  margin-left: -1em;
  color: rgb(254, 254, 254);
  content: "\e90f";
}
.icon-staff .path6:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e910";
}
.icon-staff .path7:before {
  margin-left: -1em;
  color: rgb(83, 183, 232);
  content: "\e911";
  opacity: 0.1;
}
.icon-staff .path8:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e912";
}
.icon-staff .path9:before {
  margin-left: -1em;
  color: rgb(226, 226, 226);
  content: "\e913";
}
.icon-staff .path10:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e914";
}
.icon-staff .path11:before {
  margin-left: -1em;
  color: rgb(123, 202, 240);
  content: "\e915";
}
.icon-staff .path12:before {
  margin-left: -1em;
  color: rgb(83, 183, 232);
  content: "\e916";
}
.icon-staff .path13:before {
  margin-left: -1em;
  color: rgb(123, 202, 240);
  content: "\e917";
}
.icon-staff .path14:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e918";
}
.icon-results .path1:before {
  color: rgb(225, 235, 240);
  content: "\e919";
}
.icon-results .path2:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e91a";
}
.icon-results .path3:before {
  margin-left: -1em;
  color: rgb(255, 255, 255);
  content: "\e91b";
}
.icon-organization .path1:before {
  color: rgb(85, 85, 85);
  content: "\e91c";
}
.icon-organization .path2:before {
  margin-left: -1em;
  color: rgb(217, 201, 182);
  content: "\e91d";
}
.icon-organization .path3:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e91e";
}
.icon-organization .path4:before {
  margin-left: -1em;
  color: rgb(191, 170, 156);
  content: "\e91f";
}
.icon-organization .path5:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e920";
}
.icon-organization .path6:before {
  margin-left: -1em;
  color: rgb(254, 229, 152);
  content: "\e921";
}
.icon-user .path1:before {
  color: rgb(85, 85, 85);
  content: "\e922";
}
.icon-user .path2:before {
  margin-left: -1em;
  color: rgb(226, 226, 226);
  content: "\e923";
}
.icon-user .path3:before {
  margin-left: -1em;
  color: rgb(245, 245, 245);
  content: "\e924";
}
.icon-user .path4:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e925";
}
.icon-user .path5:before {
  margin-left: -1em;
  color: rgb(226, 226, 226);
  content: "\e926";
}
.icon-chabad .path1:before {
  color: rgb(230, 240, 245);
  content: "\e909";
}
.icon-chabad .path2:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e90a";
}
.icon-students .path1:before {
  color: rgb(83, 183, 232);
  content: "\e904";
  opacity: 0.1;
}
.icon-students .path2:before {
  margin-left: -1em;
  color: rgb(248, 220, 219);
  content: "\e907";
}
.icon-students .path3:before {
  margin-left: -1em;
  color: rgb(85, 85, 85);
  content: "\e908";
}
.icon-school .path1:before {
  color: rgb(236, 236, 236);
  content: "\e905";
}
.icon-school .path2:before {
  margin-left: -1em;
  color: rgb(102, 102, 102);
  content: "\e906";
}
