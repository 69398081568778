.form {
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="number"] {
    width: 100%;
    height: 48px;
    padding-left: 10px;
    background-color: #fff;
    border: solid 2px #e2e2e2;
    border-radius: 3px;
    box-shadow: inset 0 1px 0 0 #ececec;
    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }

  textarea {
    width: 100%;
    height: 80px;
    padding-left: 10px;
    background-color: #fff;
    border: solid 2px #e2e2e2;
    border-radius: 3px;
    box-shadow: inset 0 1px 0 0 #ececec;
  }

  .container {
    margin-left: 0;
  }

  &-label {
    display: block;
    font-size: 14px;
    font-weight: 100;
    color: $labelColor;
  }

  &-required:after {
    padding-left: 5px;
    color: $warningColor;
    content: "*";
  }
}

form {
  padding-bottom: 60px;
}

.form-actions {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 12px 0;
  padding-left: 15px;
  background: white;
  border-top: 1px solid #eee;
  box-shadow: rgb(217, 228, 237) 1px 1px 10px 0;
  z-index: 2;

  @media (min-width: 800px) {
    padding-left: 245px;
  }

  .btn {
    min-width: 80px;
    margin-right: 20px;
  }
}
