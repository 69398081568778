/***** TRIP PAGE STYLES *****/
.trip-page {
  padding: 80px 40px 40px 40px;
  font-size: 14px;
}

.trip-page .tooltip-container {
  height: unset;
}

/*** Trip Card Styles ***/
.trip-card.card {
  padding: 24px;
}

.trip-card .trip-event-select {
  width: 600px;
  min-width: 144px;
  border: none;
  box-shadow: none;
}

.trip-card .trip-event-select * {
  font-size: 20px;
}

.trip-card .trip-event-select.is-focused > .Select-control,
.trip-event-select.is-focused:not(.is-open) > .Select-control {
  border: 1px solid #edecec;
  box-shadow: none;
}

.trip-card .two-column-grid {
  grid-gap: 8px 40px;
}

.trip-card .two-column-grid label {
  font-weight: normal;
  font-size: 14px;
  margin-right: 24px;
  width: 112px;
  min-width: 112px;
}

.trip-card .two-column-grid .copy-code-container {
  width: 100%;
}

.trip-link {
  @extend .raffles-link;
}

.chaperone {
  height: 32px;
  width: 94%;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.04);
  margin-bottom: 8px;
  padding-top: 6px;
}

/*** Trip Tab Cards Styles ***/
.trip-details-tabs {
  @extend .raffle-details-tabs;
}

.sort-arrows {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.sort-arrows i {
  font-size: 12px;
  color: $accent-text-dark;
  line-height: 8px;
}

/* Student Styles */
.trip-student-checkin-modal {
  width: 850px;
  max-height: 700px;
  overflow: auto;
}

.trip-student-checkin-modal .student-checkin-details {
  max-height: 400px;
  overflow: auto;
  padding-right: 6px;
}

.trip-student-checkin-modal .two-column-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 0px;
  grid-column-gap: 4px;
  font-size: 14px;
  margin-left: 18px;
}
.trip-student-checkin-modal .four-column-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 0px;
  grid-column-gap: 4px;
  font-size: 14px;
  margin-left: 18px;
}
.trip-students-table-filter-inputs {
  display: flex;
  align-items: center;
}
.trip-students-table-filter-inputs .search-input {
  height: 36px;
  width: 340px;
  opacity: 0.65;
  input {
    font-family: "Avenir";
    letter-spacing: normal;
    font-size: 14px;
  }
}
.select-search-input {
  margin-left: 16px;
}

.trip-filter-select {
  min-width: 250px;
  max-width: 100%;
  border-radius: 3px;
  .Select-clear-zone,
  .Select-arrow-zone {
    background-color: #fff;
  }
}

.trip-filter-select .Select-control {
  height: 36px;
  border: 1px solid #edecec;
  border-color: #edecec !important;
  box-shadow: none !important;
}

.filter-by-select .Select-control {
  border: none;
  background: transparent;
  color: $accent-color;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .Select-multi-value-wrapper {
    max-height: 32px;
    .Select-input {
      display: none !important;
    }
  }
}

.filter-by-select .Select-control:hover {
  box-shadow: none !important;
}

.filter-by-select .Select-placeholder {
  color: $accent-color;
  position: relative;
}

.filter-by-select.Select.is-focused:not(.is-open) > .Select-control {
  box-shadow: none !important;
}

.trip-students-table-header {
  border-top: 1px solid #edecec;
  border-bottom: 1px solid #edecec;
  display: grid;
  grid-template-columns: 20% 25% 20% 15% 15% auto 136px;
  padding: 0 16px;
  height: 40px;
  align-items: center;
  font-weight: 700;
  grid-gap: 24px;
}

.trip-students-table-header.read-only {
  grid-template-columns: 20% 25% 20% 15% 15% auto 0px;
}

.trip-students-table p {
  margin-bottom: 0;
}

.trip-students-table .custom-checkbox[type="checkbox"] + label,
.trip-shluchim-table .custom-checkbox[type="checkbox"] + label {
  top: 0;
  display: block;
}

.trip-students-table .custom-checkbox[type="checkbox"] + label:before,
.trip-shluchim-table .custom-checkbox[type="checkbox"] + label:before,
.trip-students-table .custom-checkbox[type="checkbox"]:checked + label:before,
.trip-shluchim-table .custom-checkbox[type="checkbox"]:checked + label:before {
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.trip-students-table .custom-checkbox[type="checkbox"]:disabled + label:before,
.trip-shluchim-table .custom-checkbox[type="checkbox"]:disabled + label:before {
  cursor: not-allowed;
}

.trip-students-table .custom-checkbox[type="checkbox"]:checked + label:after,
.trip-shluchim-table .custom-checkbox[type="checkbox"]:checked + label:after {
  top: 6px;
  left: 6px;
}

.trip-students-table-sort {
  position: absolute;
  right: 0;
  top: 8px;
}

.trip-students-table .table {
  padding: 0;
}

.trip-students-table-row {
  background: #f5f5f5;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 700;
  color: $accent-text-dark;
  transition: opacity 300ms linear;
  display: grid;
  grid-template-columns: 20% 25% 20% 15% 15% auto 136px;
  padding: 12px 16px;
  margin-top: 12px;
  border-radius: 3px;
  grid-gap: 24px;
  color: inherit;
}
.trip-students-table-row.read-only {
  grid-template-columns: 20% 25% 20% 15% 15% auto 0px;
}
.trip-students-table-row.disabled {
  background: #fafafa;
}

.trip-students-table-row:hover {
  color: inherit;
  text-decoration: none;
}

.trip-students-table.overflow > div {
  overflow: auto;
}

.trip-students-table.overflow {
  .trip-students-table-header {
    border: none;
    grid-gap: 0;
    padding: 0;
  }

  .trip-students-table-header > * {
    border-top: 1px solid #edecec;
    border-bottom: 1px solid #edecec;
    padding: 8px 0 8px 24px;
    height: 40px;
  }

  .trip-students-table-row {
    background: transparent;
    grid-gap: 0;
    padding: 0;
  }

  .trip-students-table-row > * {
    background: #f5f5f5;
    padding: 8px 0 8px 24px;
  }

  .students-table-header-tour:not(:first-child),
  .students-table-row-tour:not(:first-child) {
    padding-left: 24px;
  }

  .students-table-header-tour:not(:first-child) {
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .students-table-row-tour {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .students-table-header-tour:first-child:after,
  .students-table-row-tour:first-child:after {
    content: "";
    background: rgba(83, 183, 232, 0.08);
    position: absolute;
    height: calc(100% + 14px);
    width: 100%;
    left: 0;
    top: 0;
  }

  .students-table-header-tour:first-child:before,
  .students-table-row-tour:first-child:before,
  .students-table-header-tour:not(:first-child):before,
  .students-table-row-tour:not(:first-child):before,
  .students-table-header-tour:last-child:after,
  .students-table-row-tour:last-child:after {
    content: "";
    background: #53b7e8;
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: calc(100% + 14px);
  }

  .students-table-row-tour:first-child:after,
  .trip-students-table-row:last-child .students-table-row-tour:before {
    height: 100%;
  }

  .students-table-header-tour:not(:first-child):before,
  .students-table-row-tour:not(:first-child):before {
    left: 12px;
  }

  .students-table-row-tour:not(:first-child) {
    position: relative;
  }

  .students-table-row-tour:not(:first-child):before,
  .students-table-row-tour:last-child:after {
    top: -8px;
    height: calc(100% + 28px);
  }

  .trip-students-table-row:last-child
    .students-table-row-tour:not(:first-child):before,
  .trip-students-table-row:last-child
    .students-table-row-tour:last-child:after {
    height: calc(100% + 16px);
  }

  .students-table-header-tour:last-child:after,
  .students-table-row-tour:last-child:after {
    left: auto;
    right: 0;
  }
}

.student-action-items {
  .item {
    border-top: 1px solid #edecec;
    margin-top: 24px;
    padding-top: 24px;

    .header {
      font-size: 16px;
      font-weight: 700;
    }

    .action-btn {
      width: 215px;
      background-color: #53b7e8;
      color: white;
      box-shadow: none;
      height: 36px;
      font-size: 14px;
      margin-top: 8px;

      &:hover {
        opacity: 0.5;
        transition: opacity 300ms linear;
      }
    }
  }
}

.trip-checkbox {
  .MuiFormControlLabel-label {
    font-size: 14px;
    font-family: "Avenir";
  }
  .MuiSvgIcon-root {
    font-size: 18px;
    color: $accent-text;
  }
  .MuiButtonBase-root {
    background-color: transparent !important;
  }
  .Mui-checked {
    color: transparent !important;
    .MuiSvgIcon-root {
      color: $accent-color;
    }
  }
}

.preview-btn {
  margin-bottom: 22px !important;

  .visibility-icon {
    margin-right: 8px;
    font-size: 18px;
  }

  .expand-icon {
    margin-left: 8px;
    font-size: 16px;
  }

  .MuiButton-label {
    font-size: 14px;
    color: $accent-color;
    text-transform: none;
  }
}

.email-preview-wrapper {
  border: 1px solid #d8d8dd;
  padding: 12px;
  margin-bottom: 16px;
  text-align: left;

  p {
    font-family: "Avenir";
    font-size: 14px;
    color: black;
  }
}
/* CheckIn Styles */
.tour-schedule-columns {
  width: 160px;
  font-size: 12px;
  white-space: pre;
}
.checkin-table-columns {
  grid-template-columns: 32px 25% 35% 15% 15%;
}
.checkin-modal-error {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  max-width: 100%;
  text-align: end;
  margin-top: 8px;
}
.checkin-shliach-chap-details {
  margin-bottom: 16px;
  .MuiButton-label {
    font-size: 12px;
    color: #53b7e8;
  }
  .MuiButton-root:hover {
    background-color: white;
    opacity: 0.5;
    transition: opacity 300ms linear;
  }
  .expanded-list-wrapper {
    padding-left: 18px;
    font-size: 14px;
    .MuiCollapse-wrapperInner {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
.checkin-btns {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  .btn {
    border-radius: 3px;
    letter-spacing: 0.2px;
    font-size: 14px;
    text-transform: uppercase;
    transition: background 300ms linear, color 300ms linear,
      opacity 300ms linear;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
  }
  .btn-accent {
    background: $accent-color;
    color: #fff;
    border: 1px solid $accent-color;
  }
  .btn-accent:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  .btn-cancel {
    background: #ebebeb;
    color: $accent-text-dark;
    border: 1px solid #ebebeb;
  }
  .btn-cancel:hover {
    opacity: 0.5;
    cursor: pointer;
  }
}
@media only screen and (max-width: 1080px) {
  .checkin-table-columns {
    grid-template-columns: 32px 22% 32% 12% 12%;
  }
  .trip-filter-select {
    width: 145px;
    min-width: unset;
  }
}
@media only screen and (max-width: 820px) {
  .checkin-table-columns {
    grid-template-columns: 12px 18% 20% 15% 12%;
  }
  .trip-students-table-filter-inputs {
    flex-direction: column;
    align-items: flex-start;
  }
  .trip-filter-select {
    margin-top: 12px;
  }
  .select-search-input {
    margin-left: 0px;
  }
}
/* Shluchim Styles */
.trip-shluchim-table .table {
  padding: 0;
}

.trip-shluchim-table p {
  margin-bottom: 0;
}

.trip-shluchim-table .radio-container input[type="radio"] + label {
  width: 106px;
  padding: 0 8px !important;
  margin-bottom: 0;
}

.trip-shluchim-table .radio-container input[type="radio"] + label:last-of-type {
  width: 186px;
  span {
    font-size: 12px;
    font-weight: 500;
    margin-top: 2px;
    margin-left: 4px;
  }
}

.trip-shluchim-table-header {
  @extend .trip-students-table-header;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.5fr 0.5fr 0.5fr 1fr;
  padding: 4px 12px;
  height: auto;
}

.trip-shluchim-table-header.travel-trip {
  grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 0.5fr;
}

.trip-shluchim-table-row {
  @extend .trip-students-table-row;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.5fr 0.5fr 0.5fr 1fr;
  padding: 12px;
  font-weight: 400;
  margin-top: 8px;

  .MuiIconButton-root {
    padding: 8px;
  }

  .MuiIconButton-root:hover {
    background-color: transparent;
    opacity: 0.5;
    transition: opacity 300ms linear;
  }
}

.trip-shluchim-table-row.travel-trip {
  grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 0.5fr;
}

.trip-shluchim-table-row a {
  color: rgb(97, 95, 95);
}

/***** dropdown styles *****/
.trip-page .dropdown {
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms linear;
  position: absolute;
  left: 0;
}

.trip-page .btn-dropdown {
  width: 140px;
}

/* Metrics Styles */
.trip-metrics {
  font-size: 14px;
}

.number-metrics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
}

.number-metrics > div {
  background: #f4f4f4;
  padding: 24px;
  border-radius: 3px;
}

.number-metrics .xxl-text {
  font-size: 34px;
}

.pie-chart-metrics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  margin: 48px 0;
}

.pie-chart-metrics > div {
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 16px;
}

.metrics-pie-chart-label-square {
  height: 14px;
  width: 14px;
  margin-right: 8px;
}

.tour-metrics > div {
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 16px;
  margin-bottom: 16px;
  line-height: 1.75;
}

.tour-metrics > div > div:first-child {
  padding-left: 40px;
  position: relative;
  margin-bottom: 16px;
}

.tour-metrics > div > div:first-child:before {
  content: "";
  height: 16px;
  width: 16px;
  transform: rotate(45deg);
  background: $accent-color;
  position: absolute;
  left: 0;
  top: 8px;
}

.tour-metrics > div > div:nth-child(2) {
  border-top: 1px solid #edecec;
  margin-left: 16px;
  padding-left: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.tour-metrics > div > div > div:nth-child(2) {
  border-left: 1px solid #edecec;
  padding-left: 16px;
}

/* Trip Enrollment Styles */
/*** Trip Form Styles ***/
.trip-page-form-container {
  width: 100%;
}

.trip-page-form-section:not(:last-child) {
  border-bottom: 1px solid #edecec;
  padding-bottom: 24px;
  margin-bottom: 24px;
}

.trip-page-form-container .xxl-text {
  font-size: 22px;
}

.trip-page-form-section .flex.custom-checkbox-container.mb-12:last-child {
  margin-bottom: 0;
}

.trip-page-form-section
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 6px;
}

.trip-page-form-section .multiselect {
  height: 40px;
}

.trip-page-form-section .multiselect > div:first-of-type {
  align-items: flex-start;
  height: 40px;
}

.trip-page-form-section .multiselect > div > div {
  height: 100%;
}

.trip-page-form-section textarea.custom-input {
  height: 80px;
  resize: none;
  width: 100%;
  padding: 8px;
}

/* General Settings Styles */
.additional-chaperone-form label {
  width: 148px;
  min-width: 148px;
}

.additional-chaperone-form .custom-input,
.additional-chaperone-form .phone-input {
  width: 208px;
  max-width: 100%;
}

/* Pricing Styles */
.trip-form-dates-inputs label {
  width: 164px;
  min-width: 148px;
}

.trip-form-dates-inputs .custom-input {
  width: 208px;
  min-width: 208px;
  max-width: 100%;
}

.trip-form-pricing-inputs label {
  width: 240px;
  min-width: 240px;
}

.promo-codes-grid {
  max-width: 840px;
}

.promo-codes-grid > div > div {
  display: grid;
  grid-template-columns: 1.5fr 1.25fr 1.25fr 1.5fr 1fr;
}

.new-promo-modal.card {
  width: 400px;
  max-width: 96%;
  font-size: 14px;
  padding: 24px;
}

.new-promo-modal label {
  font-weight: normal;
  width: 96px;
  min-width: 96px;
}

.new-promo-modal .custom-input {
  width: 100%;
}

.new-promo-modal .limit-input {
  max-width: 154px;
}

/* Tours Styles */
.trip-tours-form-label,
.trip-page-form-label {
  width: 176px;
  min-width: 176px;
  margin-right: 8px;
}

.small-input {
  width: 104px;
}

.schedule-modal-input {
  width: 230px;
}

/* Read-only Inputs Styles */
.trip-page-form-section .radio-container.readonly {
  cursor: default;
}

.trip-page-form-section .radio-container.readonly input + label {
  cursor: default;
}

.trip-page-form-section
  .radio-container.readonly
  input[type="radio"]:disabled
  + label {
  cursor: default;
}

.trip-page-form-section .radio-button-container.readonly {
  cursor: default;
}

.trip-page-form-section .radio-button-container.readonly input + label {
  cursor: default;
}

.trip-page-form-section .custom-input:read-only {
  cursor: default;
}

.trip-page-form-section .custom-input:read-only:focus {
  border-color: #edecec;
}

/* Shliach Enrollment Settings Styles */
.trip-page-subheader {
  @extend .raffles-page-subheader;
}

.trip-page-settings-subheader {
  @extend .raffles-page-settings-subheader;
}

.trip-page-settings-subheader ul {
  justify-content: center;
}

.trip-shliach-enrollment-form {
  font-size: 14px;
}

.trip-shliach-enrollment-form label {
  font-weight: 400;
  color: $accent-text-dark;
  font-size: 14px;
  margin-bottom: 0;
}

.trip-shliach-enrollment-form
  .trip-page-form-section
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 6px;
  top: 3px;
}

.trip-shliach-enrollment-form .hover-delete-container {
  display: flex;
}

.trip-shliach-enrollment-form .radio-container input[type="radio"] + label {
  width: 96px;
}

.trip-shliach-enrollment-form
  .custom-checkbox[type="checkbox"]:checked
  + label:before,
.trip-shliach-enrollment-form .custom-checkbox[type="checkbox"] + label:before {
  width: 16px;
  height: 16px;
}

/*** Student Registration Details Styles ***/
.registration-btn {
  height: 32px;
  width: 96px;
  line-height: 32px;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.24);
  transition: all 300ms linear;
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.registration-btn:hover {
  box-shadow: none;
  border: 1px solid #edecec;
}

.registration-tag {
  height: 32px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

/* Student Registration Status Dropdown */
.trip-registration-status-menu-button svg#loading {
  height: 14px;
  width: 14px;
  margin-left: 8px;
}
.trip-registration-status-menu .MuiPopover-paper {
  width: 300px;
  height: 260px;
  padding: 4px 0px;
}
.trip-registration-status-menu.sub-menu .MuiPopover-paper {
  width: 180px;
  height: 182px;
}
.trip-registration-status-menu.sub-menu-small .MuiPopover-paper {
  width: 140px;
  height: 92px;
}
.trip-registration-status-menu .status-menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  font-size: 16px;
  height: 42px;
}
.trip-registration-status-menu .status-menu-item.descriptive {
  height: 48px;
}
.trip-registration-status-menu .tooltip {
  left: 0px;
}

.trip-students-table-row .trip-registration-status-menu-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0px;
  font-weight: 700;
}
.trip-students-table-row .trip-registration-status-menu-button.open {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.17);
  padding: 4px 8px;
  margin: 0 -8px;
}

.trip-modal .trip-registration-status-menu-button {
  width: 374px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  border: 1px solid #edecec;
  border-radius: 3px;
}
.trip-registration-status-menu.bulk .MuiPopover-paper {
  width: 374px;
}

/* Student Overview Card Styles */
.trip-student-overview-card {
  @extend .card;
  margin-bottom: 32px;
}
.trip-student-overview-card > div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #edecec;
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.trip-student-overview-card .registration-input {
  position: relative;
}
.trip-student-overview-card .registration-input label {
  top: -6px;
  left: 6px;
  background: #fff;
  width: fit-content;
  position: absolute;
  z-index: 1;
  font-size: 10px;
  font-weight: 500;
  margin: 0;
  padding: 0px 4px;
  color: $accent-text;
}
.trip-student-overview-card .registration-input select {
  @extend .custom-input;
}
.trip-student-overview-card .registration-input .custom-input {
  width: 300px;
}
.trip-student-overview-card .student-profile-img {
  height: 32px;
  width: 32px;
  margin-right: unset;
  margin-left: 12px;
}

/* Student Overview Workflow Stepper Styles */
.trip-student-workflow-stepper {
  background-color: #f4f4f4;
  border-radius: 3px;
  padding: 24px 32px;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.trip-student-workflow-stepper .workflow-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  width: 158px;
  .workflow-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 12px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.38);
    color: #fff;
    margin-bottom: 16px;
  }
  .workflow-number.completed {
    background-color: #2774ae;
  }
  .workflow-description {
    font-size: 12px;
    font-weight: 600;
  }
  .stepper-line {
    position: absolute;
    top: 12px; // 24px height of workflow-index / 2
    width: 106px;
    right: 107px; // (158px width of workflow-step / 2) + (24px width of workflow-index / 2) + 16px offset
    border-bottom: 1px solid #b4b5b9;
  }
}

/* Student Overview Info Grid Styles */
.student-overview-info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.student-overview-info-grid.more-info {
  grid-template-columns: 1fr 1fr 0.6fr;
}
.student-overview-info-grid .two-column-grid {
  display: grid;
  grid-template-columns: 148px 1fr;
  grid-row-gap: 0px;
  grid-column-gap: 16px;
}
.student-overview-info-grid > div:not(:first-child) {
  border-left: 1px solid #edecec;
  padding-left: 16px;
  margin-left: 16px;
}
.student-overview-info-grid .divider-section {
  border-bottom: 1px solid #edecec;
  padding-bottom: 16px;
  margin-bottom: 16px;
  textarea {
    height: 68px;
    resize: none;
  }
}
.student-overview-info-grid .custom-checkbox-container label {
  align-items: center;
  font-weight: inherit;
}
.student-overview-info-grid .custom-checkbox[type="checkbox"] + label:before,
.student-overview-info-grid
  .custom-checkbox[type="checkbox"]:checked
  + label:before {
  height: 14px;
  width: 14px;
}
.student-overview-info-grid
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 5px;
  top: 4px;
}
.student-overview-info-grid .custom-checkbox[type="checkbox"]:disabled + label {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Student Detail Tabs Styles */
.trip-student-detail-tabs .MuiTab-root {
  font-size: 18px;
  text-transform: none;
  font-family: "Avenir";
}
.trip-student-detail-tabs .Mui-selected {
  color: #53b7e8;
}
.trip-student-detail-tabs .MuiTabs-indicator {
  background-color: #53b7e8;
}

.trip-student-detail-tab {
  padding: 24px;
  background-color: #fff;
  border-top: 1px solid #edecec;
}

.trip-student-detail-tab > .student-detail-section {
  padding: 24px 0;
  white-space: pre-wrap;
}
.trip-student-detail-tab > .student-detail-section:not(:last-of-type) {
  border-bottom: 1px solid #edecec;
}
.trip-student-detail-tab > .student-detail-section:last-child {
  padding-bottom: 0;
}

.trip-student-detail-tab .double-grid-container {
  display: flex;
  justify-content: space-between;
  .two-column-grid {
    height: fit-content;
  }
}
.trip-student-detail-tab .double-grid-container > div {
  width: 100%;
}

.trip-student-detail-tab .two-column-grid {
  grid-template-columns: 82px 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0;
}

.trip-student-detail-tab.personal-tab .two-column-grid,
.trip-student-detail-tab.payment-tab .two-column-grid {
  grid-template-columns: 164px 1fr;
}
.trip-student-payment-text {
  @extend .flex;
  align-items: center;
  flex-flow: wrap;
  column-gap: 8px;
}

/* Student Detail Modal Styles */
.trip-modal {
  font-size: 14px;
}
.trip-student-cancellation-modal {
  @extend .trip-modal;
  width: 450px;
  .refund-options {
    border-top: 1px solid #edecec;
    padding-top: 16px;
    margin-top: 16px;
  }
}
.trip-refund-modal {
  @extend .trip-modal;
  .trip-checkbox {
    width: 180px;
  }
  .custom-input:read-only {
    border: 1px solid #edecec;
  }
  .tooltip-container {
    height: unset;
  }
}

.trip-info-icon {
  color: #2774ae;
}
.trip-warning-icon {
  color: #ffb503;
}

/* Student Registration Tour Styles */
.student-registration-tour:not(:last-child) {
  margin-bottom: 32px;
}

.student-registration-tour .custom-input {
  border: none;
}

.btn-refund-registration {
  background: #0275d8;
  color: #fff;
  border: 1px solid #0275d8;
}

.btn-refund-registration:hover {
  background: #fff;
  color: #0275d8;
}

/* Partial refund modal styles */
.partial-refund-inputs {
  border-bottom: 1px solid #edecec;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
/* Trip Program Settings Styles */
.program-settings-page-subheader-right {
  display: flex;
}
.itinerary-settings {
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 20px;
}
.trip-image {
  position: relative;
  display: inline-block;
}
.trip-image img {
  border-radius: 3px;
  height: 100px;
  width: 150px;
  object-fit: cover;
  margin-right: 0px;
}
.close-img {
  box-shadow: 0 0 16px -4px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #666666;
  color: #fff;
  padding-inline: 6px;
  border-radius: 40px;
  overflow: visible;
}
.relative-overlay-loader {
  position: relative;
  background-color: rgba(255, 255, 255, 0.007);
}
.event-settings-inputs {
  max-width: 100%;
}
