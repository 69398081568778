/***** LIFE INSURANCE PAGE STYLES *****/
.life-insurance-page {
  padding: 80px 24px;
  font-size: 14px;
}

/***** LIFE INSURANCE HEADER CARDS *****/
.life-insurance-header-cards .card {
  padding: 24px 16px;
  width: 168px;
  font-size: 14px;
}

/***** LIFE INSURANCE TABS STYLES ******/
.life-insurance-details-tabs {
  @extend .raffle-details-tabs;
}

/***** LIFE INSURANCE TABLE STYLES *****/
.life-insurance-enrollment-table .table {
  padding: 8px 0;
}
/*** Search / Filter Header Styles ***/
.life-insurance-enrollment-table-filter-inputs .search-input {
  height: 36px;
}
/*** Table Header Styles ***/
.life-insurance-enrolled-table-header {
  border-top: 1px solid #edecec;
  border-bottom: 1px solid #edecec;
  display: grid;
  align-items: center;
  font-weight: 700;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  padding: 4px 12px;
  height: auto;
}
.life-insurance-enrolled-table-header p {
  margin-bottom: 0;
  font-size: 16px;
  padding: 8px 0;
}
.life-insurance-not-enrolled-table-header {
  @extend .life-insurance-enrolled-table-header;
  grid-template-columns: 1fr 1fr 1fr 1fr 3fr;
}
/*** Table Row Styles ***/
.life-insurance-enrolled-table-row {
  background: #f5f5f5;
  padding: 12px 16px;
  font-size: 14px;
  color: $accent-text-dark;
  transition: opacity 300ms linear;
  display: grid;
  border-radius: 3px;
  grid-gap: 24px;
  color: inherit;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  padding: 12px;
  font-weight: 400;
  margin-top: 8px;
  align-items: center;
}
.life-insurance-enrolled-table-row:hover {
  opacity: 0.5;
  text-decoration: none;
}
.life-insurance-enrolled-table-row p {
  margin-bottom: 0;
}
.life-insurance-not-enrolled-table-row {
  @extend .life-insurance-enrolled-table-row;
  grid-template-columns: 1fr 1fr 1fr 1fr 3fr;
}

/***** LIFE INSURANCE DETAILS CARD STYLES *****/
/*** Life Insurance Tag Styles ***/
.life-insurance-tag {
  color: #fff;
  font-size: 12px;
  border-radius: 3px;
  height: 24px;
  padding: 0 8px;
  display: inline-flex;
  align-items: center;
}
.life-insurance-tag.active-tag {
  background-color: #63c674;
}
.life-insurance-tag.pending-tag {
  background-color: #f36464;
}
.life-insurance-tag.terminated-tag {
  background-color: #f3893d;
}
/*** Beneficiary Designation Styles ***/
.beneficiary-designation-forms .dropzone {
  justify-content: flex-start;
  padding: 0;
}
.image-preview-modal {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-preview-modal:not(.image-preview-modal-error):not(
    .image-preview-modal-download
  )
  > div {
  width: 96%;
  max-width: 1200px;
}
.image-preview-modal:not(.image-preview-modal-error) i {
  display: block;
  text-align: right;
}
.image-preview-modal-error i {
  font-size: 20px;
}
.image-preview-modal object {
  height: 80vh;
  width: 100%;
  max-width: 1200px;
}
