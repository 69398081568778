table,
.table {
  padding: 10px;
  margin-bottom: 0;
  background: white;

  @include small {
    padding: 0;
  }

  > tbody {
    tr {
      background: $lighterWhite;

      &.selected-value {
        td {
          position: relative;
          background: white;

          &:before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            pointer-events: none;
            content: "";
            border-top: 2px solid $blue;
            border-bottom: 2px solid $blue;
          }

          &:first-child:before {
            border-left: 2px solid $blue;
          }

          &:last-child:before {
            border-right: 2px solid $blue;
          }
        }
      }

      > td {
        padding: 15px;
        font-size: 14px;
        color: $grey;
        border-top: none;
        border-bottom: 10px solid #fff;

        @include small {
          padding: 5px;
          font-size: 14px;
        }

        .icon {
          position: relative;
          top: 2px;
          font-size: 1.2em;
        }
      }
    }
  }

  > thead {
    border-bottom: 5px solid white;

    > tr {
      > th {
        font-family: "Avenir";

        .icon {
          font-size: 1.2em;
        }
      }
    }
  }
}

.no-results {
  font-size: 1.3em;
}

.table-pagination {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;

  .rc-pagination {
    margin-bottom: 0;

    li {
      font-family: "Avenir";
      border: 1px solid #ccc;
      border-radius: 3px;

      &.rc-pagination-item-active {
        border-color: $blue;
      }
    }
  }
}

.dropdown {
  & > .dropdown-toggle.btn-default.table-dropdown {
    &:hover,
    &:active,
    &:focus {
      color: $blue;
      background-color: transparent;
      box-shadow: none;
    }
  }

  .table-dropdown {
    position: relative;
    left: 3px;
    background-color: transparent;
    box-shadow: none;
  }
}

.open > .dropdown-toggle.btn-default.table-dropdown {
  color: $blue;
  background-color: transparent;
  box-shadow: none;

  &:hover,
  &:active,
  &:focus {
    color: $blue;
    background-color: transparent;
    box-shadow: none;
  }
}

.table-dropdown {
  padding: 0 5px;
  margin-bottom: 2px;
  margin-left: 2px;
  background: transparent;
  border: none;

  & + .dropdown-menu {
    top: 30px;
    left: -30px;
  }
}

.table > thead > tr > th.table-header-selected {
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgba(83, 183, 232, 0.1);
  border-bottom: none;
}
