/***** RAFFLES PAGE STYLES *****/
.raffles-page.container,
.raffles-page .container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
}
.raffles-page.page.container {
  padding-top: 80px;
}
.raffles-page {
  font-size: 14px;
  font-family: "Avenir", sans-serif;
}
.pointer {
  cursor: pointer;
}
@media only screen and (max-width: 1040px) {
  .raffles-page.container,
  .raffles-page .container {
    padding: 0 24px;
    max-width: 848px;
  }
  .raffles-page.page.container {
    padding: 40px 24px;
  }
}
@media only screen and (max-width: 560px) {
  .raffles-page.container,
  .raffles-page .container {
    padding: 0 16px;
  }
  .raffles-page.page.container {
    padding: 24px 16px;
  }
}

/***** RAFFLES DRAFT CARD STYLES *****/
.raffles-card {
  margin-bottom: 32px;
  padding: 24px;
}
.uppercase-text {
  text-transform: uppercase;
}
.draft-tag {
  background: #f3893d;
}

/***** RAFFLES PUBLISH MODAL STYLES *****/
.raffles-page .modal-card {
  width: 440px;
  max-width: 100%;
  padding: 24px;
  max-height: 80vh;
  overflow: auto;
}
.modal-btns {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
}
.refund-modal {
  position: relative;
}
.refund-modal .modal-btns .error-text {
  position: absolute;
  bottom: 0px;
  right: 24px;
}
.modal-btns button,
.modal-btns p {
  font-size: 14px;
  padding: 0;
  background-color: transparent;
  border: none;
}
.modal-btns p {
  margin-bottom: 0;
  font-weight: 500;
}

/***** RAFFLES ACTIVE CARD STYLES *****/
.raffles-card .divider {
  margin: 0 40px;
  height: 72px;
  width: 1px;
  background: #edecec;
}
.active-tag {
  background: #63c674;
}
.closed-tag {
  background: #f36464;
}
.scheduled-tag {
  background: #6369d1;
}
.percentage-bar {
  width: calc(100% + 24px);
  min-width: 400px;
}
.percentage-bar .bar {
  width: 100%;
  height: 6px;
  background: rgba(0, 164, 234, 0.12);
}
.percentage-bar .filled-in {
  position: absolute;
  background: #00a4ea;
  height: 6px;
  top: 0;
}
.percentage-bar .filled-in.bonus {
  opacity: 0.5;
}
.raffles-active-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.raffles-active-right p {
  margin-bottom: 0;
}
.raffles-link {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #edecec;
  padding: 0 8px;
}

/***** COPY-TO-CLIPBORD STYLES *****/
.copy-code-container textarea {
  height: auto;
  border: none;
  resize: none;
  width: 100%;
  overflow: hidden;
}
.copy-code-container textarea::selection {
  background: transparent;
}
.copy-code-container textarea:focus {
  outline: none;
  border: none;
}
.copy-code-container textarea::selection {
  background: transparent;
}

/***** RAFFLES HISTORY STYLES *****/
.raffles-history-card {
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 24px;
  background: #fff;
  border-radius: 3px;
}
/*** Raffles History Table Styles ***/
.raffles-history-table > div,
.raffles-history-table > a {
  display: grid;
  grid-template-columns: 0.4fr 1fr 1.2fr 0.75fr 3fr 2.25fr 1fr;
  grid-gap: 16px;
}
.raffles-history-table-header {
  border-bottom: 1px solid #e0e0e0;
  padding: 0 16px;
  align-items: flex-end;
  padding-bottom: 8px;
  margin-bottom: 16px;
}
.raffles-history-table-header p {
  font-weight: bold;
  color: #555;
  font-size: 14px;
  margin-bottom: 0;
}
.raffles-history-table-row {
  background: #f5f5f5;
  padding: 12px 16px;
  font-size: 12px;
  font-weight: bold;
  color: inherit;
  transition: opacity 300ms linear;
}
.raffles-history-table-row:hover {
  color: inherit;
  text-decoration: none;
  opacity: 0.5;
  cursor: pointer;
}
.raffles-history-table-row:not(:last-child) {
  margin-bottom: 8px;
}
.raffles-history-table-row p {
  margin-bottom: 0;
}
.raffles-history-table p.no-history {
  display: flex;
  justify-content: center;
  border-radius: 3px;
  margin-top: 12px;
}
.raffles-history-table .accent-text {
  font-weight: normal;
}

/***** NEW RAFFLE STYLES *****/
/*** Subheader Styles ***/
.raffles-page-subheader {
  background: #fff;
  height: 64px;
  box-shadow: 0 2px 18px 2px rgba(0, 0, 0, 0.08);
  top: 72px;
  width: calc(100% - 230px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  width: 100%;
}
.raffles-page .raffles-page-subheader .btn {
  font-size: 12px;
}
/* Wizard Steps Styles */
.raffles-page-wizard-steps {
  flex: 1;
}
.raffles-page-wizard-steps p {
  margin-bottom: 0;
}
.wizard-step {
  margin-right: 32px;
  display: flex;
  align-items: center;
  color: #333;
  cursor: pointer;
}
.wizard-step .wizard-step-number {
  border: 1px solid #333;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  margin-right: 12px;
  position: relative;
}
.wizard-step.active .wizard-step-number {
  background: $accent-color;
  color: #fff;
  border-color: $accent-color;
}
.wizard-step.active .wizard-step-number:after {
  content: "";
  height: 32px;
  width: 32px;
  border: 1px solid $accent-color;
  border-radius: 100%;
  position: absolute;
  top: -5px;
  left: -5px;
}
/* Wizard Steps Styles */
.wizard-step {
  transition: opacity 300ms linear;
}
.wizard-step.active .wizard-step-title-tab {
  color: $accent-color;
  font-weight: 500;
  cursor: default;
}
.wizard-step:not(.active):hover {
  opacity: 0.5;
}
.wizard-step.active {
  cursor: default;
}
/*** New Raffle Step Styles ***/
.new-raffle-step {
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: #fff;
  border-radius: 3px;
  padding: 40px;
  margin-bottom: 24px;
}
.new-raffle-step-section:not(:last-child) {
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid #edecec;
}
.new-raffle-step-section > p.xl-text {
  font-size: 22px;
  margin-bottom: 0;
}
/* First Step Styles */
.raffle-label {
  width: 112px;
  min-width: 112px;
  color: $accent-text;
  font-size: 12px;
}
.raffle-label-wide {
  width: 185px;
  color: $accent-text;
  font-size: 12px;
}
.new-raffle-step-section textarea.custom-input {
  width: 608px;
  max-width: 100%;
  resize: none;
  height: 80px;
}
.raffle-date-wrapper .custom-input {
  min-width: 180px;
}
.raffle-date-wrapper {
  display: flex;
  align-items: center;
  min-height: 44px;
}
.ticket-fee-input-label {
  position: absolute;
  right: 8px;
  top: 8px;
}
/* Second Step Styles */
.new-raffle-second-step .new-raffle-step {
  padding: 16px;
}
.new-raffle-second-step .xl-text {
  font-size: 22px;
}
.new-raffle-second-step .flex .new-raffle-step:first-child {
  flex: 1;
}
.new-raffle-second-step .flex .new-raffle-step:last-child {
  flex: 2;
}
.prize {
  background: #f5f5f5;
  padding: 8px;
  margin-bottom: 8px;
  transition: all 300ms linear;
  border-radius: 3px;
  border: 1px solid transparent;
}
.prize.hover:hover {
  opacity: 0.5;
  cursor: pointer;
}
.prize.active {
  border: 1px solid $accent-color;
}
.prize i {
  cursor: pointer;
  transition: opacity 300ms linear;
}
.prize i:hover {
  opacity: 0.5;
}
.prize-details-form .custom-input:not(.add-local-winners-input) {
  width: 100%;
}
.prize-details-form textarea.custom-input {
  height: 80px;
  resize: none;
}
.prize-details-form > .flex > .flex {
  width: 50%;
}
.prize-details-form .radio-container input[type="radio"] + label {
  width: 50%;
}
.prize-details-form .radio-container label {
  margin-bottom: 0;
}
.prize-details-form-btns {
  display: flex;
  justify-content: flex-end;
}
.prize-details-form .custom-checkbox[type="checkbox"]:checked + label:after {
  height: 8px;
  width: 4px;
  left: 5px;
  top: 5px;
}
.prize-details-form .custom-checkbox[type="checkbox"]:disabled + label {
  opacity: 0.5;
  cursor: not-allowed;
}
.prize-details-form .error-message {
  top: 32px;
  left: 112px;
}
.local-winners-row {
  background: #f5f5f5;
  padding: 8px 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 8px;
  width: 100%;
}
.add-local-winners-input {
  width: 100%;
}
/* Third Step Styles */
.legal-text {
  background: #fafafa;
  border-radius: 3px;
  border: 1px solid #edecec;
  overflow: hidden;
}
.legal-text .custom-input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  padding: 16px;
  resize: none;
}
.legal-text.short {
  height: 104px;
}
.legal-text.long {
  height: 304px;
}
/* Fourth Step Styles */
.instructions-sidebar {
  position: fixed;
  top: 180px;
  right: 0;
}
.instructions-sidebar.closed {
  right: -5px;
}
.instructions-sidebar .toggle {
  background: #f3893d;
  width: 180px;
  height: 48px;
  border-radius: 3px 3px 0 0;
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px;
  left: -114px;
  top: 112px;
  position: absolute;
  font-size: 18px;
  margin-bottom: 0;
}
.instructions-sidebar-content {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
  padding: 24px;
  width: 360px;
  background: #fff;
  max-height: 60vh;
  overflow: auto;
  transition: width 300ms linear;
}
.instructions-sidebar.closed .instructions-sidebar-content {
  padding: 0;
  width: 0;
  box-shadow: none;
}
/*** New Raffle Wizard Button Styles ***/
.raffles-page-wizard-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.raffles-page .raffles-page-wizard-btns .btn {
  font-size: 12px;
  width: 96px;
}

/***** RAFFLE DETAILS STYLES *****/
/*** Raffle Details Tab Styles ***/
.raffle-details-tabs .large-text {
  padding-right: 8px;
  cursor: default;
  transition: opacity 300ms linear;
}
.raffle-details-tabs .large-text:not(.active):hover {
  opacity: 0.5;
  cursor: pointer;
}
.raffle-details-tabs .large-text.active {
  border-bottom: 4px solid #30a4dc;
}
/*** Raffle Details Card Header Styles ***/
.raffle-details-card .xxl-text {
  font-size: 22px;
}
.search-input {
  border: 1px solid #edecec;
  border-radius: 3px;
  display: flex;
  height: 32px;
  padding-right: 8px;
  width: 360px;
  max-width: 100%;
}
.search-input input {
  background: transparent;
  border: none;
  padding: 0 16px;
  width: 100%;
  color: black;
}
.search-input select:focus,
.search-input input:focus {
  border: none;
  outline: none;
}
.search-input.with-options input {
  border-left: 1px solid #edecec;
}
.search-input select {
  background: #fafafa;
  border: none;
  border-radius: 0;
  width: 80px;
}
.raffles-page label {
  font-weight: normal;
  margin-bottom: 0;
}
.no-border-select {
  border: none;
  background: transparent;
  outline: none;
}
.raffle-details-card .btn-dropdown {
  width: 176px;
}
.raffle-details-card .radio-container input[type="radio"] + label {
  width: 96px;
}
.all-shluchim-header .relative .error-text {
  position: absolute;
  right: 0;
  white-space: nowrap;
}
/*** Raffle Details Table Styles ***/
.raffle-details-table p {
  margin-bottom: 0;
}
.raffle-details-table-header {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  font-size: 15px;
  align-items: center;
  font-weight: bold;
  padding: 16px;
  margin-bottom: 16px;
}
.raffle-details-table-row {
  background: #f5f5f5;
  font-size: 12px;
  padding: 8px 16px;
  border-radius: 3px;
  margin-bottom: 8px;
}
.raffle-details-table + .pagination {
  display: flex;
  justify-content: flex-end;
}
/* Raffle Details Order Table Styles */
.raffle-details-order-table-row {
  display: grid;
  grid-template-columns: 2fr 0.75fr 1.2fr 0.5fr 1.8fr 1fr 0.75fr 0.5fr;
  grid-gap: 16px;
}
.raffle-details-order-by-enrollment-table-row {
  display: grid;
  grid-template-columns: 1.75fr 0.75fr 1fr 0.5fr 1.25fr 1fr 0.5fr;
  grid-gap: 16px;
}
.raffle-details-order-table-row.hover,
.raffle-details-order-by-enrollment-table-row.hover {
  transition: opacity 300ms linear;
}
.raffle-details-order-table-row.hover:hover,
.raffle-details-order-by-enrollment-table-row.hover:hover {
  opacity: 0.5;
  cursor: pointer;
}
.refunded {
  color: $error-color;
  text-decoration: line-through;
}
.error-text,
.accent-text-dark.error-text {
  color: $error-color;
  font-size: 13px;
}
.full-page-error-text {
  height: 70vh;
  width: 85vw;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/* Raffle Details Shluchim Table Styles */
.raffle-details-shluchim-table-row {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr 1fr 1fr 1fr;
  padding: 16px;
  grid-gap: 16px;
  color: inherit;
}
.raffle-details-shluchim-table-row:hover {
  color: inherit;
  text-decoration: none;
  cursor: default;
}
.raffle-details-shluchim-table-row.clickable {
  transition: opacity 300ms linear;
}
.raffle-details-shluchim-table-row.clickable:hover {
  opacity: 0.5;
  cursor: pointer;
}
.raffle-tickets-sold-filter .custom-checkbox[type="checkbox"] + label::before {
  margin-top: 4px;
}
.raffle-tickets-sold-filter
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  height: 8px;
  width: 4px;
  left: 5px;
  top: 5px;
}
/* Raffle Details Metrics Styles */
.raffle-metrics-card .percentage-bar {
  width: 100%;
}
.raffle-metrics-checkbox .custom-checkbox[type="checkbox"] + label:before,
.raffle-metrics-checkbox
  .custom-checkbox[type="checkbox"]:checked
  + label:before {
  height: 14px;
  width: 14px;
}
.raffle-metrics-checkbox
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 5px;
  top: 4px;
}
/***** Bulk Order Import Modal Styles *****/
.import-modal {
  max-width: 544px;
  padding: 24px 24px 16px 24px;
  font-size: 14px;
  position: relative;
}
.uploading {
  flex: 1;
  margin-left: 24px;
}
.uploading .percentage-bar {
  width: 100%;
  min-width: auto;
}
.raffle-modal-btns {
  border-top: 1px solid #edecec;
  padding: 16px 24px 0;
  margin: 0 -24px;
}
.import-modal .btn {
  border-radius: 3px;
  letter-spacing: 0.2px;
  font-size: 12px;
  text-transform: uppercase;
  transition: background 300ms linear, color 300ms linear, opacity 300ms linear;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
}
.import-modal .btn-accent {
  background: $accent-color;
  color: #fff;
  border: 1px solid $accent-color;
}
.import-modal .btn-accent:hover:not(:disabled) {
  background: #fff;
  color: $accent-color;
}
.import-modal .btn-cancel {
  background: #ebebeb;
  color: $accent-text-dark;
  border: 1px solid #ebebeb;
}
.import-modal .btn-cancel:hover:not(:disabled) {
  background: $accent-text-dark;
  color: #ebebeb;
}

/*** New Order Modal Styles ***/
.new-order-modal {
  width: 800px;
  max-width: 100%;
  max-height: 96vh;
  height: 100%;
  padding: 24px 24px 16px 24px;
  overflow: hidden;
}
.new-order-modal-overflow-container {
  height: calc(100% - 57px);
  overflow: auto;
  margin: -24px -24px 0 -24px;
  padding: 24px;
}
.new-order-modal .custom-input:read-only {
  border: 1px solid #edecec;
}
.new-order-modal .custom-input.error:read-only {
  border-color: $error-color;
}
.new-order-modal-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 12px 0;
}
.new-order-modal-grid label {
  min-width: 120px;
  width: 120px;
}
.new-order-modal-grid .custom-input {
  width: 100%;
}
.new-order-modal-grid .phone-input {
  width: calc(100% - 120px);
  padding-left: 8px;
  padding-top: 4px;
}
.new-order-modal-grid .phone-input input {
  width: 100%;
  font-size: 12px;
}
.new-order-address {
  max-width: 512px;
}
.new-order-address label,
.new-order-payment-method label {
  min-width: 176px;
}
.new-order-address .custom-input {
  position: relative;
}
.new-order-modal .custom-input select {
  height: 100%;
  border: none;
  background: transparent;
  width: 100%;
}
.new-order-modal .custom-input select:focus {
  border: none;
  outline: none;
}
.new-order-modal .clear-select {
  top: 5px;
  right: 20px;
}
.new-order-payment-method .custom-select {
  border: 1px solid #edecec;
  background: transparent;
  height: 32px;
  width: 120px;
  border-radius: 3px;
  font-size: 14px;
}
.new-order-modal .cc-info-form .custom-input {
  width: 256px;
  max-width: 100%;
  display: flex;
  align-items: center;
}
.new-order-modal .StripeElement {
  display: flex;
  align-items: center;
}
.new-order-modal .__PrivateStripeElement {
  width: 100%;
}
.new-order-modal .btn {
  border-radius: 3px;
  letter-spacing: 0.2px;
  font-size: 12px;
  text-transform: uppercase;
  transition: background 300ms linear, color 300ms linear, opacity 300ms linear;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
}
.new-order-modal .btn-accent {
  background: $accent-color;
  color: #fff;
  border: 1px solid $accent-color;
}
.new-order-modal .btn-accent:hover:not(:disabled) {
  background: #fff;
  color: $accent-color;
}
.new-order-modal .btn-cancel {
  background: #ebebeb;
  color: $accent-text-dark;
  border: 1px solid #ebebeb;
}
.new-order-modal .btn-cancel:hover:not(:disabled) {
  background: $accent-text-dark;
  color: #ebebeb;
}

/*** Order Detail Modal Styles ***/
/***** ORDER DETAILS MODAL STYLES *****/
.order-details-modal {
  border-radius: 3px;
  width: 600px;
  max-width: 96%;
}
.order-details-modal .small-text.accent-text {
  min-width: 120px;
  margin-right: 24px;
}
.order-details-modal .small-text.error-text {
  width: 120px;
  margin-right: 24px;
}
.order-details-modal svg#loading {
  height: 24px;
  width: 32px;
}
.order-details-modal.order-edit-modal {
  width: 800px;
  overflow-y: auto;
  max-height: 90vh;
}
.order-details-modal.order-edit-modal .custom-input select {
  height: 100%;
  border: none;
  background: transparent;
  width: 100%;
}
.order-details-modal.order-edit-modal .custom-input select:focus {
  border: none;
  outline: none;
}
.order-details-modal.order-edit-modal .custom-input .clear-select {
  top: 5px;
  right: 24px;
}
.order-details-modal.order-edit-modal .custom-select {
  width: 400px;
}
.total-text {
  font-size: 13px;
  margin-right: 8px;
}
.amounts-wrapper {
  min-width: 144px;
}
.amounts-values-wrapper {
  min-width: 80px;
}
.amounts-values {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}
.input-size {
  width: 215px;
}
.required:after {
  content: " *";
  color: red;
}
.active-toggle {
  font-size: 12px;
  font-weight: 400;
}
.active-toggle.radio-container input[type="radio"]:first-child:checked + label {
  background: $accent-color;
}
.active-toggle.radio-container
  input[type="radio"]:not(:first-child):checked
  + label {
  background: $accent-color;
}
.active-toggle.radio-container input[type="radio"] + label {
  width: 106.5px;
  margin-bottom: 0;
}

/***** copy order link styles *****/
.raffle-order-link {
  display: flex;
}
.raffle-order-link textarea {
  width: 0px;
  height: 0px;
}
.raffle-order-link .flex-justify-end {
  justify-content: start;
}
.raffle-order-link .text-right {
  text-align: left;
}

/***** RAFFLE SETTINGS STYLES *****/
.raffles-page-subheader.raffles-page-settings-subheader {
  flex-direction: column;
  align-items: flex-start;
}
.raffles-page-settings-subheader .container {
  height: 100%;
}
.raffles-page-settings-subheader .breadcrumbs-container {
  padding-top: 4px;
}
.raffles-page-settings-subheader ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  align-items: center;
  flex: 1;
  height: 100%;
}
.raffles-page-settings-subheader li {
  margin-right: 48px;
  border-bottom: 2px solid transparent;
  transition: all 300ms linear;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.raffles-page-settings-subheader li.active,
.raffles-page-settings-subheader li:hover {
  border-bottom: 2px solid $accent-color;
  color: $accent-color;
}
.raffles-page-settings-subheader li i {
  vertical-align: middle;
}
.raffle-settings-card {
  @extend .new-raffle-step;
}
.raffle-settings-section {
  @extend .new-raffle-step-section;
}
.raffle-settings-section .radio-button-container img {
  height: 80px;
  width: 80px;
  border-radius: 3px;
  object-fit: cover;
  margin: 8px 0;
}
.raffle-settings-section .radio-container input[type="radio"] + label {
  width: 96px;
}
.raffles-section-prizes {
  @extend .new-raffle-second-step;
}
.raffle-settings-section .raffle-ticket-option-select {
  width: 200px;
}
.raffle-donor-panel-settings .radio-container input[type="radio"] + label {
  width: 148px;
}
.raffle-goal-input {
  position: relative;
  width: fit-content;
}
.raffle-goal-input:after {
  content: "Tickets";
  position: absolute;
  right: 12px;
  font-size: 12px;
  top: 8px;
  color: $accent-color;
}
.raffle-goal-input.bonus-goal-input:after {
  content: "Bonus goal";
}
.raffle-goal-input.donor-goal-input:after {
  content: "Donors";
}
/*** Winners ***/
.winner-container {
  margin: 24px;
}
.winner-search {
  flex: 1 1 0%;
  .MuiInputBase-root {
    font-size: 16px;
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
    .MuiAutocomplete-input {
    padding: 6px 4px;
  }
  .MuiAutocomplete-endAdornment {
    top: 30%;
  }
  .MuiInputLabel-outlined {
    font-size: 14px;
  }
}
.winner-autocomplete {
  width: 300px;
}
/*** Settings - teams styles ***/
.teams-separator {
  border-top: 1px solid #edecec;
  padding-top: 18px;
  margin-top: 16px;
}
.teams-grid-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  margin-bottom: 8px;
  border-radius: 3px;
  min-height: 40px;
  align-items: center;
  width: calc(100% - 72px);
}
.team-section {
  height: 86px;
  width: 250px;
  padding: 18px 16px;
  border: 1px solid #2773ae8a;
  border-radius: 3px;
}
.team-modal.card {
  width: 600px;
  max-width: 96%;
  font-size: 14px;
  padding: 24px;
}
.team-modal label {
  font-weight: normal;
  font-size: 13px;
  min-width: 120px;
}
.team-modal .custom-input {
  width: 100%;
}
.team-modal .goal-input {
  width: 135px;
}
.team-modal .goal-error {
  bottom: 100px;
}
.additional-text {
  margin-left: 120px;
}
.team-modal .btn {
  padding: 0 12px;
}
.student-dropdown {
  width: 100%;
}
.tickets-text {
  margin-left: 12px;
}
.team-modal-separator {
  border: 0.5px solid #edecec;
  margin: 28px -24px 22px;
}
.smaller-margin {
  margin: 16px -24px 22px;
}
.tooltip-height {
  height: 16px;
}
.cancel-btn {
  color: black;
}
.save-btn {
  color: white;
  background-color: $accent-color;
  min-width: 80px;
}
@media only screen and (max-width: 1040px) {
  .teams-grid-row {
    grid-template-columns: 1.5fr 2fr 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 560px) {
  .teams-grid-row {
    grid-template-columns: 1fr;
    height: auto;
    grid-gap: 8px;
  }
  .team-modal .custom-input {
    width: 100% !important;
  }
  .team-modal .goal-input {
    width: 135px !important;
  }
  .additional-text {
    margin-left: 0px;
  }
}
/***** RAFFLE SHLIACH SETTINGS *****/
.raffles-page-shliach-subheader .go-back {
  position: absolute;
}
.raffles-page-shliach-subheader .container {
  height: 100%;
}
.raffles-page-shliach-subheader ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  height: 100%;
}
.raffles-page-shliach-subheader li {
  margin: 0 24px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  transition: all 300ms linear;
  cursor: pointer;
}
.raffles-page-shliach-subheader li.active,
.raffles-page-shliach-subheader li:hover {
  border-bottom: 2px solid $accent-color;
  color: $accent-color;
}
.raffle-shliach-card {
  @extend .raffle-settings-card;
}
/*** Raffle Shliach Orders Styles ***/
.raffle-shliach-card .search-input {
  height: 40px;
  width: 320px;
}
.raffle-shliach-card .btn-dropdown {
  width: 176px;
}
.raffle-order-checkbox-container label {
  font-size: 12px;
}
.raffle-order-checkbox-container
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  height: 8px;
  width: 4px;
  left: 5px;
  top: 3px;
}
.raffle-order-summary {
  max-width: 200px;
  font-size: 14px;
}
@media only screen and (max-width: 560px) {
  .raffle-order-summary {
    max-width: 100%;
    font-size: 14px;
  }
}
/*** Raffle Shliach Orders Styles ***/
.raffle-shliach-card .card.raffle-details-card {
  padding: 0;
  box-shadow: none;
  border: none;
}
/*** Raffle Shliach Settings Styles ***/
.raffle-shliach-card .raffle-settings-section:not(:last-child) {
  margin-bottom: 16px;
  padding-bottom: 32px;
}
.iframe-container {
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 12px;
  align-items: flex-start;
}
textarea.custom-input.custom-message {
  width: calc(100% - 28px);
  padding: 12px;
  margin-left: 28px;
  font-size: 14px;
  font-family: "Avenir", sans-serif;
}
textarea.custom-input.custom-message.full-width {
  width: 100%;
  margin-left: 0px;
}
.chip-container {
  @extend .iframe-container;
  max-width: 100%;
  min-height: 88px;
  flex-wrap: wrap;
}
.chip {
  height: 32px;
  padding: 0 16px;
  border-radius: 19.5px;
  background-color: rgba(237, 236, 236, 0.6);
  margin-right: 8px;
  margin-bottom: 8px;
}
.chip-container input,
.chip-container input:focus {
  height: 32px;
  border: none;
  outline: none;
}
.raffle-shliach-card .settings-btns {
  display: flex;
  justify-content: flex-end;
}
.raffle-shliach-card .settings-btns .btn {
  width: 120px;
}
.enrollment-settings-loader svg#loading {
  height: 24px;
  width: 24px;
}
.raffle-web-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #edecec;
  padding: 0 8px;
}
.raffle-web-link p:first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
}
.referrers-grid {
  max-width: 840px;
}
.referrers-grid-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
  background: #f5f5f5;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 3px;
  min-height: 40px;
  align-items: center;
  width: calc(100% - 72px);
}
.referrers-grid-row p {
  margin-bottom: 0;
  color: #555555;
}
.referrer-details {
  display: contents;
}
.referrer-modal.card {
  width: 400px;
  max-width: 96%;
  font-size: 14px;
  padding: 24px;
}
.referrer-modal label {
  font-weight: normal;
  width: 96px;
  min-width: 96px;
}
.referrer-modal .custom-input {
  width: 100%;
}
/*** Raffle Shliach Metric Styles ***/
.raffle-shliach-card .percentage-bar {
  width: 100%;
}
.money-status-cards-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}
.money-status-card {
  margin-right: 24px;
  background: #f4f4f4;
  border-radius: 3px;
  padding: 32px 16px 16px 16px;
  min-width: calc(25% - 24px);
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: opacity 300ms linear;
  font-size: 16px;
}
.money-status-card:hover {
  opacity: 0.5;
  cursor: pointer;
}
.money-status-card:last-child {
  margin-right: 0;
}
.money-status-card p.mb-8 {
  font-size: 30px;
  font-weight: bold;
}
.money-status-card-substatus {
  text-align: center;
  font-size: 12px;
}
.ticket-status-card {
  width: 204px;
  height: 200px;
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.ticket-status-card:last-child {
  margin-bottom: 0;
}
.icon-wrapper {
  height: 42px;
  width: 42px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
}
.person-icon {
  background-color: rgba(99, 198, 116, 0.08);
}
.ticket-icon {
  background-color: rgba(243, 137, 61, 0.08);
}
.graph-outline {
  border: 1px solid #edecec;
  border-radius: 3px;
  width: 100%;
  padding: 24px 24px 0 24px;
}
.recharts-wrapper {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.recharts-cartesian-axis-tick {
  font-size: 11px;
}
.recharts-surface {
  overflow: visible;
}
.recharts-legend-item {
  min-width: 160px;
  text-align: left;
}

/***** BUTTON STYLES *****/
.raffles-page .btn {
  border-radius: 3px;
  letter-spacing: 0.2px;
  font-size: 12px;
  text-transform: uppercase;
  transition: background 300ms linear, color 300ms linear, opacity 300ms linear;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
}
.raffles-page a.btn {
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
}
.raffles-page .btn:focus {
  outline: none;
}
.raffles-page .btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.raffles-page .btn-large {
  height: 48px;
  line-height: 48px;
}
.raffles-page .btn-medium {
  height: 32px;
  line-height: 32px;
  padding: 0 16px;
}
.btn-small {
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
}
.raffles-page .btn-fullWidth {
  width: 100%;
}
.raffles-page .btn-accent {
  background: $accent-color;
  color: #fff;
  border: 1px solid $accent-color;
}
.raffles-page .btn-accent:hover:not(:disabled) {
  opacity: 0.5;
  cursor: pointer;
}
.raffles-page .btn-cancel {
  background: #ebebeb;
  color: $accent-text-dark;
  border: 1px solid #ebebeb;
}
.raffles-page .btn-cancel:hover:not(:disabled) {
  opacity: 0.4;
  cursor: pointer;
}
.raffles-page .btn-accent-secondary {
  background: #f3893d;
  color: #fff;
  border: 1px solid #f3893d;
}
.raffles-page .btn-accent-secondary:hover:not(:disabled) {
  background: #fff;
  color: #f3893d;
}

/***** TAG STYLES *****/
.raffles-page .tag {
  height: 24px;
  padding: 0 8px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

/***** PAGINATION STYLES *****/
.pagination {
  display: flex;
  margin-bottom: 0;
  margin-top: 24px;
  align-items: center;
  justify-content: flex-end;
}
.pagination ul {
  display: flex;
  list-style: none;
  padding-left: 0;
  align-items: center;
  margin-bottom: 0;
}
.pagination ul > *:not(.disabled):not(.active) {
  transition: opacity 300ms linear;
  cursor: pointer;
}
.pagination ul > *:not(.disabled):not(.active):hover {
  opacity: 0.5;
}
.pagination li:not(:last-child) {
  margin-right: 16px;
}
.pagination ul li.active {
  height: 24px;
  width: 24px;
  background: $accent-color;
  border-radius: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/***** DROPDOWN STYLES *****/
.dropdown-container {
  position: relative;
}
.dropdown-container:hover .dropdown {
  opacity: 1;
  visibility: visible;
}
.dropdown-container .dropdown {
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms linear;
  position: absolute;
  left: 0;
}
.btn-dropdown {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 18px 2px rgba(0, 0, 0, 0.08);
  padding: 16px;
}

/***** EMPTY STATE STYLES *****/
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/***** MODAL STYLES *****/
.ReactModal__Overlay {
  z-index: 10;
  background: rgba(0, 0, 0, 0.3) !important;
}
.center-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 100vh;
}
.scrollable-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  position: fixed;
  inset: 0px;
}
.small-modal {
  width: 440px;
  max-width: 100%;
  padding: 24px;
}
.medium-modal {
  width: 600px;
  max-width: 100%;
  padding: 24px;
}
.large-modal {
  width: 640px;
  max-width: 100%;
  padding: 24px;
}
.modal-btns-loader svg#loading {
  height: 24px;
  width: 32px;
  float: right;
  margin-top: 32px;
}

/***** CAMPAIGN MESSAGE STYLES *****/
.campaign-message {
  width: 600px;
  max-width: 100%;
}
.campaign-message .small-text.accent-text {
  position: absolute;
  bottom: 0;
  right: 4px;
}

/***** DROPZONE STYLES *****/
.raffles-page .dropzone > img {
  height: 80px;
  width: 80px;
  border-radius: 3px;
  object-fit: cover;
}
.raffles-page .dropzone.disabled {
  cursor: default;
  outline: none;
  border: none;
}
.raffles-page .dropzone.disabled .link-text.uppercase-text {
  display: none;
}
.raffles-page .dropzone.error .link-text.uppercase-text {
  color: $error-color;
}
/****CAMPAIGN IMAGE UPLOAD STYLES***/
.image-upload {
  flex-wrap: wrap;
}
.campaign-image {
  position: relative;
  display: inline-block;
}
.campaign-image img {
  border-radius: 3px;
  height: 100px;
  width: 100px;
  object-fit: cover;
  margin-right: 0px;
}
.raffle-close-img {
  font-size: 14px;
  box-shadow: 0 0 16px -4px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #666666;
  color: #fff;
  padding: 2px;
  border-radius: 40px;
  overflow: visible;
}

.img-error-message {
  position: relative;
  font-size: 13px;
  color: $error-color;
}
