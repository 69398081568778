.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loader .loader-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
}

.loader .glyphicon {
  position: absolute;
  top: 200px;
  left: 50%;
  font-size: 3em;
  transform: translate(-50%, -50%);
}

.spin-right {
  animation: spin-right 1s infinite ease-in-out;
}

@keyframes spin-right {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
