.rsvp-page {
  @include program-page;
}
.msg-svc-label-lg {
  min-width: 130px !important;
  width: 130px !important;
}
.msg-svc-icon {
  position: absolute !important;
  left: 100px;
  top: 18px;
}

/***** LOCATIONS TABLE STYLES *****/
.rsvp-locations-table {
  @include data-table;
}
/*** Table Header Styles ***/
.rsvp-locations-table-header {
  @include data-table-header;
  grid-template-columns: 1fr 1fr 1.5fr 1.5fr 0.75fr 0.125fr 0.125fr;
}
/*** Table Row Styles ***/
.rsvp-locations-table-row {
  @include data-table-row;
  grid-template-columns: 1fr 1fr 1.5fr 1.5fr 0.75fr 0.125fr 0.125fr;
}
