.sidebar-menu {
  height: 100vh;
  padding: 0 10px;
  background-color: #fff;

  .btn-logout {
    width: 100%;
    margin: 10px 0;
    border-radius: 3px;
  }

  .panel-heading {
    display: block;
    padding: 0.5em 0;
    font-size: $baseFontSize;
    font-weight: normal;
    color: $menuBlue;
    cursor: pointer;
    background: none;
    border: none;
  }

  .panel-title {
    font-family: "Avenir";
    font-size: $baseFontSize;

    a {
      padding-left: 15px;
    }

    a[aria-expanded="false"] {
      &:before {
        position: relative;
        left: -12px;
        display: inline-block;
        width: 5px;
        content: "+";
      }
    }

    a[aria-expanded="true"] {
      &:before {
        position: relative;
        left: -12px;
        display: inline-block;
        width: 5px;
        font-size: 22px;
        line-height: 16px;
        content: "-";
      }
    }
  }

  .panel-group .panel-heading + .panel-collapse > .panel-body {
    padding: 7px;
    border: none;
  }

  .panel-group {
    margin-bottom: 10px;
  }

  .panel {
    border: none;
    box-shadow: none;
  }

  .logo {
    width: 50px;
  }

  .nav a {
    padding: 7px;
    overflow: hidden;
    font-size: 15px;
    color: $menuBlue;
    text-align: left;
    text-overflow: ellipsis;
    background: none;
    border: none;

    &:hover,
    &:active {
      color: $menuBlue;
      text-decoration: underline;
      background: white;
      box-shadow: none;
    }

    &:focus {
      background: white;
      outline: none;
    }
  }
}

.sidebar-menu > .nav {
  margin-top: 1em;
  margin-left: 1em;
}

.sidebar-menu .nested-nav .nav {
  padding-left: 15px;
  border-left: 1px dashed #8ca0b3;
}

.sidebar-content {
  margin-top: 30px;
}

.sidebar-content .nav .panel {
  padding-left: 15px;
}

.sidebar-content .nav .btn-sm {
  white-space: pre-line;
}
