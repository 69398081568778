/***** PROGRAM SETTINGS PAGE STYLES *****/
.program-settings-page {
  font-size: 14px;
}

/*** Subheader Styles ***/
.program-settings-page-subheader {
  background: #fff;
  height: 92px;
  box-shadow: 0 2px 18px 2px rgba(0, 0, 0, 0.08);
  width: calc(100% - 230px);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px;
  position: sticky;
  top: 0;
  z-index: 1;
}
.program-settings-page-subheader .program-settings-page-subheader-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.program-settings-page-subheader-left {
  height: 100%;
  display: flex;
  align-items: center;
}
.program-settings-page-subheader .program-name {
  border-right: 1px solid #edecec;
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-right: 24px;
  padding-right: 24px;
}

/*** Sidebar Styles ***/
.program-settings-page-sidebar {
  width: 80px;
  min-width: 80px;
  min-height: calc(100vh - 138px);
  background: #fafafa;
  border: 1px solid #edecec;
  position: sticky;
  top: 64px;
}
.program-settings-page-sidebar ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  li {
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    border-left: 4px solid transparent;
    transition: border 300ms linear;
    cursor: pointer;
    .tooltip-container {
      height: 100%;
      cursor: inherit;
    }
    i {
      font-size: 32px;
      position: relative;
      left: -4px;
      transition: color 300ms linear;
    }
    &.active,
    &:hover {
      border-left: 4px solid $accent-color;
      i {
        color: $accent-color;
      }
    }
    &.disabled {
      border-left: 0;
      cursor: not-allowed;
      i {
        color: $grey;
        opacity: 0.5;
      }
    }
  }
}

/*** Content Styles ***/
.program-settings-page-content {
  background: #fff;
  min-height: calc(100vh - 138px);
  width: 100%;
  padding: 40px 48px;
}
.program-settings-page-section {
  border-bottom: 1px solid #edecec;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.program-settings-page-section textarea.custom-input {
  width: 100%;
  height: 80px;
  resize: none;
  padding: 8px;
  max-width: 1200px;
}
.program-settings-page-section .custom-input {
  width: 100%;
}
.program-settings-page-section .input-label {
  font-weight: normal;
  width: 168px;
  min-width: 168px;
  margin-right: 16px;
  color: $accent-text-dark;
}
.program-settings-page .radio-container input[type="radio"] + label {
  width: 96px;
  margin-bottom: 0;
}
.program-settings-page
  .radio-container
  input[type="radio"][name="adminAcceptancePolicyOption"]
  + label {
  width: 140px;
}
.program-settings-page
  .radio-container
  input[type="radio"][name="adminAcceptancePolicyOption"]
  + label:last-child {
  width: 204px;
}
.program-settings-page-section .two-column-grid {
  max-width: 840px;
}
.program-settings-page-btns .btn {
  width: 112px;
  text-transform: uppercase;
}
/* General Event Settings Styles */
.event-settings-inputs {
  max-width: 400px;
}
.event-settings-inputs > .flex:not(:last-child) {
  margin-bottom: 16px;
}
.event-settings-inputs .custom-input select {
  height: 100%;
  width: 100%;
  border: none;
  background: transparent;
}
.two-column-grid.dates-two-column-grid {
  grid-gap: 16px 40px;
}
.dates-two-column-grid .react-datepicker-wrapper {
  width: 100%;
}
.dates-two-column-grid .react-datepicker__input-container {
  width: 100%;
}
/* Shluchim Controls Styles */
.shluchim-controls-row {
  max-width: 950px;
  margin-bottom: 40px;
}
.shluchim-controls-row:last-child {
  margin-bottom: 0;
}
.shluchim-controls-row-left .custom-input select {
  height: 100%;
  border: none;
  background: transparent;
  width: 240px;
}
.shluchim-controls-row-left .custom-input select:focus {
  border: none;
  outline: none;
}
/* Participants Styles */
.event-participants-table .table {
  padding: 0;
}
.event-participants-table p {
  margin-bottom: 0;
}
.event-participants-table .radio-container input[type="radio"] + label {
  width: 96px;
  margin-bottom: 0;
}
.event-participants-table-header {
  border-top: 1px solid #edecec;
  border-bottom: 1px solid #edecec;
  display: grid;
  align-items: center;
  font-weight: 700;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.5fr;
  padding: 4px 12px;
  height: auto;
}
.event-participants-table-row {
  background: #f5f5f5;
  padding: 12px 16px;
  font-size: 14px;
  color: $accent-text-dark;
  transition: opacity 300ms linear;
  display: grid;
  border-radius: 3px;
  grid-gap: 24px;
  color: inherit;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.5fr;
  padding: 12px;
  font-weight: 400;
  margin-top: 8px;
}
.event-participants-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 100vh;
}
.event-participants-modal-container .Select-input > input {
  position: initial !important;
}
/* Pricing Styles */
.promo-codes-grid {
  max-width: 840px;
}
.promo-codes-grid > div {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr 0.75fr 0.75fr 0.5fr;
  grid-column-gap: 6px;
}
.promo-codes-grid-header {
  border-top: 1px solid #edecec;
  border-bottom: 1px solid #edecec;
  padding: 8px;
  margin-bottom: 16px;
}
.promo-codes-grid-header p {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  color: $accent-text-dark;
}
.promo-codes-grid-row {
  background: #f5f5f5;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 3px;
  height: auto;
  min-height: 40px;
  align-items: center;
}
.promo-codes-grid-row p {
  margin-bottom: 0;
  color: $accent-text-dark;
}
.new-promo-modal.card {
  width: 400px;
  max-width: 96%;
  font-size: 14px;
  padding: 24px;
}
.new-promo-modal label {
  font-weight: normal;
  width: 96px;
  min-width: 96px;
}
.new-promo-modal .custom-input select {
  height: 100%;
  width: 100%;
  border: none;
  background: transparent;
}
.new-promo-modal .custom-input select:focus {
  border: none;
  outline: none;
}
/* Tour Styles */
.program-settings-tour-card {
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  padding: 16px;
  margin-bottom: 24px;
}
.program-settings-tour-card:first-child {
  width: 320px;
  max-width: 320px;
}
.program-settings-tour-card:last-child {
  width: calc(100% - 336px);
}
.program-settings-tour-card .xxl-text {
  font-size: 22px;
}
.tour {
  background: #f5f5f5;
  padding: 8px;
  margin-bottom: 8px;
  transition: all 300ms linear;
  border-radius: 3px;
}
.tour:hover {
  background-color: rgba(83, 183, 232, 0.12);
  cursor: pointer;
}
.tour.active {
  background-color: rgba(83, 183, 232, 0.12);
  cursor: default;
}
.tour i {
  cursor: pointer;
  transition: opacity 300ms linear;
}
.tour i:hover {
  opacity: 0.5;
}
.tour i.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.tour-details-form-top {
  border-bottom: 1px solid #edecec;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.tour-details-form label {
  font-size: 14px;
  font-weight: normal;
  color: $accent-text-dark;
  width: 120px;
  min-width: 120px;
}
.tour-details-form .custom-input {
  width: 100%;
  max-width: 384px;
}
.tour-details-form .small-input {
  width: 104px;
}
.tour-details-form textarea.custom-input {
  height: 80px;
  resize: none;
}
.tour-details-form .two-column-grid {
  max-width: 384px;
}
.duration-input input {
  padding-right: 40px;
}
.duration-input p {
  position: absolute;
  top: 8px;
  right: 8px;
}
.tour-details-form .radio-button-container label {
  min-width: auto;
  width: auto;
  color: rgba(0, 0, 0, 0.87);
}
.tour-schedules-grid > div {
  display: grid;
  grid-template-columns: 2fr 1.5fr 1.5fr 1fr 1fr 1fr;
  max-width: 504px;
  align-items: center;
}
.tour-schedules-grid-header {
  @extend .promo-codes-grid-header;
}
.tour-schedules-grid-row {
  @extend .promo-codes-grid-row;
}
.new-schedule-modal.card {
  width: 440px;
  max-width: 96%;
  font-size: 14px;
  padding: 24px;
  div[data-lastpass-icon-root] {
    display: none;
  }
}
.new-schedule-modal label {
  font-weight: normal;
}
/* Term Styles */
.terms-grid {
  max-width: 840px;
}
.terms-grid > div {
  display: grid;
  grid-template-columns: 1fr 0.75fr 3fr 0.5fr;
  grid-column-gap: 6px;
}
.terms-grid-header {
  @extend .promo-codes-grid-header;
}
.terms-grid-row {
  @extend .promo-codes-grid-row;
}
.terms-grid-row p:nth-child(3) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.new-terms-modal.card {
  width: 504px;
  max-width: 96%;
  font-size: 14px;
  padding: 24px;
}
.new-terms-modal textarea.custom-input {
  width: 100%;
  height: 120px;
  resize: none;
  padding: 12px;
}
.new-terms-modal .custom-input select {
  height: 100%;
  width: 100%;
  border: none;
  background: transparent;
}
.new-terms-modal .custom-input select:focus {
  border: none;
  outline: none;
}

/* Program Schedule Toggle Styles */
.program-schedule-row .radio-container input[type="radio"] + label,
.program-schedule-content-row .radio-container input[type="radio"] + label {
  width: 50%;
  min-width: auto;
  margin-bottom: 0;
}
.program-schedule-row .radio-button-container label,
.program-schedule-content-row .radio-button-container label {
  min-width: auto;
  width: auto;
}
.program-schedule-row .radio-container {
  width: 280px;
  height: 36px;
}
.program-schedule-content-row .radio-container {
  width: 172px;
  height: 32px;
  font-size: 14px;
}
