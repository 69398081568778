.results-summary {
  display: inline-grid;
  grid-template-columns: 150px 150px 150px 220px 170px;
  grid-gap: 15px;

  @include medium {
    grid-template-columns: 150px 170px 150px;
  }
  @include small {
    grid-template-columns: 170px 170px;
  }
  @include mobile {
    grid-template-columns: 170px;
  }

  &-small {
    grid-template-columns: 150px;
  }

  &-item {
    display: flex;
    align-items: center;
    background: white;
    border: 1px solid $lightWhite;
    border-radius: 4px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);

    .control-label {
      display: block;
      font-family: "Avenir";
      font-size: 13px;
      color: $labelColor;
      text-align: left;
    }

    .results-summary-desc {
      padding-left: 10px;
      font-family: "Avenir";
      font-size: 16px;
      color: $grey;
    }

    .icon {
      padding: 17px 15px;
      border-right: 1px solid $lightWhite;
    }
  }
}
// results summary styles for the shluchim directory (needed editing bc broke when filtered)
.shluchim-results-summary {
  @extend .results-summary;
  display: flex;
}
.shluchim-results-summary-item {
  @extend .results-summary-item;
  padding-right: 16px;
}
.shluchim-results-summary-desc {
  @extend .results-summary-desc;
}
.shluchim-results-summary-desc label {
  white-space: nowrap;
}
.filter-field {
  position: absolute;
  top: -140px;
  right: 0;
  padding: 0;
}
