﻿/***** Redirects Page Styles *****/
.redirects-page.container {
  max-width: 1200px;
}
.redirects-page {
  padding: 112px 40px 40px 40px;
  font-size: 14px;
}

/*** Redirects Table Styles ***/
.redirects-grid > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;
  grid-column-gap: 6px;
}
.redirects-grid-header {
  border-top: 1px solid #edecec;
  border-bottom: 1px solid #edecec;
  padding: 8px;
  margin-bottom: 16px;
}
.redirects-grid-header p {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  color: $accent-text-dark;
}
.redirects-grid-row {
  background: #f5f5f5;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 3px;
  height: auto;
  min-height: 40px;
  align-items: center;
}
.redirects-grid-row p {
  margin-bottom: 0;
  color: $accent-text-dark;
}
.redirects-grid > .no-redirects {
  column-span: all;
  text-align: center;
  padding: 12px 0px;
}

/*** Redirect Modal Styles ***/
.new-redirect-modal.card {
  width: 504px;
  max-width: 96%;
  font-size: 14px;
  padding: 24px;
}
