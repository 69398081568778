/***** STUDENT DETAILS STYLES *****/
.student-details-section {
  margin: 0 20px;
}
.student-info-two-column-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 8px 24px;
  padding-bottom: 24px;
  margin-bottom: 24px;
}

.student-info-two-column-grid > div {
  display: flex;
  align-items: center;
}

.student-info-two-column-grid > div p:first-child {
  font-weight: 700;
  width: 152px;
}

.student-info-two-column-grid p {
  margin-bottom: 0;
}

.student-profile-img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 16px;
}
.student-profile-img:not(.placeholder-img) {
  cursor: pointer;
  transition: opacity 300ms linear;
}
.student-profile-img:not(.placeholder-img):hover {
  opacity: 0.6;
}

/*** Student Registration History Table Styles ***/
.student-activity-table-row {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.student-activity-table-row:not(:last-child) {
  margin-bottom: 12px;
}
.student-activity-table-row .interaction-category-rating {
  img {
    height: 14px;
  }
}

/***** STUDENT PROFILE FORM STYLES *****/
.student-profile-modal {
  width: 96vw;
  max-width: 1200px;
  max-height: 96vh;
  overflow: auto;
}
.student-profile-form .student-profile-form-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 320px));
  grid-gap: 16px 40px;
}
.allergy-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 320px));
}
.student-profile-form .student-profile-form-grid.parent-contact-info-grid {
  grid-template-columns: repeat(2, minmax(auto, 320px));
}
.student-profile-form .student-profile-form-section {
  margin-bottom: 40px;
}
.allergy-toggle {
  width: 250px;
}
.student-profile-form .profile-section-title {
  font-weight: 600;
  margin-bottom: 24px;
  font-size: 16px;
}
.student-profile-form .input-container {
  height: 48px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.student-profile-form .input-container label {
  line-height: 10px;
}
.student-profile-form .small-label label {
  font-size: 9px;
}
.student-profile-form .input-container input {
  height: 18px;
}
.student-profile-form .input-container .error-message {
  position: absolute;
  bottom: -15px;
  left: 16px;
  font-size: 12px;
}
.student-profile-form .required-text {
  color: $error-color;
}
.student-profile-form .Select.custom-input,
.student-profile-form .Select-input {
  height: 18px;
  border: none;
  padding: 0;
}
.student-profile-form .Select-control {
  border: none;
  background: transparent;
  position: relative;
  top: -8px;
  font-size: 12px;
  left: -8px;
  width: calc(100% + 16px);
  border: none !important;
  padding: 0;
  height: 32px;
}
.student-profile-form .Select-control:hover {
  box-shadow: none;
}
.student-profile-form .Select.is-focused > .Select-control {
  background: transparent;
}
.student-profile-form .Select.is-focused:not(.is-open) > .Select-control {
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.student-profile-form .multiselect > div:first-of-type {
  height: 48px;
}
.student-profile-form .Select--multi .Select-multi-value-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}
.student-profile-form .input-container-select__control--is-focused {
  border: none;
  outline: none !important;
  box-shadow: none;
}
.student-profile-form .input-container-select__indicator-separator {
  display: none;
}
.student-profile-form .phone-input {
  height: 18px;
  border: none;
  margin-top: -2px;
}
.student-profile-form
  .allergy-grid
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 4px;
  top: 4px;
}
.student-profile-form .custom-checkbox[type="checkbox"]:checked + label:after {
  left: 4px;
  top: 6px;
}
.student-profile-form .custom-checkbox[type="checkbox"] + label {
  align-items: center;
}
.student-profile-form .input-container .react-datepicker-popper {
  z-index: 2;
}
.student-school-error-message {
  font-size: 12px;
}
.student-profile-school-loader svg#loading {
  position: absolute;
  height: 20px;
  width: 20px;
  top: 20px;
  left: 16px;
}
.student-profile-form .btn {
  width: 120px;
}
.student-profile-form .toggle-container {
  padding: 0;
}
.student-profile-form .error .toggle-container {
  border: 1px solid $error-color;
}
.student-profile-form .radio-container input[type="radio"] + label {
  width: 50%;
  min-width: auto;
  margin-bottom: 0;
}
.student-profile-form .radio-button-container label {
  min-width: auto;
  width: auto;
}
.student-profile-form .radio-container,
.student-profile-form .custom-input {
  width: 100%;
}
.student-profile-form textarea.custom-input {
  height: 60px;
  width: 100%;
  padding: 8px;
  font-size: 14px;
  font-family: Avenir;
  resize: none;
}
.student-profile-form-section .chip-container {
  min-height: auto;
}
.save-btn-container .error-message {
  white-space: nowrap;
  right: 0;
  bottom: -16px;
}
.save-btn-container .success-text {
  position: absolute;
  white-space: nowrap;
  right: 0;
  top: 52px;
  font-size: 13px;
  color: #2d7c3b;
}
/***** MODAL PROFILE FORM STYLES *****/
.modal .student-profile-form .save-btn-container {
  position: unset;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  grid-column-start: span 2;
}
.student-profile-modal-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  list-style: none;
}
.student-profile-modal-tabs li {
  min-width: 180px;
  text-transform: uppercase;
  padding-bottom: 16px;
  border-bottom: 1px solid $accent-text-light;
  transition: all 300ms linear;
  text-align: center;
}
.student-profile-modal-tabs li:hover {
  color: $accent-color;
  cursor: pointer;
}
.student-profile-modal-tabs li.active {
  padding-bottom: 15px;
  border-bottom: 2px solid $accent-color;
  color: $accent-color;
  cursor: pointer;
}
.student-profile-modal-tabs + .student-profile-form {
  max-height: calc(96vh - 216px);
  overflow: auto;
  margin-right: -32px;
  padding-right: 32px;
}
.new-student-profile .student-profile-modal-tabs + .student-profile-form {
  max-height: calc(96vh - 260px);
}

/***** OTHER PEOPLE PAGE STYLES *****/
.other-people-page {
  @extend .trip-page;
}
.other-people-page .table {
  padding: 0px;
}
/*** Table Header Styles ***/
.other-people-table-header {
  @include data-table-header;
  grid-template-columns: .5fr 1fr 1fr 1fr 1fr;
}
/*** Table Row Styles ***/
.other-people-table-row {
  @include data-table-row;
  grid-template-columns: .5fr 1fr 1fr 1fr 1fr;
  &:hover {
    cursor: unset;
    opacity: unset;
    text-decoration: unset;
  }
}
