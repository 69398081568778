.toast {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px;
  margin: auto;
  transition: 250ms;
  background: rgba(83, 183, 232, 0.85);
  color: #15568f;
  line-height: 24px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  display: flex;
  z-index: 101;
}

.toast.active {
  transform: translateY(0);
}

.toast.inactive {
  transform: translateY(100%);
}

.toast a,
.toast-button {
  background: transparent;
  color: #61dafb;
  font-size: inherit;
  line-height: inherit;
}

.toast-text {
  flex: 1;
  text-align: center;
  font-size: 20px;
}
