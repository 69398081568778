@mixin mobile {
  @media (max-width: 375px) {
    @content;
  }
}

@mixin small {
  @media (max-width: 799px) {
    @content;
  }
}

@mixin medium {
  @media (max-width: 1024px) {
    @content;
  }
}
