/***** COURSES PAGE STYLES *****/
.courses-page {
  padding: 80px 24px;
  font-size: 14px;
}

/***** COURSES HEADER CARDS *****/
.courses-header-cards .card {
  padding: 16px;
  width: 144px;
  font-size: 14px;
}

/***** COURSES TABS STYLES ******/
.courses-details-tabs {
  @extend .raffle-details-tabs;
}

/***** COURSES TABLE STYLES *****/
.courses-table .table {
  padding: 8px 0;
}
/*** Table Header Styles ***/
.courses-table-header {
  border-top: 1px solid #edecec;
  border-bottom: 1px solid #edecec;
  display: grid;
  align-items: center;
  font-weight: 700;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr 0.75fr;
  padding: 4px 12px;
  height: auto;
}
.courses-table-header p {
  margin-bottom: 0;
  padding: 8px 0;
}
/*** Table Row Styles ***/
.courses-table-row {
  background: #f5f5f5;
  padding: 12px 16px;
  font-size: 14px;
  color: $accent-text-dark;
  transition: opacity 300ms linear;
  display: grid;
  border-radius: 3px;
  grid-gap: 24px;
  color: inherit;
  grid-template-columns: 1fr 1fr 1fr 0.75fr;
  padding: 12px;
  font-weight: 400;
  margin-top: 8px;
  align-items: center;
}
.courses-table-row:hover {
  cursor: pointer;
  opacity: 0.5;
  text-decoration: none;
}
.courses-table-row p {
  margin-bottom: 0;
}

/***** NEW/EDIT COURSE FORM STYLES *****/
.course-form-page {
  @extend .courses-page;
}
.course-form-section:first-child {
  border-top: 1px solid #edecec;
}
.course-form-section {
  border-bottom: 1px solid #edecec;
}
.course-form-section-title {
  border-bottom: 1px solid #edecec;
  padding: 16px 0;
  margin-bottom: 24px;
  font-weight: 700;
}
.course-form-section .custom-checkbox[type="checkbox"] + label {
  top: 2px;
}
.course-form-section .custom-checkbox[type="checkbox"] + label:before,
.course-form-section .custom-checkbox[type="checkbox"]:checked + label:before {
  height: 14px;
  width: 14px;
}
.course-form-section .custom-checkbox[type="checkbox"]:checked + label:after {
  left: 5px;
  top: 4px;
}
.active-toggle.radio-container input[type="radio"]:first-child:checked + label {
  background: #72d798;
}
.active-toggle.radio-container
  input[type="radio"]:not(:first-child):checked
  + label {
  background: #f36464;
}
.active-toggle.radio-container input[type="radio"] + label {
  width: 104px;
  margin-bottom: 0;
}
.course-form .mb-16 label {
  font-weight: normal;
  width: 180px;
  min-width: 180px;
}
.course-form .mb-24 label {
  font-weight: normal;
  margin-bottom: 0;
}
.course-form .radio-container input[type="radio"] + label {
  width: 160px;
  margin-bottom: 0;
}
.course-form-class-section {
  background: #f5f5f5;
  padding: 16px;
  border-radius: 3px;
}
.course-form .link-text.error {
  color: $error-color;
}
.course-form-btns .error-text {
  position: absolute;
  right: 0;
  bottom: -20px;
}

/***** LOCATIONS TABLE STYLES *****/
/*** Table Header Styles ***/
.courses-locations-table-header {
  @extend .courses-table-header;
  grid-template-columns: 1fr 1fr 1.5fr 1.5fr 1.5fr 0.5fr;
}
.jewish-u-locations-table .courses-locations-table-header  {
  grid-template-columns: 1fr 1fr 1.5fr 1.5fr 1.5fr 1fr 0.5fr;
}
.your-israel-locations-table .courses-locations-table-header {
  grid-template-columns: 1fr 1fr 1.5fr 1.5fr 1fr 0.5fr;
}
.courses-locations-table-filters {
  @extend .courses-requests-table-filters;
}
/*** Table Row Styles ***/
.courses-locations-table-row {
  @extend .courses-table-row;
  grid-template-columns: 1fr 1fr 1.5fr 1.5fr 1.5fr 0.5fr;
}
.jewish-u-locations-table .courses-locations-table-row {
  grid-template-columns: 1fr 1fr 1.5fr 1.5fr 1.5fr 1fr 0.5fr;
}
.your-israel-locations-table .courses-locations-table-row { 
  grid-template-columns: 1fr 1fr 1.5fr 1.5fr 1fr 0.5fr;
}
/*** Shliach Details Modal Styles ***/
.locations-shliach-details-modal {
  width: 900px;
  font-size: 14px;
  max-height: 96vh;
  overflow: auto;
}
.locations-shliach-contact-details label {
  width: 200px;
  min-width: 200px;
}
.locations-shliach-contact-details p {
  margin-bottom: 4px;
}
.locations-shliach-contact-details + .divider {
  border-top: 1px solid #edecec;
  margin: 16px 0;
}
.locations-shliach-details-modal-table-header {
  @extend .courses-table-header;
  grid-template-columns: 1fr 1fr 0.75fr 0.5fr;
}
.locations-shliach-details-modal-table-header p {
  font-size: 14px;
}
.locations-shliach-details-modal-table-row {
  @extend .courses-table-row;
  grid-template-columns: 1fr 1fr 0.75fr 0.5fr;
}
.locations-shliach-details-modal-table-row:hover {
  opacity: 1;
  text-decoration: none;
}

/***** STUDENTS TABLE STYLES *****/
/*** Filter Styles ***/
.courses-students-table-filters {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  grid-gap: 16px;
  align-items: center;
}
.courses-students-table-filters .search-input {
  width: auto;
  height: 38px;
}
.courses-students-table-filters .Select-control {
  border: 1px solid #edecec;
}
.courses-students-table-filters .Select-control .Select-value-label {
  text-overflow: ellipsis;
  overflow: hidden;
}
/*** Table Header Styles ***/
.courses-students-table-header {
  @extend .courses-table-header;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr;
}
.courses-students-table-header.hide-status {
  @extend .courses-table-header;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
}
/*** Table Row Styles ***/
.courses-students-table-row {
  @extend .courses-table-row;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr;
  align-items: flex-start;
}
.courses-students-table-row.hide-status {
  @extend .courses-table-row;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
  align-items: flex-start;
}
.courses-students-table-row:hover {
  opacity: 1;
}

/***** STUDENTS PROFILE STYLES *****/
.courses-students-page {
  @extend .courses-page;
}
/*** Students Profile Card Styles ***/
.students-profile-card.card {
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 24px 48px;
}
.students-profile-card .student-name .xxl-text {
  font-weight: 900;
  font-size: 34px;
  color: inherit;
}
.students-profile-card .student-name .tag {
  background: $accent-color;
  color: #fff;
  padding: 0 8px;
  line-height: 24px;
  height: 24px;
  border-radius: 3px;
}
.students-profile-card-right label.fw-700 {
  margin-bottom: 0;
  width: 176px;
  min-width: 176px;
}
/*** Students Info Card Styles ***/
.student-profile-tabs {
  @extend .courses-details-tabs;
  margin-top: 40px;
}
.students-profile-info-card.card {
  padding: 16px 24px;
}
.student-details-edu-tabs-card {
  border: 1px solid #edecec;
  padding: 24px 24px 14px 24px;
  border-radius: 3px;
  min-width: 180px;
  width: 180px;
  margin-right: 40px;
  margin-top: 0;
  border-bottom: "1px solid #edecec";
}
.student-details-edu-tabs-card p {
  margin-left: -24px;
  padding-left: 24px;
  border-left: 4px solid transparent;
}
.student-details-edu-tabs-card p:first-child {
  margin-bottom: 20px;
}
.student-details-edu-tabs-card p.active {
  border-left: 4px solid #30a4dc;
  color: #53b7e8;
}
/* Background Styles */
.students-profile-info-card .divider {
  background: #edecec;
  height: 1px;
  width: 100%;
}
.student-profile-background-info label {
  width: 176px;
  min-width: 176px;
}
/* Course History Styles */
.student-profile-courses-progress {
  width: 376px;
  max-width: 100%;
}
.progress-bar-outer {
  background: #edecec;
  height: 12px;
  top: 0;
  left: 0;
}
.progress-bar-inner {
  background: $accent-color;
  height: 12px;
  top: 0;
  left: 0;
}
.rejected-chip {
  background-color: #ff6b6b;
  height: 13px;
  padding: 10px;
  display: inline-flex;
  border-radius: 16px;
  width: fit-content;
  margin-left: 8px;
  line-height: 0px;
}
.students-courses-history-table-header {
  @extend .courses-table-header;
  grid-template-columns: 1.5fr 0.75fr 0.75fr 0.75fr 0.75fr 0.75fr 0.5fr;
}
.students-courses-history-table-row {
  @extend .courses-table-row;
  grid-template-columns: 1.5fr 0.75fr 0.75fr 0.75fr 0.75fr 0.75fr 0.5fr;
}
.students-courses-history-table-row:hover {
  opacity: 1;
  cursor: default;
}
/* Reward History Styles */
.students-rewards-history-table-header {
  @extend .courses-table-header;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.75fr;
}
.students-rewards-history-table-row {
  @extend .courses-table-row;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.75fr;
}
.students-rewards-history-table-row:hover {
  opacity: 1;
  cursor: default;
}

/***** COURSE SCHEDULES TABLE STYLES *****/
/*** Table Header Styles ***/
.courses-schedules-table-header {
  @extend .courses-table-header;
  grid-template-columns: 1.5fr 2fr 1fr 1fr 0.75fr 0.75fr 0.75fr;
}
/*** Table Row Styles ***/
.courses-schedules-table-row {
  @extend .courses-table-row;
  grid-template-columns: 1.5fr 2fr 1fr 1fr 0.75fr 0.75fr 0.75fr;
}
.courses-schedules-table-filters {
  @extend .courses-requests-table-filters;
}
.courses-schedules-table-filters .react-datepicker__close-icon::after {
  background-color: transparent;
  color: $accent-text-dark;
}

/***** REWARDS TABLE STYLES *****/
/*** Table Header Styles ***/
.courses-rewards-table-header {
  @extend .courses-table-header;
  grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr;
}
/*** Table Row Styles ***/
.courses-rewards-table-row {
  @extend .courses-table-row;
  grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr;
}
.courses-rewards-table-row p.active:before,
.courses-rewards-table-row p.inactive:before {
  left: 0;
}

/***** EDIT REWARDS STYLES *****/
.edit-reward-page {
  @extend .courses-page;
}
.edit-reward-page .card {
  padding: 32px 64px;
}
.edit-reward-page .card .reward-form-header {
  padding-bottom: 16px;
  border-bottom: 1px solid #edecec;
}
.reward-form .mb-16 label {
  font-weight: normal;
  width: 232px;
}
.reward-form .mb-24 label {
  font-weight: normal;
  margin-bottom: 0;
}
.reward-form .radio-container input[type="radio"] + label {
  width: 160px;
  margin-bottom: 0;
}
.reward-form textarea.custom-input.full-width {
  resize: none;
  height: 72px;
  padding: 8px;
}
.reward-form-btns .error-text {
  position: absolute;
  right: 0;
  bottom: -20px;
}

/***** REQUESTS TABLE STYLES *****/
/*** Filter Styles ***/
.courses-requests-table-filters .Select-control {
  border: 1px solid #edecec;
  width: 220px;
}
.courses-requests-table-filters .Select-control .Select-value-label {
  text-overflow: ellipsis;
  overflow: hidden;
}
.courses-requests-table-filters .Select--multi .Select-control {
  width: 100%;
}
.courses-requests-table-filters .Select--multi .Select-control .Select-value {
  vertical-align: middle;
}
/*** Table Header Styles ***/
.courses-requests-table-header {
  @extend .courses-table-header;
  grid-template-columns: 1fr 2fr 1.25fr 1fr 1fr 0.5fr 1fr;
}
/*** Table Row Styles ***/
.courses-requests-table-row {
  @extend .courses-table-row;
  grid-template-columns: 1fr 2fr 1.25fr 1fr 1fr 0.5fr 1fr;
  align-items: flex-start;
}
.courses-requests-table-row:hover {
  opacity: 1;
}
.courses-requests-table-row .custom-checkbox[type="checkbox"] + label {
  top: 2px;
}
.courses-requests-table-row .custom-checkbox[type="checkbox"] + label:before,
.courses-requests-table-row
  .custom-checkbox[type="checkbox"]:checked
  + label:before {
  height: 14px;
  width: 14px;
}
.courses-requests-table-row
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 5px;
  top: 1px;
}
