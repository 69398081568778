.styled-contact-add-btn {
  width: 100%;
  background: none;
  height: 48px;
  padding: 0 10px;
  border-radius: 3px;
  border: 1px dashed #a0a3a9;
  text-align: left;
}

.styled-contact-header {
  padding: 0;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 20px;
  list-style: none;
  border-bottom: 1px solid #e0e0e0;
  font-family: "Avenir";

  li {
    color: #555;

    &:first-child {
      @media (min-width: 550px) {
        min-width: 330px;
      }
    }
  }
}

.styled-contact-container {
  display: flex;
  padding: 5px 10px;
  margin-bottom: 10px;
  justify-content: space-between;
  background-color: #f5f5f5;
  border: 2px solid #e2e2e2;
  border-radius: 3px;

  &.text-input-only {
    background-color: transparent;
    border: 2px solid none;
  }

  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="number"] {
    width: 100%;
    height: 35px;
    padding-left: 10px;
    background-color: #fff;
    box-shadow: none;
    border: none;
    border-radius: 3px;
    box-shadow: inset 0 1px 0 0 #ececec;
  }
}

.styled-contact-delete-btn {
  background: none;
  border: none;
}

.styled-contact-value {
  @media (min-width: 550px) {
    min-width: 330px;
  }
}

.styled-group-container {
  height: 48px;
  border-radius: 3px;
  background-color: rgba(83, 183, 232, 0.1);
  font-size: 18px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin: 20px 0;

  i {
    margin-right: 10px;
  }
}

.styled-group-btn {
  background: none;
  border: none;
}

.styled-list-add-btn {
  width: 100%;
  background: none;
  height: 48px;
  padding: 0 10px;
  border-radius: 3px;
  border: 1px dashed #a0a3a9;
  text-align: left;
}

.styled-list-container {
  padding: 5px 10px;
  margin-bottom: 10px;
}

.styled-list-delete-btn {
  background: none;
  border: none;
}

.styled-select {
  height: 48px;
  background-color: #fff;
  border: solid 2px #e2e2e2;
  position: relative;
  min-width: 110px;
  box-sizing: border-box;
  padding-left: 5px;
  overflow: hidden;

  &:after {
    position: absolute;
    top: 17px;
    right: 10px;
    width: 0;
    height: 0;
    pointer-events: none;
    content: "";
    border-color: #555 transparent transparent transparent;
    border-style: solid;
    border-width: 8px 5px 0 5px;
  }

  &.disabled {
    background-color: transparent;
    border-color: transparent;
    &:after {
      border: none;
    }
  }

  &.small {
    height: 30px;
    background-color: transparent;
    border: none;
    &:after {
      top: 10px;
    }
    select {
      height: 30px;
    }
  }

  select {
    width: 100%;
    height: 48px;
    -webkit-appearance: none;
    padding: 5px 0 5px 5px;
    background: transparent;
    border: none;

    &:focus {
      outline: none;
    }

    &::-ms-expand {
      display: none;
    }
  }
}
// Hack for opera - not sure if needed...
// _:-o-prefocus, .selector {
//   .styled-select { background: none; }
// }

.simple-keyboard {
  position: absolute;
  z-index: 2;
  top: 34px;
  left: 0;
  min-width: 280px;
}
.child-row .simple-keyboard {
  top: 48px;
}
.simple-keyboard.hg-theme-default {
  background: #f5f5f5;
}
@media only screen and (max-width: 560px) {
  .simple-keyboard {
    top: 58px;
  }
}

.horizontal-menu {
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 24px;
}
.menu-item-wrapper {
  padding: 0 8px;
}
.menu-item {
  margin: 0 8px;
  user-select: none;
  cursor: pointer;
  border: none;
}
.menu-item.active {
  color: $accent-color;
  border-bottom: 2px $accent-color solid;
}
.menu-item-wrapper:focus {
  outline: none;
}
.scroll-menu-arrow {
  cursor: pointer;
}
.scroll-menu-arrow--disabled {
  visibility: hidden;
}

.autocomplete-menu {
  border-radius: 3px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.9);
  padding: 2px 0;
  font-size: 14px;
  position: absolute;
  overflow: auto;
  max-height: 280px;
  z-index: 2;
}
.autocomplete-menu > div {
  transition: background 300ms linear;
}
