.jumbotron {
  display: flex;
  overflow: hidden;
  align-items: center;
  background: none;

  img {
    width: 81px;

    @include small {
      width: 50px;
      margin-top: 20px;
      align-self: flex-start;
    }
  }

  h2 {
    font-size: 26px;
    color: $grey;

    @include small {
      font-size: 20px;
    }
  }

  p {
    font-size: 20px;
    color: $darkGrey;

    @include small {
      font-size: $baseFontSize;
    }
  }

  .jumbotron-desc {
    display: inline-block;
    margin-left: 30px;

    @include small {
      margin-left: 10px;
    }
  }
}

.container-fluid .jumbotron {
  padding: 40px 20px;
  margin: 0;

  @include small {
    padding: 0;
  }
}
